import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import { globals as globalsSlice } from '../../../app/globalsSlice';
import { SLICE_NAME as CORRESPONDENCE_SLICE_NAME } from '../../../pages/CorrespondencePage/constants';
import { newMessages } from '../../../pages/CorrespondencePage/correspondenceSlice';

const useStyles = makeStyles((theme) => ({
    count: {
        fontSize: theme.typography.fontSizeSmall,
        fontWeight: theme.typography.fontWeightBold,
        marginLeft: '10px',
        padding: '2px',
        border: '1px solid',
        borderRadius: '50%',
        color: theme.palette.text.mainBlue,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '20px',
        minHeight: '20px',
        maxWidth: '30px',
        maxHeight: '30px',
        position: 'absolute',
        right: 0,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
const CorrespondenceIcon = ({ id = 'count' }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { globals } = useSelector(globalsSlice) || {};
    const newMessagesCount = useSelector(newMessages) || 0;

    const CHECK_NEW_MESSAGES_INTERVAL = globals?.application['correspondence.new.messages.counter'] || 60;

    useEffect(() => {
        const updateCountOnInterval = () => {
            dispatch({ type: `${CORRESPONDENCE_SLICE_NAME}/requestNewCorrespondencesCount` });
        };
        updateCountOnInterval();
        const interval = setInterval(() => updateCountOnInterval(), CHECK_NEW_MESSAGES_INTERVAL * 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <React.Fragment>
            {!!newMessagesCount && (
                <div className={classes.count} id={`${id}-counter`}>
                    <span>{newMessagesCount}</span>
                </div>
            )}
        </React.Fragment>
    );
};

export default CorrespondenceIcon;
