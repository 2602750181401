import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ErrorInput from '../../../../components/ErrorInput';
import { t } from '../../../../providers';

const useStyles = makeStyles((theme) => ({
    PageWrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    inputWrapper: {
        marginBottom: '1.5rem',
    },
    textInput: {
        height: '2.5rem',
        width: '14rem',
        marginTop: '10px',
        '@media (max-width:600px)': {
            width: '12.5rem',
        },
    },
    tokenInput: {
        width: '22rem',
        '@media (max-width:600px)': {
            width: '100%',
        },
    },
    inputItem: {
        marginBottom: '10px',
        flex: '1 1 auto',
    },
    inputError: {
        marginTop: '5px',
    },
    fieldLabel: {
        fontWeight: theme.typography.titleFontWeight,
        fontSize: theme.typography.titleFontSize,
        color: '#546E7A',
        marginTop: '1.5rem',
    },
    tokenLabel: {
        marginTop: '0',
    },
    verifyButton: {
        minWidth: '7.5rem',
        marginLeft: '1rem',
    }
}));

const VerificationForm = ({ token, verifyUsernameChange, requestNewCode, error}) => {
    const [code, setCode] = useState({ value: token || '', isDirty: false });

    const requestNew = (event) => {
        event.preventDefault();
        requestNewCode();
    };

    const handleChangeValue = (event) => setCode({ value: event.target.value, isDirty: true });

    const submitCode = (event) => {
        event.preventDefault();
        let payload = { token: code.value };
        if (!code.value) {
            setCode({ ...code, isDirty: true });
        }
        verifyUsernameChange(payload);
    };

    const classes = useStyles();
    return (
        <div className={classes.PageWrapper}>
            <form onSubmit={submitCode} noValidate>
                <Grid container direction="column" className={classes.inputWrapper}>
                    <Grid item xs sm md className={classes.inputItem}>
                        <InputLabel
                            htmlFor="verificationCode"
                            error={!!error || (code.isDirty && !code.value)}
                            className={`${classes.fieldLabel} ${classes.tokenLabel}`}>
                            {t('verification.code')}
                        </InputLabel>
                        <Input
                            id="verificationCode"
                            className={`${classes.textInput} ${classes.tokenInput}`}
                            placeholder={t('type.verification.code')}
                            type="verificationCode"
                            name="text"
                            fullWidth
                            autoFocus
                            onChange={handleChangeValue}
                            value={code.value}
                            error={!!error || (code.isDirty && !code.value)}
                        />
                        {code.isDirty && !code.value && <ErrorInput errorMessage={t('required.field')} classes={`${classes.inputError}`} />}
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <Typography variant="body2" className={classes.infoMessage}>
                            {t('username.change.verification.description')}
                        </Typography>
                    </Grid>
                    {!!error && (
                        <Grid item xs sm md>
                            <Typography color="error">{t(error)}</Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={0}>
                    <Grid item>
                        <Button onClick={requestNew} variant="outlined" type="button">
                            {t('send.new.code')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" className={classes.verifyButton} type="submit" disabled={!code.value}>
                            {t('verify.username.change')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default VerificationForm;
