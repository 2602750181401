import React, { useState, useEffect } from 'react';
import {
    getCookie,
    deleteCookie,
    updateCookie
} from '../../../common/utils/localStorageHandler';

import { makeStyles } from '@material-ui/core/styles';
import { initEgovLogin } from '../service';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { t } from '../../../providers';
import eValdziaLogo from '../../../assets/images/eValdzia-logo.png';

const useStyles = makeStyles(() => ({
    loginBoxWrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        padding: '1rem',
    },
    portalLink: {},
    mainLogo: {
        paddingRight: '15px',
        float: 'left',
        maxWidth: '68px',
    },
    logoTitle: {
        fontSize: '30px',
        fontWeight: '700',
        lineHeight: '1',
        marginBottom: '2px',
        color: '#006D9B',
        float: 'left',
    },
    logoText: {
        lineHeight: '1.4',
        fontSize: '15px',
        color: '#2A6085',
        float: 'left',
    },
}));

const EpaslaugosLoginComponent = () => {
    const classes = useStyles();

    const [ticket, setTicket] = useState('');
    const [ticketCookie, setTicketCookie] = useState(getCookie('ticket'));
    const [loginChallenge, setLoginChallenge] = useState(new URLSearchParams(window.location.search).get('login_challenge'));
    const [authenticationUrl, setAuthenticationUrl] = useState('');
    const [errorMessageKey, setErrorMessageKey] = useState(new URLSearchParams(window.location.search).get('errorMessageKey'));

    const callbackUrl = window.location.protocol + '//' + window.location.host + '/epaslaugos/auth/callback/login';
    const loginWithTicketUrl = window.location.protocol + '//' + window.location.host + '/epaslaugos/auth/callback/login-with-ticket-only';

    const initLogin = () => {
        initEgovLogin({callbackUrl : callbackUrl, loginChallenge : loginChallenge}).then(({ ticket, authenticationUrl }) => {
            if (!!ticket && !!authenticationUrl) {
                setTicket(ticket);
                setAuthenticationUrl(authenticationUrl);
                document.getElementById('authentication_init_form').submit();
            } else {
                setErrorMessageKey('service.unavailable');
            }
        });
    };

    useEffect(() => {
        if(!!ticketCookie && !!loginChallenge){
            deleteCookie('ticket')
            document.getElementById('authentication_form').submit();
        }
    }, []);

    return (
        <div className={classes.loginBoxWrapper}>
            <Grid container direction="column" className={classes.inputWrapper}>
                <div>
                    <div>
                        <a href="#" onClick={initLogin}>
                            <div>
                                <img src={eValdziaLogo} className={classes.mainLogo} />
                            </div>
                            <div>
                                <div className={classes.logoTitle}>{t('e.government.gateway')}</div>
                                <div className={classes.logoText}>{t('e.government.gateway.description')}</div>
                            </div>
                        </a>
                    </div>
                    <form id="authentication_init_form" noValidate method="post" action={authenticationUrl}>
                        <input type="hidden" name="ticket" value={ticket} />
                    </form>
                    <form id="authentication_form" noValidate method="post" action={loginWithTicketUrl}>
                        <input type="hidden" name="ticket" value={ticketCookie} />
                        <input type="hidden" name="challenge" value={loginChallenge} />
                    </form>
                </div>
            </Grid>
            <Grid container direction="column">
                <Typography color="error">{t(errorMessageKey)}</Typography>
            </Grid>
        </div>
    );
};

export default EpaslaugosLoginComponent;
