import React, {useEffect, useState} from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {globals as globalsSlice} from '../../../app/globalsSlice';
import {t} from '../../../providers';
import {registration as regSlice, error as regError, loading as regLoading} from '../authenticationSlice';
import AuthContainer from '../components/AuthContainer';
import RequestResult from '../components/RequestResult';
import {SLICE_NAME} from '../constants';
import StepOne from './components/StepOne';
import StepTwo from './components/StepTwo';
import StepThree from './components/StepThree';
import StepFour from './components/StepFour';
import WarningModal from "../../../components/WarningModal";

const accountTypes = [
    {
        key: 'applicant',
        value: 'applicant'
    },
    {
        key: 'representative',
        value: 'representative'
    },
];

const SelfRegistrationPage = () => {

    //useSelector is alternative of redux connect()
    const loading = useSelector(regLoading);
    const error = useSelector(regError);
    const registration = useSelector(regSlice); // regSlice = state['auth'].registration;
    const {globals} = useSelector(globalsSlice);

    const {supportedLanguages, account} = globals;

    const defaultLanguage = (!!supportedLanguages && Object.keys(supportedLanguages)[0]) || 'en';
    const [step, setStep] = useState('ONE');
    const [registrationForm, setRegistrationForm] = useState({});
    const [candidateFieldsList, setCandidateFieldsList] = useState([]);
    const [warningModal, setWarningModal] = useState(true);

    const history = useHistory();
    const dispatch = useDispatch(); // The equivalent of map dispatch to props

    // @param effect Imperative function that can return a cleanup function
    // @param deps If present, effect will only activate if the values in the list change.
    useEffect(() => {
        const authenticatedTicket = new URLSearchParams(window.location.search).get('ticket');
        if (registration === null && authenticatedTicket != null) {
            dispatch({type: `${SLICE_NAME}/getEgovAuthenticationData`, payload: {ticket: authenticatedTicket}});
        }

        if (registration && registration.fromAction === 'requestEgovAuthenticationData') {
            const isLegalEntity = (registration.legalEntityCode != null && registration.organizationName != null);
            const authData = {
                'isLegalEntity': isLegalEntity,
                'legalStatus': isLegalEntity ? 'business.company' : 'individuals',
                ...registration
            };
            setRegistrationForm(authData);
            setStep('TWO');
        }

        if (registration && registration.userName && registration.fromAction === 'validateCredentials') {
            registerUser(registrationForm);
            setStep('FOUR');
        }
        if (registration && registration.userName && registration.fromAction === 'registerAccount') {
            setStep('SUCCESS');
        }

    }, [registration]);

    useEffect(() => {
        return () => {
            dispatch({type: `${SLICE_NAME}/registration`, payload: null});
            dispatch({type: `${SLICE_NAME}/error`, payload: null});
        };
    }, [dispatch]);

    const candidateAsAView = (candidate) => {
        const allowedKeysInOrder = [
            'email',
            'userType',
            'accountType',
            'applicantType',
            'representativeType',
            'legalStatus',
            'firstName',
            'surName',
            'telephone',
            'naturalPersonCode',
            'organizationName',
            'legalEntityCode'
        ];

        const translatableFieldKeys = ['userType', 'accountType', 'applicantType', 'representativeType', 'legalStatus'];

        return allowedKeysInOrder.reduce((previous, current) => {
            return candidate[current]
                ? [...previous, translatableFieldKeys.includes(current) ? t(candidate[current]) : candidate[current]]
                : [...previous];
        }, []);
    };

    const showStepThree = (stepData) => {
        const mergedData = {...registrationForm, ...stepData};
        setRegistrationForm(mergedData);
        setCandidateFieldsList(candidateAsAView(mergedData));
        setStep('THREE');
    };

    const showStepFour = () => {
        setStep('FOUR');
    };

    const validateStepFour = (stepData) => {
        dispatch({type: `${SLICE_NAME}/validateCredentials`, payload: {...stepData}});
        const mergedData = {...registrationForm, ...stepData};
        setRegistrationForm(mergedData);
    };

    const registerUser = (accountData) => {
        const {
            fromAction,
            gdprConsent,
            isPatentAttorney,
            legalStatus,
            privacyPolicy,
            userType,
            ...pureAccountData
        } = accountData;
        pureAccountData.personType = account[accountData.userType];
        pureAccountData.language = defaultLanguage;
        pureAccountData.accountType = account[accountData.legalStatus];
        dispatch({
            type: `${SLICE_NAME}/registerAccount`,
            payload: pureAccountData
        });
    };

    const navigateToLogin = () => {
        history.push('/login');
    };

    const handleCloseModalAction = () => {
        setWarningModal(false);
    };

    const renderItem = (
        <>
            {step === 'ONE' && (
                <StepOne/>
            )}
            {step === 'TWO' && (
                <React.Fragment>
                    <StepTwo
                        proceedToNextStep={showStepThree}
                        accountTypes={accountTypes}
                        stepData={registrationForm}
                    />
                    <WarningModal
                        visibility={!registrationForm.isLegalEntity && warningModal}
                        message={t('information.register.natural')}
                        confirmButtonText={t('register.natural.button')}
                        onConfirmation={handleCloseModalAction}
                    />
                </React.Fragment>
            )}
            {step === 'THREE' && (
                <StepThree
                    accountDetails={candidateFieldsList}
                    backToStepTwo={() => setStep('TWO')}
                    proceedToNextStep={showStepFour}
                />
            )}
            {step === 'FOUR' && (
                <StepFour
                    gotoNextStep={validateStepFour}
                    gotoPreviousStep={() => setStep('THREE')}
                    error={error}
                    stepData={registrationForm}
                />
            )}
            {step === 'SUCCESS' && (
                <RequestResult
                    action={navigateToLogin}
                    message={'registration.success.message'}
                    messageVariables={{email: registrationForm.email, ipoTitle: 'IPO Office'}}
                    button={'back.to.login'}
                />
            )}
        </>
    );

    return (
        <AuthContainer preTitle={2} title={t('create.new.account.user.area')}>
            {loading ? <CircularProgress/> : renderItem}
        </AuthContainer>
    );
};

export default SelfRegistrationPage;
