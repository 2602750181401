import { sendRequest } from '../../common/utils';

export const fetchSignatures = ({ params }) => {
    const { sortColumn, sortType } = params;
    const url = '/signatures';
    return sendRequest({
        url,
        method: 'POST',
        prefix: '/backend',
        body: {
            sortColumn,
            sortType,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const signApp = (params) => {
    const { filingNumber } = params;
    const url = `/signatures/sign/${filingNumber}`;
    return sendRequest({
        url,
        method: 'POST',
        prefix: '/backend',
        returnJSON: false,
    })
        .then((response) => {
            return response.text();
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code, details: error.details } };
        });
};
