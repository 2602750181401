import { all, call, put, takeLatest } from 'redux-saga/effects';
import { saveDownloadedFile } from '../../common/utils/service';

import { getDraftReceipt, deleteApp } from '../MyApplicationsPage/service';
import { SLICE_NAME } from './constants';
import { fetchSignatures, signApp } from './service';

function* requestSignatures(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(fetchSignatures, {
        ...action.payload,
    });
    if (!response.error) {
        yield put({
            type: `${SLICE_NAME}/list`,
            payload: response,
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* getSignatures() {
    yield takeLatest(`${SLICE_NAME}/requestSignatures`, requestSignatures);
}

function* requestSignApplication(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(signApp, {
        ...action.payload,
    });
    if (!response.error) {
        const redirectUrl = response;
        if (!!redirectUrl) {
            window.open(redirectUrl, '_blank');
        }
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.code === 403 ? response.error : response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* signApplication() {
    yield takeLatest(`${SLICE_NAME}/signApplication`, requestSignApplication);
}

function* requestDeleteApplication(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(deleteApp, {
        ...action.payload,
    });
    if (!response.error) {
        const params = { ...action.payload.params.sortProps };
        yield call(requestSignatures, { payload: { params } });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.code === 403 ? response.error : response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* deleteApplication() {
    yield takeLatest(`${SLICE_NAME}/deleteApplication`, requestDeleteApplication);
}

function* requestDraftReceipt(action) {
    yield put({ type: `${SLICE_NAME}/error`, payload: false });
    const response = yield call(getDraftReceipt, {
        ...action.payload,
    });
    if (!response.error) {
        saveDownloadedFile(response);
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
}

function* downloadDraftReceipt() {
    yield takeLatest(`${SLICE_NAME}/downloadDraftReceipt`, requestDraftReceipt);
}

export {
    getSignatures,
    signApplication,
    deleteApplication,
    downloadDraftReceipt,
};
