import React from 'react';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import { bareDateTimeFormat, t } from '../../providers';

const valueWithTooltip = (text) => (
    <Tooltip arrow title={text} placement={'top'}>
        <span>{text}</span>
    </Tooltip>
);

export const paymentHistoryParserList = (data, downloadInvoice) => {
    const { content } = data;

    let paymentHistoryViewList = {
        headers: [
            { label: t('date.and.time'), code: 'PAYMENT_DATE', align: 'left', sortable: true },
            { label: t('application.reference'), code: 'APP_REFERENCE', align: 'left', sortable: false },
            { label: t('application.or.number'), code: 'APPLICATION_NUMBER', align: 'left', sortable: false },
            { label: t('paid.by'), code: 'PAID_BY', align: 'left', sortable: true },
            { label: t('payment.method'), code: 'PAYMENT_METHOD', align: 'left', sortable: true },
            { label: t('cost'), code: 'PAYMENT_TOTAL_COST', align: 'left', sortable: true },
            { label: t('payment.status'), code: 'PAYMENT_STATUS', align: 'left', sortable: false },
            { label: '', code: 'actions', align: 'left', sortable: false },
        ].filter((item) => typeof item === 'object'), //remove non-objects from headers
    };

    let dataList = [];

    // table/list data
    const parsedHistory = (content || []).map((item) => [
        {
            label: 'paymentDate',
            value: item.paymentDate ? valueWithTooltip(bareDateTimeFormat(item.paymentDate, 'lt')) : '',
        },
        {
            label: 'appReferences',
            value: item.applicationsReferences ? valueWithTooltip(item.applicationsReferences) : '',
        },
        {
            label: 'applications',
            value: item.applicationNumbers ? valueWithTooltip(item.applicationNumbers) : '',
        },
        {
            label: 'paidBy',
            value: item.paidBy ? valueWithTooltip(item.paidBy) : '',
        },
        {
            label: 'paidMethod',
            value: item.paymentMethod ? t(`payment.method.${item.paymentMethod}`) : '',
        },
        {
            label: 'cost',
            value: item.totalCost ? `${item.totalCost} €` : '',
        },
        {
            label: 'paymentStatus',
            value: item.status ? t(`payment.status.${item.status}`) : '',
        },
        {
            label: 'invoiceBtn',
            value: <Button onClick={() => downloadInvoice(item.paymentId)}>{t('invoice')}</Button>,
        },
    ]);

    //remove non-objects from data
    dataList = parsedHistory.map((app) => {
        return app.filter((item) => typeof item === 'object');
    });

    paymentHistoryViewList = {
        ...paymentHistoryViewList,
        data: dataList,
    };

    return paymentHistoryViewList;
};

export const filtersParser = (filters) => {
    let parsedFilters = [];
    Object.values(filters).map((filter) => parsedFilters.push(...filter));
    return !!Object.keys(filters).length ? { ipRightTypes: parsedFilters } : {};
};
