import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { t } from '../../../providers';

const useStyles = makeStyles(() => ({
    containerDiv: {
        'padding-left': '10px',
        color: '#607D8B',
    },
    line: {
        height: '45%',
        'border-left': '2px solid',
        'margin-left': '50%',
        color: '#607D8B',
    }
}));

const OrSeparator = () => {
    const classes = useStyles();

    return (
        <div className={classes.containerDiv}>
            <div className={classes.line}></div>
            {t('or')}
            <div className={classes.line}></div>
        </div>
    );
};

export default OrSeparator;
