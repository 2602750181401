import CorrespondenceIcon from './SidebarIcons/CorrespondenceIcon';
import { WaitingForPaymentApplicationCountIcon, WaitingForSignatureApplicationCountIcon, } from './SidebarIcons/ApplicationCountIcon';

const sidebarIcons = {
    correspondence: CorrespondenceIcon,
    waitingForPaymentCountIcon: WaitingForPaymentApplicationCountIcon,
    waitingForSignatureCountIcon: WaitingForSignatureApplicationCountIcon,
};

const loginChallenge = new URLSearchParams(window.location.search).get('login_challenge');

export default {
    unauth: [
        {
            key: 'LOGIN',
            subtitle: 'sign.in',
            value: 'login.user',
            path: '/login',
            isDefault: true,
            submenu: [
                { key: 'FORGOTTEN_LOGIN', subtitle: 'sign.in', value: 'password.reset', path: '/forgotten-login' },
                { key: 'RESET_ACCOUNT', value: 'password.reset', path: '/reset-password' },
            ],
        },
        { key: 'CREATE_NEW', subtitle: 'user.area.management.tool', value: 'create.account', path: '/new-account', query: (!loginChallenge ? '' : '?login_challenge=' + loginChallenge) },
    ],
    auth: [
        { key: 'DASHBOARD', value: 'dashboard', path: '/', isDefault: true },
        {
            key: 'ONLINE_SERVICES',
            value: 'online.services',
            path: '/online-services',
            submenu: [
                { key: 'TRADEMARKS', value: 'trademarks', path: '/online-services/trademarks' },
                { key: 'DESIGNS', value: 'designs', path: '/online-services/designs' },
            ],
        },
        { key: 'MY_APPLICATIONS', value: 'my.applications', path: '/my-applications' },
        { key: 'DRAFT_APPLICATIONS', value: 'my.draft.applications', path: '/my-drafts' },
        {
            key: 'SIGNATURES',
            value: 'signatures',
            path: '/signatures',
            Icon: sidebarIcons['waitingForSignatureCountIcon'],
            cannotAccess: (_userType, authorities) => _userType === 'CHILD',
        },
        {
            key: 'PAYMENTS',
            value: 'payments',
            path: '/payments/shopping-cart',
            Icon: sidebarIcons['waitingForPaymentCountIcon'],
            cannotAccess: (_userType, authorities) => !(authorities && authorities.includes('ROLE_PAYMENTS')),
        },
        {
            key: 'CORRESPONDENCE',
            value: 'correspondence',
            path: '/correspondence',
            Icon: sidebarIcons['correspondence'],
            cannotAccess: (_userType, authorities) =>
                !(authorities && (authorities.includes('ROLE_CORRESPONDENCE_READ_ONLY') || authorities.includes('ROLE_CORRESPONDENCE_READ_WRITE'))),
        },
        {
            key: 'MY_ACCOUNT',
            value: 'my.account',
            path: '/my-account/login-details',
            redirectTo: '/',
            submenu: [
                { key: 'LOGIN_DETAILS', value: 'login.details', path: '/my-account/login-details', includeParam: true },
                { key: 'CHANGE_PASSWORD', value: 'profile.details', path: '/my-account/profile-details', includeParam: true },
                { key: 'PREFERENCES', value: 'preferences', path: '/my-account/preferences', includeParam: true },
                {
                    key: 'CHILD_ACCOUNTS',
                    value: 'child.accounts',
                    path: '/my-account/child-accounts',
                    includeParam: true,
                },
                {
                    key: 'CREATE_CHILD_ACCOUNT',
                    value: 'create.child.account',
                    path: '/child-accounts/create',
                    cannotAccess: (_userType, authorities) => !authorities || !authorities.includes('ROLE_ACCOUNT_ADMINISTRATOR'),
                },
                {
                    key: 'EDIT_CHILD_ACCOUNT',
                    value: 'edit.child.account',
                    path: '/child-accounts/edit',
                    cannotAccess: (_userType, authorities) => !authorities || !authorities.includes('ROLE_ACCOUNT_ADMINISTRATOR'),
                },
            ],
        },
    ],
};
