import React from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

import { numberWithDecimals } from '../../common/utils/service';
import {bareDateTimeFormat, t} from '../../providers';

const valueWithTooltip = (text) => (
    <Tooltip arrow title={text} placement={'top'}>
        <span>{text}</span>
    </Tooltip>
);

const parseMultiple = (items) =>
    items.split(',').map((item, idx) => (
        <Tooltip key={idx} arrow placement={'top-start'} title={item}>
            <div className={'classification-item'}>{item}</div>
        </Tooltip>
    ));

const filterApplications = (contentList, filters = {}) => {
    let filteredList = contentList;
    if (!!Object.keys(filters).length) {
        const allFilters = Object.values(filters).reduce((acc, cur) => [...acc, ...cur], []);
        filteredList = contentList.filter((item) => allFilters.includes(item.type));
    }
    return filteredList;
};

const checkboxLabel = (checkAll, indeterminate, checked, onChange, readOnly, id) => {
    const onChangeValue = !readOnly ? onChange : () => {};
    return (
        <Checkbox
            indeterminate={checkAll ? indeterminate : null}
            checked={checked}
            onChange={onChangeValue}
            inputProps={{ 'aria-label': checkAll ? 'select all applications' : `select ${id}` }}
            disabled={readOnly}
        />
    );
};

const lastSubmitDateValue = (lastSubmitDate) => {
    let now = (new Date()).setHours(0,0,0,0);
    let overdue = !!lastSubmitDate && now > new Date(lastSubmitDate).setHours(0,0,0,0);
    return (
        <span style={{color: overdue? "red" : "black"}}>
            {bareDateTimeFormat(lastSubmitDate, 'YY-mm-dd')}
        </span>
    );
};

export const shoppingCartParserList = (
    applications,
    handleSelectAllApplications,
    handleSelectApplication,
    downloadDraftReceipt,
    deleteApplication,
    readOnly,
    submitWithoutPayment,
    enableSorting = false,
    confirmationPage = true,
    selectedApplications = [],
    filters = {}
) => {
    const content = filterApplications(applications, filters);
    const rowCount = content.length;

    const isSelected = (number) => selectedApplications.indexOf(number) !== -1;
    const numSelected = selectedApplications.length;
    const indeterminateAll = numSelected > 0 && numSelected < rowCount;
    const checkedAll = rowCount > 0 && numSelected === rowCount;

    let shoppingCartViewList = {
        headers: [
            !confirmationPage && {
                label: checkboxLabel(true, indeterminateAll, checkedAll, handleSelectAllApplications, readOnly),
                align: 'left',
                sortable: false,
            },
            { label: t('type'), code: 'TYPE', align: 'left', sortable: enableSorting },
            { label: t('denomination'), code: 'DENOMINATION', align: 'left', sortable: enableSorting },
            { label: t('user.reference'), code: 'USERREFERENCE', align: 'left', sortable: false },
            { label: t('application.or.number'), code: 'APPLICATIONNUMBER', align: 'left', sortable: enableSorting },
            { label: t('application.registration.number'), code: 'REGISTRATIONNUMBER', align: 'left', sortable: false },
            { label: t('applicants.name'), code: 'APPLICANTS', align: 'left', sortable: enableSorting },
            { label: t('last.submit.date'), code: 'LASTSUBMITDATE', align: 'left', sortable: false },
            { label: t('cost'), code: 'FEES', align: 'left', sortable: enableSorting },
            !confirmationPage && { label: '', code: 'actions', align: 'left', sortable: false },
        ].filter((item) => typeof item === 'object'), //remove non-objects from headers
    };

    let dataList = [];

    // table/list data
    const parsedApplications = (content || []).map((item, idx) => [
        !confirmationPage && {
            label: 'selectedApp',
            value: checkboxLabel(false, null, isSelected(item.filingNumber), (e) => handleSelectApplication(e, item.filingNumber, item.fees), readOnly, idx),
        },
        {
            label: 'type',
            value: item.type ? t(`app.type.${item.type}`) : '',
        },
        {
            label: 'denomination',
            value: item.denomination ? item.denomination : '',
        },
        {
            label: 'user.reference',
            value: item.userReference ? item.userReference : '',
        },
        {
            label: 'application.number',
            value: item.applicationNumber ? valueWithTooltip(item.applicationNumber) : '',
        },
        {
            label: 'registration.number',
            value: item.registrationNumber ? item.registrationNumber : '',
        },
        {
            label: 'applicants',
            value: item.applicant ? parseMultiple(item.applicant) : '',
        },
        {
            label: 'last.submit.date',
            value: (item.lastSubmitDate && lastSubmitDateValue(item.lastSubmitDate, 'YY-mm-dd')) || '',
        },
        {
            label: 'fees',
            value: !isNaN(item.fees) ? `${numberWithDecimals(item.fees / 100, 2)} €` : '',
        },
        !confirmationPage && {
            label: 'menuItems',
            value: [
                { label: t('draft.receipt'), action: () => downloadDraftReceipt(item.filingNumber) },
                !readOnly && { label: t('delete'), action: () => deleteApplication(item.filingNumber) },
                item.type === 'DS' && item.status === 'WAITING_FOR_PAYMENT' && {
                    label: t('submit.without.payment'), action: () => submitWithoutPayment(item.filingNumber)
                },
            ].filter((obj) => typeof obj === 'object'),
        },
    ]);

    //remove non-objects from data
    dataList = parsedApplications.map((cart) => cart.filter((item) => typeof item === 'object'));

    shoppingCartViewList = {
        ...shoppingCartViewList,
        data: dataList,
    };

    return shoppingCartViewList;
};

export const summaryShoppingCartParserList = (forSummary, handleSelectApplication, enableSorting = false) => {
    const content = forSummary;

    let summaryShoppingCartViewList = {
        headers: [
            { label: t('type'), code: 'TYPE', align: 'left', sortable: enableSorting },
            { label: t('denomination'), code: 'DENOMINATION', align: 'left', sortable: enableSorting },
            { label: t('user.reference'), code: 'USERREFERENCE', align: 'left', sortable: false },
            { label: t('application.number'), code: 'APPLICATIONNUMBER', align: 'left', sortable: enableSorting },
            { label: t('application.registration.number'), code: 'REGISTRATIONNUMBER', align: 'left', sortable: false },
            { label: t('applicants.name'), code: 'APPLICANTS', align: 'left', sortable: enableSorting },
            { label: t('cost'), code: 'FEES', align: 'left', sortable: enableSorting },
            { label: '', code: 'actions', align: 'left', sortable: false },
        ].filter((item) => typeof item === 'object'), //remove non-objects from headers
    };

    let dataList = [];

    // table/list data
    const parsedApplications = (content || []).map((item) => [
        {
            label: 'type',
            value: item.type ? t(`app.type.${item.type}`) : '',
        },
        {
            label: 'denomination',
            value: item.denomination ? item.denomination : '',
        },
        {
            label: 'user.reference',
            value: item.userReference ? item.userReference : '',
        },
        {
            label: 'application.number',
            value: item.applicationNumber ? valueWithTooltip(item.applicationNumber) : '',
        },
        {
            label: 'user.reference',
            value: item.registrationNumber ? item.registrationNumber : '',
        },
        {
            label: 'applicants',
            value: item.applicant ? parseMultiple(item.applicant) : '',
        },
        {
            label: 'fees',
            value: !isNaN(item.fees) ? `${numberWithDecimals(item.fees / 100, 2)} €` : '',
        },
        {
            label: 'removeBtn',
            value: <Button onClick={(event) => handleSelectApplication(event, item.filingNumber, item.fees, true)}>{t('remove')}</Button>,
        },
    ]);

    //remove non-objects from data
    dataList = parsedApplications.map((cart) => cart.filter((item) => typeof item === 'object'));

    summaryShoppingCartViewList = {
        ...summaryShoppingCartViewList,
        data: dataList,
    };

    return summaryShoppingCartViewList;
};
