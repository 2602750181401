const filters = [
    {
        globalsKey: 'categoriesCorr',
        filterid: 'CATEGORY',
        name: 'categories',
        placeholder: 'category',
        filterOptions: [],
        multiple: true,
    },
    {
        globalsKey: 'statusesCorr',
        filterid: 'CORRESPONDENCE_STATUS',
        name: 'statuses',
        placeholder: 'application.status',
        filterOptions: [],
        multiple: true,
    },
    {
        globalsKey: 'importantCorr',
        filterid: 'IMPORTANT',
        name: 'important',
        placeholder: 'correspondence.important',
        filterOptions: [],
        multiple: false,
    }
];

const getDropDownFilters = () => filters || [];

export default { getDropDownFilters };
