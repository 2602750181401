import { createSlice } from '@reduxjs/toolkit';

import { SLICE_NAME } from './constants';

export const slice = createSlice({
    name: SLICE_NAME,
    initialState: {
        shoppingCartList: null,
        error: null,
        loading: false,
        platformUrl: null,
        platformPaymentData: null,
    },
    reducers: {
        shoppingCartList: (state, action) => {
            state.shoppingCartList = action.payload;
        },
        loading: (state, action) => {
            state.loading = action.payload;
        },
        error: (state, action) => {
            state.error = action.payload;
        },
        platformUrl: (state, action) => {
            state.platformUrl = action.payload;
        },
        platformPaymentData: (state, action) => {
            state.platformPaymentData = action.payload;
        },
    },
});

export const shoppingCartList = (state) => state[SLICE_NAME].shoppingCartList;
export const loading = (state) => state[SLICE_NAME].loading;
export const error = (state) => state[SLICE_NAME].error;
export const platformUrl = (state) => state[SLICE_NAME].platformUrl;
export const platformPaymentData = (state) => state[SLICE_NAME].platformPaymentData;
export default slice.reducer;
