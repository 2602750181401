import React from 'react';

let _literals = {};
let _locale = 'lt';

/**
 *
 * @param {string} text The string with the parameters in {}
 * @param {object} params Replacement can be string or JSX
 * @param {string} options option to return string instead of JSX
 */
const replaceParams = (text, params, options) => {
    const paramRegExp = new RegExp(/{[a-zA-Z]+}/g);
    const matches = text.match(paramRegExp);
    let splits = text.split(paramRegExp);

    for (let i = 0; i < splits.length - 1; i++) {
        if (options === 'returnString') {
            splits[i] = `${splits[i]}${params[matches[i].slice(1, -1)]}`;
        } else {
            splits[i] = (
                <React.Fragment key={i}>
                    {splits[i]}
                    {params[matches[i].slice(1, -1)]}
                </React.Fragment>
            );
        }
    }
    return options === 'returnString' ? splits.join('') : splits;
};

const Translation = (key, params, options) => {
    const literals = _literals;

    // TODO: Refine this when getting translations from API.
    let translated = '';
    if (literals && literals[key]) {
        if (params) {
            translated = replaceParams(literals[key], params, options);
        } else {
            translated = literals[key];
        }
    } else if (typeof key === 'string') {
        translated = key.split('.').join(' ');
        translated = `${translated[0].toUpperCase() + translated.slice(1)} *`;
    }

    return translated;
};

export const setLiterals = (literals) => {
    _literals = { ...literals };
};

export const setLocale = (languageCode) => {
    _locale = languageCode;
};

export const getLocale = () => {
    return _locale;
};

export const t = (key, params, options) => Translation(key, params, options);
