import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    detail: {
        marginBottom: '10px',
        display: 'flex',
        fontSize: '14px',
        lineHeight: '20px',
        '@media (max-width:960px)': { marginBottom: '8px' },
    },
    detailsLabel: {
        fontWeight: '600',
        color: theme.palette.text.blackCommon,
        '@media (max-width:960px)': { textTransform: 'uppercase' },
    },
    detailsValue: {
        paddingLeft: '15px',
        color: theme.palette.text.greyMain,
        wordBreak: 'break-word',
        '@media (max-width:960px)': { paddingLeft: '8px' },
    },
}));
const Details = ({ label, value }) => {
    const classes = useStyles();
    return (
        <div className={classes.detail}>
            <span className={classes.detailsLabel}>{label}</span>
            <span className={classes.detailsValue}>{value}</span>
        </div>
    );
};

export default Details;
