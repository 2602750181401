import React, { useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useSelector } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import { globals as globalsSlice } from '../../../../app/globalsSlice';
import { userCan } from '../../../../common/utils/service';
import PageTitleBreadcrumb from '../../../../components/PageTitleBreadcrumb';
import { t } from '../../../../providers';
import { me as meSlice } from '../../../AuthenticatedPage/authenticationSlice';
import FieldsMissingDialog from '../../../MyAccountPage/components/ChildAccounts/FieldsMissingDialog';
import servicesItems from '../../servicesItems';
import ServicesList from '../ServicesList/ServicesList';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        height: '100%',
        maxWidth: 'calc(100% - 236px)',
        '@media (max-width:1100px)': {
            minWidth: '77%',
            maxWidth: 'unset',
        },
    },
    tabs: {
        '& .MuiTab-root': {
            minWidth: '100px',
        },
    },
    tabContainer: {
        width: '100%',
    },
    profileTabContainer: {
        '@media (min-width:1100px)': {
            // width: '653px',
        },
    },
}));

const EServicesPage = () => {
    const classes = useStyles();
    const { service } = useParams();
    const location = useLocation();
    const history = useHistory();
    const me = useSelector(meSlice);
    const { globals } = useSelector(globalsSlice) || {};
    const readOnly = !!(me || {}).impersonatedUser;

    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0); //index of tabs
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        const { authorities } = me;
        let allowedTabs = [];
        if (userCan(authorities, `ROLE_${service}S`.toUpperCase())) {
            allowedTabs = servicesItems[service]['categories'];
            if (!allowedTabs.some((tab) => tab === 'all')) allowedTabs.push('all');
            allowedTabs.sort(); // needed to be aligned with selectedTab index
        }

        setTabs(allowedTabs); //set tabs depending on tabs allowed based on authorities role

        if (allowedTabs.length <= 0) {
            history.push('/');
        }
    }, [service, me, history]);

    const handleTabChange = (event, value) => {
        event.preventDefault();
        setSelectedTab(value);
    };

    const navigateTo = (event, path, external) => {
        event.preventDefault();
        if (path && external) {
            if (!readOnly) {
                const isComplete = !!me && me.accountProfileCompleted;
                if (!isComplete) {
                    setDialogOpen(true);
                } else {
                    //get path form globals
                    let newPath = globals.ipo[path];
                    if (!!newPath && !newPath.startsWith('http')) newPath = globals.ipo['fo.url'].concat(globals.ipo[path]);
                    if (!!newPath) window.open(newPath, '_blank');
                }
            }
        } else {
            if (path && location.pathname !== path) {
                history.push(path);
            }
        }
    };

    const navigateToProfile = (event) => {
        event.preventDefault();
        setDialogOpen(false);
        history.push('/my-account/profile-details');
    };

    const cancelMessage = (event) => {
        event.preventDefault();
        setDialogOpen(false);
    };

    // filter e-services items based on tab selection
    let availableItems = servicesItems[service]['eServices'];
    if (selectedTab !== 0) {
        availableItems = availableItems.filter((item) => item.category === tabs[selectedTab]);
    }
    return (
        <div className={classes.container}>
            <PageTitleBreadcrumb
                parentTitle={t('online.services.title')}
                parentLink={`/online-services/${service}`}
                title={t(service + '.eServices')}
                details={t(service + '.description')}
            />
            <Tabs className={classes.tabs} value={selectedTab} onChange={handleTabChange} textColor="primary">
                {tabs.map((tab, index) => (
                    <Tab key={'tab-' + index} label={t(tab + '.tab.title')} />
                ))}
            </Tabs>
            {globals ? (
                <div className={classes.profileTabContainer}>
                    {service && <ServicesList readOnly={readOnly} items={availableItems} navigateTo={navigateTo} />}
                </div>
            ) : (
                <CircularProgress />
            )}
            <FieldsMissingDialog open={dialogOpen} onAccept={navigateToProfile} onCancel={cancelMessage} />
        </div>
    );
};

export default EServicesPage;
