import { sendRequest } from '../../common/utils';

export const fetchDetails = () => {
    return sendRequest({
        url: '/account/details',
        method: 'GET',
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const updateUserLanguage = ({ language }) => {
    return sendRequest({
        url: '/account/language',
        method: 'PUT',
        body: { language },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const updateUserPersonType = ({ personType }) => {
    return sendRequest({
        url: '/account/personType',
        method: 'PUT',
        body: { personType },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const updateUserPassword = ({ oldPassword, newPassword }) => {
    return sendRequest({
        url: '/account/password',
        method: 'PUT',
        body: { oldPassword, newPassword },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const updateDetails = (accountDetails) => {
    return sendRequest({
        url: '/account/details',
        method: 'PUT',
        body: accountDetails,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const registerNewChildAccount = (payload) => {
    return sendRequest({
        url: '/child/registration',
        method: 'POST',
        body: payload,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const fetchChildAccountsPaginated = ({ params }) => {
    const url = Object.keys(params).reduce((path, key, idx) => {
        return `${path}${(!!idx && '&') || ''}${key}=${params[key]}`;
    }, '/child/details?');
    return sendRequest({
        url,
        method: 'GET',
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const updateChildAccount = (payload) => {
    return sendRequest({
        url: '/child/details',
        method: 'PUT',
        body: payload,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const deactivateChild = (payload) => {
    return sendRequest({
        url: '/child/deactivation',
        method: 'POST',
        body: payload,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const activateChild = (payload) => {
    return sendRequest({
        url: '/child/activation',
        method: 'POST',
        body: payload,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const deactivateAccount = () => {
    return sendRequest({
        url: '/account/deactivation',
        method: 'POST',
        body: {},
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.code === 403 && error.message === 'deactivate.account.info') {
                return { message: 'deactivate.account.info', valid: false, reason: 'ACTIVE_CHILD_ACCOUNTS' };
            }
            return { error: { description: error.message, code: error.code } };
        });
};

export const confirmDeactivateAccount = () => {
    return sendRequest({
        url: '/account/deactivation/confirmation',
        method: 'POST',
        body: {},
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.code === 403 && error.message === 'deactivate.account.info') {
                return { message: 'deactivate.account.info', valid: false, reason: 'ACTIVE_CHILD_ACCOUNTS' };
            }
            return { error: { description: error.message, code: error.code } };
        });
};

export const lockAccount = ({ childUserName }) => {
    return sendRequest({
        url: '/account/lock',
        method: 'POST',
        body: { childUserName },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code, details: error.details } };
        });
};

export const cancelUsernameChangeRequest = () => {
    console.log('cancelUsernameChangeRequest');
    return sendRequest({
        url: '/account/username',
        method: 'DELETE'
    })
    .then((response) => {
        return response;
    })
    .catch((error) => {
        return { error: { description: error.message, code: error.code } };
    });
};
