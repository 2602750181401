import React, { useEffect, useState } from 'react';

import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    accordion: {
        fontSize: '14px',
        lineHeight: '20px',
        margin: '20px 0',
        borderBottom: '1px solid #E2E4E9',
    },
    title: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        // borderBottom: '1px solid #E7E7ED',
        // marginBottom: '20px',
        padding: '10px 0',
        textTransform: 'upperCase',
        color: theme.palette.text.greyMain,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    titleText: {
        marginLeft: '8px',
        color: theme.palette.text.blackCommon,
    },
    headerDetails: {
        // marginBottom: '10px'
        padding: '12px 0',
    },
    accordionBody: { minHeight: '50px' },
}));

const Accordion = ({ replyRef, id, title, visibleDetails, expanded, handleChange, children }) => {
    const classes = useStyles();
    const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        setVisibility(expanded);
    }, [expanded]);

    return (
        <div ref={replyRef} id={id} className={classes.accordion}>
            <div className={classes.accordionHead}>
                <div className={classes.title} onClick={handleChange}>
                    {visibility ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    <div className={classes.titleText}>{title}</div>
                </div>
                {visibleDetails && <div className={classes.headerDetails}>{visibleDetails}</div>}
            </div>
            <Collapse in={visibility}>
                <div className={classes.accordionBody}>{children}</div>
            </Collapse>
        </div>
    );
};

export default Accordion;
