import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { ReactComponent as DownloadIcon } from '../../../../assets/images/download.svg';
import MinimalPagination from '../../../../components/Pagination/MinimalPagination';
import VerticalMenu from '../../../../components/VerticalMenu';
import { bareDateTimeFormat, t } from '../../../../providers';
import { dueDateParser, canReply } from '../../../CorrespondencePage/utils';
import Attachments from '../Attachments';
import Details from '../Details';

const useStyles = makeStyles((theme) => ({
    incoming: {
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: '20px',
        paddingBottom: '20px',
        borderBottom: '1px solid #E2E4E9',
    },
    messageSection: {
        color: theme.palette.text.greyMain,
        marginBottom: '30px',
        height: 'unset',
        minHeight: '50px',
    },
    details: {
        '@media (max-width:420px)': {
            maxWidth: '100%',
            flexBasis: '100%',
        },
    },
    actionsSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '@media (max-width:420px)': { maxWidth: '100%', flexBasis: '100%' },
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingBottom: '10px',
        marginRight: '10px',
    },
    actionIcon: {
        padding: '0 10px',
        '&:hover': { cursor: 'pointer' },
    },
}));

const IncomingMobile = ({
    content = {},
    downloadAttachment,
    downloadCorrespondence,
    redirectToCorrespondence,
    maxRepliesAllowed,
    onClose,
    onReply,
    initReply,
    readOnly,
}) => {
    const classes = useStyles();
    const { message, subRows } = content;
    const {
        id,
        applicationId : number,
        applicationNumber,
        dossierType,
        procedure,
        subject,
        receivedDate,
        dueDate,
        hasReply,
        requiresReply,
        body: messageBody,
        messageSearchAttachment: attachments,
        previousId,
        index,
        nextId,
        direction
    } = message || {};
    const replies = subRows;

    const pagination = { previousId, index, nextId };

    const [initiateReply, setInitiateReply] = useState(initReply);

    useEffect(() => {
        setInitiateReply(initReply);
    }, [initReply]);

    useEffect(() => {
        if (message && !!Object.keys(message).length && initiateReply) {
            setInitiateReply(false);
            onReply();
        }
    }, [message]);

    const showReply = (direction !== 'BO_INCOMING') && canReply(dueDate, requiresReply, replies, maxRepliesAllowed, readOnly);

    const handleFieldsView = (fieldvalue, textValue) => (fieldvalue ? textValue || fieldvalue : '');

    const menuOptions = [showReply && { label: t('reply'), action: () => onReply() }, { label: t('close.and.back'), action: onClose }].filter(
        (item) => typeof item === 'object'
    ); //remove non-objects from

    return (
        <div className={classes.incoming}>
            {/* details */}
            <Grid container spacing={2}>
                <Grid item xs={10} className={classes.details}>
                    <Details label={`${t('category')}:`} value={handleFieldsView(t('correspondence.category.' + dossierType))} />
                    <Details label={`${t('subject')}:`} value={handleFieldsView(subject)} />
                    <Details
                        label={`${t('app.number')}:`}
                        value={
                            <Link
                                to={{
                                    pathname: '/my-applications',
                                    state: { appNumber: number },
                                }}>
                                {handleFieldsView(number)}
                            </Link>
                        }
                    />
                    {direction !== 'BO_INCOMING' && (
                        <Details label={`${t('received.date')}:`} value={handleFieldsView(receivedDate, bareDateTimeFormat(receivedDate))} />
                    )}
                    {direction === 'BO_INCOMING' && (
                        <Details label={`${t('sent.date')}:`} value={handleFieldsView(receivedDate, bareDateTimeFormat(receivedDate))} />
                    )}
                    <Details
                        label={`${t('due.date')}:`}
                        value={dueDate && handleFieldsView(dueDate, dueDateParser(requiresReply, dueDate, hasReply))}
                    />
                </Grid>
                <Grid item xs={2} className={classes.actionsSection}>
                    {/*
                    <div className={classes.actions}>
                        <DownloadIcon className={classes.actionIcon} onClick={() => downloadCorrespondence(id)} />
                        <VerticalMenu actionButtonId={'incomingMobileOptions'} menuItems={menuOptions} />
                    </div>
                    */}
                    <MinimalPagination pagination={pagination} redirectTo={redirectToCorrespondence} />
                </Grid>
                <Grid item xs={12}>
                    {/* message */}
                    <div className={classes.messageSection} dangerouslySetInnerHTML={{ __html: (messageBody !== null) ? messageBody : t('correspondence.message.default.content') }} />
                    {/* attachments */}
                    {attachments && !!attachments.length && <Attachments attachments={attachments} handleDownload={downloadAttachment} />}
                </Grid>
            </Grid>
        </div>
    );
};

export default IncomingMobile;
