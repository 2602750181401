import React, { useState, useEffect } from 'react';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';

import { t } from '../../providers';
import VerticalMenu from '../VerticalMenu';
import MobileSorting from './MobileSorting';

const useStyles = makeStyles((theme) => ({
    gridSection: {
        width: 'calc(100% - 20px)',
        padding: '0px',
        marginBottom: '10px',
        '@media (max-width:960px)': { width: 'auto' },
        '& .MuiGrid-container': {
            '@media (max-width:960px)': { justifyContent: 'center' },
        },
    },
    appCard: {
        minWidth: '300px',
    },
    appCardBlue: {
        minWidth: '300px',
        border: '1px solid #80ACFF',
        borderRadius: '0px',
        '& $detailsSection': {
            background: 'linear-gradient(90deg, #80ACFF 1.5%, #F0F5FF 1.5%)',
        },
    },

    actionsSection: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px',
        marginTop: '-48px',
    },
    detailsSection: {
        padding: '0 4px 0 16px',
        height: '159px',
        overflowY: 'hidden',
        backgroundColor: '#ffffff',
        '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '3px',
            backgroundColor: theme.palette.scrollbar.main,
        },
        '& $detail': { margin: '7px 0' },
    },
    detailsSubSection: {
        padding: '0 4px 0 16px',
        // height: '159px',
        maxHeight: '120px',
        overflowY: 'hidden',
        backgroundColor: '#FAFAFA',
        borderBottom: '2px solid #E7E7ED', //#ffffff',
        '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '3px',
            backgroundColor: theme.palette.scrollbar.main,
        },
        '& $detail': { margin: '7px 0' },
    },
    detail: {},
    detailTitle: {
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '15px',
        textTransform: 'uppercase',
        color: theme.palette.text.gridDetailTitle,
    },
    status: {
        float: 'right',
        marginRight: '15px',
    },
    infoSection2: {
        float: 'right',
        marginRight: '42px',
        marginTop: '-34px',
        color: '#6B6C7E',
    },
    unfold: {
        height: '20px',
    },
    less: {
        height: '20px',
    },
    detailValue: {
        maxWidth: 'calc(100% - 120px)',
        flexWrap: 'wrap',
        display: 'inline-flex',
        fontSize: '14px',
        lineHeight: '15px',
        color: theme.palette.text.gridDetailValue,
        marginLeft: '5px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&.status': {
            padding: '5px',
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '20px',
            color: theme.palette.text.statusGreen,
            border: '1px solid ' + theme.palette.text.statusGreen,
            boxSizing: 'border-box',
            borderRadius: '2px',
            textTransform: 'lowercase',
        },
        '& .classification-item': {
            background: theme.palette.text.lightBg,
            border: '1px solid ' + theme.palette.text.lightBg,
            boxSizing: 'border-box',
            borderRadius: '8px',
            fontWeight: '600',
            fontSize: '10px',
            lineHeight: '20px',
            textTransform: 'uppercase',
            color: theme.palette.text.darkGrey,
            display: 'flex',
            alignItems: 'center',
            marginRight: '5px',
            marginTop: '3px',
            padding: '0 4px',
        },
    },
}));

const CorrespondenceGrid = ({ sortableFields, sortBy, sortType, setSorting, correspondence, gridId = 'gridId' }) => {
    const classes = useStyles();
    const [openCollapse, setOpenCollapse] = useState([]);

    useEffect(() => {
        if (correspondence) {
            let hasSubRows = [];
            correspondence.map((corr, corrIdx) => {
                const { subrows } = corr.details.find((item) => item.label === 'subrows');
                if (!!subrows.length) {
                    hasSubRows.push({ corrIdx: corrIdx, open: false });
                }
            });
            setOpenCollapse(hasSubRows);
        }
    }, [correspondence]);

    const isOpen = (id) => {
        let isOpen = false;
        openCollapse.map((row) => {
            if (row.corrIdx === id && row.open) {
                isOpen = true;
            }
        });
        return isOpen;
    };

    const setOpenRow = (id) => {
        let updOpenRow = [];
        openCollapse.map((row) => {
            if (row.corrIdx === id) {
                row = { corrIdx: row.corrIdx, open: !row.open };
            }
            updOpenRow.push(row);
        });
        setOpenCollapse(updOpenRow);
    };

    return (
        <div className={classes.gridSection} id={`grid-${gridId}`}>
            <MobileSorting
                sortableFields={sortableFields}
                sortBy={sortBy}
                sortType={sortType}
                handleSorting={setSorting}
                text={t('sorting.correspondence.text', { total: correspondence.length })}
            />
            <Grid container direction="row" spacing={0}>
                {(!correspondence || !correspondence.length) && (
                    <Grid item xs={12} align={'center'}>
                        {t('no.correspondence')}
                    </Grid>
                )}
                {(correspondence || []).map((corr, corrIdx) => {
                    const { subrows } = corr.details.find((item) => item.label === 'subrows');
                    return (
                        <Grid id={`grid-${gridId}-item-${corrIdx}`} key={corrIdx} item xs={12}>
                            <Card className={corr.isNew ? classes.appCardBlue : classes.appCard}>
                                {/* details */}
                                <div className={classes.detailsSection}>
                                    {corr.details &&
                                        corr.details
                                            .filter((item) => typeof item === 'object') // display only details items that is type of object
                                            .map((item, idx) => (
                                                <div key={idx}>
                                                    <div key={idx} className={classes.detail}>
                                                        {item.code === 'status' && <span className={classes.status}>{item.value}</span>}
                                                        {item.code !== 'status' && item.label !== 'subrows' && (
                                                            <span className={classes.detailTitle}>{item.label}:</span>
                                                        )}
                                                        {item.code !== 'status' && item.label !== 'subrows' && (
                                                            <span
                                                                className={
                                                                    item.extraClass
                                                                        ? [classes.detailValue, item.extraClass].join(' ')
                                                                        : classes.detailValue
                                                                }>
                                                                {item.value}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                </div>

                                {/* action */}
                                <div className={classes.actionsSection}>
                                    <VerticalMenu actionButtonId={`grid-${gridId}-actionButton-${corrIdx}`} menuItems={corr.menuItems} />
                                </div>

                                <div className={classes.infoSection2}>
                                    {!!subrows.length &&
                                        (isOpen(corrIdx) ? (
                                            <UnfoldLessIcon className={classes.less} onClick={() => setOpenRow(corrIdx)} />
                                        ) : (
                                            <UnfoldMoreIcon className={classes.unfold} onClick={() => setOpenRow(corrIdx)} />
                                        ))}
                                </div>
                            </Card>
                            {!!subrows.length && isOpen(corrIdx) && (
                                <Grid id={`grid-${gridId}-item-${corrIdx}`} key={corrIdx} item xs={12}>
                                    <Card className={classes.appCard}>
                                        {/* details */}
                                        {subrows.map((item2, index) => (
                                            <div className={classes.detailsSubSection} key={index}>
                                                {item2.map((item, idx) => {
                                                    return (
                                                        <React.Fragment key={idx}>
                                                            <div>
                                                                <div key={idx} className={classes.detail}>
                                                                    {item.code === 'status' && <span className={classes.status}>{item.value}</span>}
                                                                    {item.code !== 'status' && item.label !== 'menuItems' && (
                                                                        <span className={classes.detailTitle}>{item.label}:</span>
                                                                    )}
                                                                    {item.code !== 'status' && item.label !== 'menuItems' && (
                                                                        <span
                                                                            className={
                                                                                item.extraClass
                                                                                    ? [classes.detailValue, item.extraClass].join(' ')
                                                                                    : classes.detailValue
                                                                            }>
                                                                            {item.value}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {item.label === 'menuItems' && (
                                                                <div className={classes.actionsSection}>
                                                                    <VerticalMenu
                                                                        actionButtonId={`grid-${gridId}-actionButton-${corrIdx}`}
                                                                        menuItems={item.menuItems}
                                                                    />
                                                                </div>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        ))}
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

export default CorrespondenceGrid;
