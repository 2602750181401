import { all, call, put, takeLatest } from 'redux-saga/effects';

import {downloadFile} from '../../common/utils/service';
import { SLICE_NAME } from './constants';
import { fetchPaymentHistory, downloadReceipt } from './service';

function* requestPaymentHistory(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(fetchPaymentHistory, {
        ...action.payload,
    });
    if (!response.error) {
        yield put({
            type: `${SLICE_NAME}/list`,
            payload: response,
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* getPaymentHistory() {
    yield takeLatest(`${SLICE_NAME}/getPaymentHistory`, requestPaymentHistory);
}

function* requestDownloadInvoice(action) {
    yield all([put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(downloadReceipt, {
        ...action.payload,
    });
    if (!response.error) {
        downloadFile(response);
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.code === 403 ? response.error : response.error.description });
    }
}

function* downloadPaymentInvoice() {
    yield takeLatest(`${SLICE_NAME}/downloadInvoice`, requestDownloadInvoice);
}

export { getPaymentHistory, downloadPaymentInvoice };
