import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        margin: '0 10px',
        padding: '0 8px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    disabled: {
        '&:hover': { cursor: 'not-allowed' },
    },
    current: {
        lineHeight: '21px',
        color: theme.palette.text.grey700,
        fontWeight: '700',
        padding: '6px 12px',
        background: '#DAE8F6',
        opacity: ' 0.6',
        borderRadius: '4px',
    },
}));
const MinimalPagination = ({ pagination = {}, redirectTo }) => {
    const classes = useStyles();
    const { previousId, index: currentListing, nextId } = pagination;

    return (
        <div className={classes.pagination}>
            {currentListing && (
                <React.Fragment>
                    <NavigateBeforeIcon
                        id={'previous-btn'}
                        aria-label={'previous'}
                        className={[classes.icon, !previousId ? classes.disabled : ''].join(' ')}
                        onClick={() => previousId && redirectTo(previousId, false, currentListing)}
                        color={previousId ? 'secondary' : 'disabled'}
                    />

                    <div className={classes.current}>{currentListing}</div>

                    <NavigateNextIcon
                        id={'next-btn'}
                        aria-label={'next'}
                        className={[classes.icon, !nextId ? classes.disabled : ''].join(' ')}
                        onClick={() => nextId && redirectTo(nextId, true, currentListing)}
                        color={nextId ? 'secondary' : 'disabled'}
                    />
                </React.Fragment>
            )}
        </div>
    );
};

export default MinimalPagination;
