import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import VerticalMenu from '../VerticalMenu';
import ApplicationsTableClientSideHeader from './ApplicationsTableClientSideHeader';

const useStyles = makeStyles((_theme) => ({
    actions: {
        width: '3%',
    },
    tableContainer: {
        minHeight: '200px',
        '@media (max-width:960px)': {
            width: 'calc(100vw - 80px)',
        },
        '& .classification-item': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    },
    removeBtn: {
        overflow: 'unset',
    },
    imageCell: {
        textAlign: 'center',
        height: '40px',
    },
}));

const ApplicationsTableClientSide = ({ sortBy, sortType, setSorting, applications, tableId = 'table', size, page, noAvailableDataLabel }) => {
    const classes = useStyles();
    let tableData = applications.data;
    if (tableData && tableData.length > 0) {
        tableData = tableData.slice(page * size, page * size + size);
    }

    const tableHead = (
        <ApplicationsTableClientSideHeader
            setSorting={setSorting}
            sortBy={sortBy}
            sortType={sortType}
            headers={applications.headers || []}
            tableId={tableId}
        />
    );

    return (
        <TableContainer className={classes.tableContainer}>
            <Grid item xs={12}>
                {applications && applications.headers && applications.headers.length > 0 && (
                    <Table id={`table-${tableId}`} className={classes.table} aria-label="simple table">
                        {tableHead}
                        <TableBody>
                            {(!applications.data || applications.data.length === 0) && (
                                <TableRow className={classes.row}>
                                    <TableCell colSpan={applications.headers.length} align="center">
                                        {noAvailableDataLabel}
                                    </TableCell>
                                </TableRow>
                            )}
                            {(tableData || []).map((app, appIdx) => {
                                return (
                                    <TableRow key={appIdx}>
                                        {app.map((item, idx) => {
                                            const cellClass = item.label === 'graphicalRepresentation' ? classes.imageCell : '';
                                            return (
                                                <React.Fragment key={idx}>
                                                    {item.label === 'menuItems' ? (
                                                        <TableCell className={classes.actions} align={item.align || 'left'}>
                                                            <VerticalMenu
                                                                actionButtonId={`${tableId}-table-actionButton-${appIdx}`}
                                                                menuItems={item.value}
                                                            />
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell
                                                            align={item.align || 'left'}
                                                            className={[cellClass, item.label === 'removeBtn' ? classes.removeBtn : ''].join(' ')}>
                                                            {item.value}
                                                        </TableCell>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                )}
            </Grid>
        </TableContainer>
    );
};

export default ApplicationsTableClientSide;
