import React from 'react';

import { Tooltip } from '@material-ui/core';

import { bareDateTimeFormat, t } from '../../providers';

const TRADEMARKS = 'trademark';
const DESIGNS = 'design';
const ESERVICES = 'eservice';

const valueWithTooltip = (text) => (
    <Tooltip arrow title={text} placement={'left'}>
        <span>{text}</span>
    </Tooltip>
);

export const draftApplicationsParserList = (
    applications,
    category,
    deleteApplication,
    resumeApplication,
    lockApplication,
    unlockApplication,
    duplicateApplication,
    readOnly
) => {
    const { content } = applications;

    let applicationsViewList = {
        headers: [
            category.includes(TRADEMARKS) && {
                label: t('trademark.name'),
                code: 'denomination',
                align: 'left',
                sortable: false,
            },
            category.includes(DESIGNS) && {
                label: t('design.name'),
                code: 'denomination',
                align: 'left',
                sortable: false,
            },
            category.includes(ESERVICES) && {
                label: t('eService.name'),
                code: 'eserviceName',
                align: 'left',
                sortable: true,
            },
            category.includes(ESERVICES) && {
                label: t('application.number'),
                code: 'associatedApplications',
                align: 'left',
                sortable: false,
            },
            { label: t('last.modified.date'), code: 'lastModifiedDate', align: 'left', sortable: true },
            { label: t('last.modified.by'), code: 'lastModifiedBy', align: 'left', sortable: true },
            { label: t('applicants.name'), code: 'applicant', align: 'left', sortable: true },
            category.includes(DESIGNS) && {
                label: t('numberDesigns'),
                code: 'associatedDesignNumber',
                align: 'left',
                sortable: false,
            },
            { label: t('user.reference'), code: 'userReference', align: 'left', sortable: false },
            { label: t('filing.number'), code: 'number', align: 'left', sortable: false },
            !readOnly && { label: '', code: 'actions', align: 'left', sortable: false },
        ].filter((item) => typeof item === 'object'), //remove non-objects from headers
    };

    let dataList = [];

    // table/list data
    const parsedApp = (content || []).map((app) => [
        category.includes(TRADEMARKS) && {
            label: t('denomination'),
            value: app.denomination ? app.denomination : '',
        },
        category.includes(DESIGNS) && {
            label: t('denomination'),
            value: app.denomination ? app.denomination : '',
        },
        category.includes(ESERVICES) && { label: t('eserviceName'), value: app.eserviceName ? t('eservice.name.' + app.eserviceName) : '' },
        category.includes(ESERVICES) && {
            label: t('associatedRight'),
            value: app.associatedRight ? valueWithTooltip(app.associatedRight) : '',
        },
        {
            label: t('lastModifiedDate'),
            value: (app.lastModifiedDate && bareDateTimeFormat(app.lastModifiedDate, 'lt')) || '',
        },
        { label: t('lastModifiedBy'), value: app.lastModifiedBy ? app.lastModifiedBy : '' },
        { label: t('applicant'), value: app.applicant ? valueWithTooltip(app.applicant) : '' },
        category.includes(DESIGNS) && {
            label: t('associatedDesignNumber'),
            value: app.associatedDesignNumber ? app.associatedDesignNumber : '',
        },
        { label: t('user.reference'), value: app.userReference ? app.userReference : '' },
        { label: t('application.number'), value: app.number ? app.number : '' },
        !readOnly && {
            label: 'menuItems',
            value: [
                // app.locked
                //     ? { label: t('unlock.draft.application'), action: () => unlockApplication(app.id, app.ipRightType) }
                //     : { label: t('lock.draft.application'), action: () => lockApplication(app.id, app.ipRightType) },
                !app.locked && { label: t('delete.draft.application'), action: () => deleteApplication(app.id, app.ipRightType) },
                { label: t('resume.draft.application'), action: () => resumeApplication(app.id, app.ipRightType) },
                // { label: t('duplicate.draft.application'), action: () => duplicateApplication(app.id) },
            ].filter((item) => typeof item === 'object'),
        },
    ]);

    //remove non-objects from data
    dataList = parsedApp.map((app) => {
        return app.filter((item) => typeof item === 'object');
    });

    applicationsViewList = {
        ...applicationsViewList,
        data: dataList,
    };

    return applicationsViewList;
};

export const filtersParser = (filters) => {
    const startDateTime = 'T00:00:00';
    const endDateTime = 'T23:59:59';
    let parsedFilters = { ...filters };
    // remove mark word from trademark types
    if (parsedFilters.types && parsedFilters.types.length > 0) {
        const parsedTypes = parsedFilters.types.map((item) => item.replace(' mark', ''));
        parsedFilters = { ...parsedFilters, types: parsedTypes };
    }

    // Normalize date ranges to API accepted date range data type, if any.
    if (parsedFilters.dates) {
        parsedFilters = {
            ...parsedFilters,
            dates: parsedFilters.dates.reduce((allDates, { dateType, startDate, endDate }) => {
                if (!!dateType && !!startDate && !!endDate) {
                    allDates.push({
                        date: dateType,
                        dateFrom: `${bareDateTimeFormat(startDate, 'YY-mm-dd')}${startDateTime}`,
                        dateTo: `${bareDateTimeFormat(endDate, 'YY-mm-dd')}${endDateTime}`,
                    });
                }
                return allDates;
            }, []),
        };
    }

    return parsedFilters;
};

export const myDraftsExportColumns = (category) => {
    return [
        category.includes(TRADEMARKS) && t('trademark.name'),
        category.includes(DESIGNS) && t('denomination'),
        category.includes(ESERVICES) && t('eService.name'),
        category.includes(ESERVICES) && t('associated.applications'),
        t('last.modified.date'),
        t('last.modified.by'),
        t('applicants.name'),
        category.includes(DESIGNS) && t('numberDesigns'),
        t('user.reference'),
        t('filing.number'),

    ]
        .filter((item) => typeof item !== 'boolean')
        .map((colName) => colName.toUpperCase());
};
