import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';

import Card from '../../../../components/Card';
import { t, bareDateTimeFormat } from '../../../../providers';
import { me as meSlice } from '../../../AuthenticatedPage/authenticationSlice';
import { loading as loadingSlice, requestResult } from '../../accountSlice';
import { SLICE_NAME } from '../../constants';
import ResetPasswordDialog from './ResetPasswordDialog';
import ChangeUsernameDialog from './ChangeUsernameDialog';

const useStyles = makeStyles((theme) => ({
    changePasswordButton: {
        // height: '2rem',
        width: '9rem',
        marginBottom: '16px',
        marginLeft: 0,
    },
    fieldLabel: {
        color: theme.palette.text.label,
        fontSize: '10px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: 'normal',
    },
    fieldValue: {
        color: theme.palette.text.value,
        fontSize: '12px',
        letterSpacing: 'normal',
        marginBottom: '10px',
    },
}));

const LoginDetails = () => {
    const dispatch = useDispatch();
    const me = useSelector(meSlice);
    const loading = useSelector(loadingSlice);
    const response = useSelector(requestResult);

    const { impersonatedUser: readOnly } = me || {};

    const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
    const [usernameChangeDialogState, setUsernameChangeDialogState] = useState(false);

    const [errorMessageKey, setErrorMessageKey] = useState(new URLSearchParams(window.location.search).get('errorMessageKey'));

    useEffect(() => {
        const { username } = me;
        if (username && (!me || !me.email)) dispatch({ type: `${SLICE_NAME}/getAccountDetails`, payload: { userName: username } });
    }, [dispatch, me]);

    useEffect(() => {
        if (response && response.ok && (response.fromAction === 'cancelUsernameChange' || response.fromAction === 'requestUsernameChange')) {
            dispatch({ type: `auth/getUserinfo` });
        }
    }, [dispatch, response]);


    useEffect(() => {
        dispatch({ type: `auth/getUserinfo` });
    }, [dispatch, loading]);

    const classes = useStyles();

    const closeChangePasswordDialog = () => setChangePasswordDialogOpen(false);

    const changePassword = () => {
        setChangePasswordDialogOpen(true);
    };

    const openUsernameChangeDialog = () => setUsernameChangeDialogState(true);
    const closeUsernameChangeDialog = () => setUsernameChangeDialogState(false);

    const cancelUsernameChangeRequest = (event) => {
        event.preventDefault();
        dispatch({ type: `${SLICE_NAME}/cancelUsernameChange` });
    };

    const renderItem = (
        <>
            <ChangeUsernameDialog open={usernameChangeDialogState} closeDialog={closeUsernameChangeDialog} />
            <ResetPasswordDialog open={changePasswordDialogOpen} closeDialog={closeChangePasswordDialog} />
            <Typography color="error">{t(errorMessageKey)}</Typography>
            <Card title={'user.login.details'} canEdit={false} cardId={'login_details_card'}>
                <Typography className={classes.fieldLabel}>{t('email.address')}</Typography>
                {!me.newUsernameRequested && (
                    <Button className={classes.changePasswordButton} variant="outlined" onClick={openUsernameChangeDialog} disabled={readOnly}>
                        {t('change.email')}
                    </Button>
                )}
                <Typography className={classes.fieldValue}>{(me && me.email) || '-'}</Typography>
                {!!me.newUsernameRequested && (
                    <>
                        <Typography>{t('new.username.requested', { newUsername: me.newUsernameRequested })}</Typography>
                        <Button className={classes.changePasswordButton} variant="outlined" onClick={cancelUsernameChangeRequest} disabled={readOnly}>
                        {t('cancel.username.change.request')}
                        </Button>
                    </>
                )}
                <Typography className={classes.fieldLabel}>{t('password')}</Typography>
                <Button className={classes.changePasswordButton} variant="outlined" onClick={changePassword} disabled={readOnly}>
                    {t('change.password')}
                </Button>
                <Typography className={classes.fieldLabel}>{t('last.login')}</Typography>
                <Typography className={classes.fieldValue}>{(me && me.lastLogin && bareDateTimeFormat(me.lastLogin, 'lt')) || '-'}</Typography>
                <Typography className={classes.fieldLabel}>{t('failed.login.attempts')}</Typography>
                <Typography className={classes.fieldValue}>
                    {(me && me.failedLoginNumber) || 0} {t('attempts')}
                </Typography>
            </Card>
        </>
    );

    return loading && !changePasswordDialogOpen ? <CircularProgress /> : renderItem;
};

export default LoginDetails;
