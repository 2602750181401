import React, {useState} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import {makeStyles} from '@material-ui/core/styles';
import ApplicationsTableClientSide from '../../../../../components/ApplicationsTableClientSide';
import Card from '../../../../../components/Card';
import ErrorInput from '../../../../../components/ErrorInput';
import PageTitle from '../../../../../components/PageTitle';
import {t} from '../../../../../providers';
import {ReactComponent as AddIcon} from '../../../../../assets/images/plus.svg';
import Typography from '@material-ui/core/Typography';
import inputsMaxChars from '../../../../../app/inputsMaxChars';
import {freeOfInvalidChars, isEmail} from '../../../../../common/utils/service';
import lt from 'date-fns/locale/lt';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {TableCell, TableRow} from '@material-ui/core';
import WarningModal from "../../../../../components/WarningModal";

const ACCEPTED_FORMATS = '.PDF, .JPG, .JPEG';

const useStyles = makeStyles((theme) => ({
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width:1100px)': {
            display: 'block',
        },
    },
    cardTable: {
        marginRight: '20px',
        '@media (max-width:960px)': {
            marginRight: 'unset',
        },
    },
    tableActionsSummary: {
        color: theme.palette.text.detailsGrey,
        fontSize: theme.typography.fontSize,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '&  span': {
            padding: '5px',
        },
        '@media (max-width:700px)': {
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
    },
    totalCost: {
        fontWeight: theme.typography.fontWeightBold,
    },
    inputWrapper: {
        marginTop: '30px',
    },
    inputItem: {
        marginBottom: '10px',
        flex: '1 1 auto',
    },
    textInput: {
        margin: '10px 0',
    },
    datePickerWrapper: {
        width: '255px',
        height: '20px',
        color: '#455A64',
        border: '1px solid #B0BEC5',
        font: 'inherit',
        margin: '10px 0',
        'font-size': '14px',
        'border-radius': '3px',
        'padding-left': '6px',
    },
    hiddenInput: {
        display: 'none',
    },
}));

const PaidInAdvanceTable = ({
                                loading,
                                shoppingCartApplications,
                                summaryShoppingCartList,
                                selectedApplications,
                                setSorting,
                                sortProps,
                                totalCost,
                                onConfirm,
                                onCancelConfirm,
                                readOnly,
                            }) => {
    const classes = useStyles();

    const [paymentReference, setPaymentReference] = useState({value: '', errorMessage: null});
    const [files, setFiles] = useState({files: [], isDirty: false});
    const [paymentDate, setPaymentDate] = useState({value: null, isDirty: false});
    const [purpose, setPurpose] = useState({value: '', errorMessage: null});
    const [payer, setPayer] = useState({value: '', errorMessage: null});
    const [amount, setAmount] = useState({value: 0, isNumber: false, errorMessage: null});
    const [additionalEmail, setAdditionalEmail] = useState({value: '', isValid: true, isDirty: false});
    const [executing, setExecuting] = useState(false);
    const [dialog, setDialog] = useState({
        visibility: false,
        message: '',
        onConfirmation: () => {
        },
        confirmButtonText: '',
        cancelButtonText: '',
        onCancel: () => {
        },
    });

    const handlePaymentReference = (event) => {
        const value = event.target.value;
        let error = null;
        if (!freeOfInvalidChars(value)) error = 'invalid.characters.error';
        if (value.length <= 200) setPaymentReference({value: event.target.value, errorMessage: error});
    };

    const handlePurpose = (event) => {
        const value = event.target.value;
        let error = null;
        if (!freeOfInvalidChars(value)) error = 'invalid.characters.error';
        if (!value) error = 'required.field';
        if (value.length <= 200) setPurpose({value: event.target.value, errorMessage: error});
    }

    const handlePayer = (event) => {
        const value = event.target.value;
        let error = null;
        if (!freeOfInvalidChars(value)) error = 'invalid.characters.error';
        if (!value) error = 'required.field';
        if (value.length <= 200) setPayer({value: event.target.value, errorMessage: error});
    }

    const handleAmount = (event) => {
        const value = event.target.value;
        let error = null;
        if (value === '' || !value) {
            setAmount({value: 0, isNumber: false, errorMessage: error})
        } else {
            const integerValue = parseInt(value * 100, 10);
            if (integerValue < totalCost) error = 'payment.amount.too.small';
            if (integerValue > totalCost) error = 'payment.amount.too.big';
            setAmount({value: integerValue, isNumber: true, errorMessage: error})
        }
    }

    const handlePaymentDate = (dateValue) => {
        if (!dateValue || dateValue > new Date()) {
            setPaymentDate({value: dateValue, isDirty: true});
        } else {
            setPaymentDate({value: dateValue, isDirty: false});
        }
    }

    const handleAdditionalEmail = (event) => {
        const value = event.target.value;
        const clean = inputsMaxChars.email && value.length <= inputsMaxChars.email;
        const isValid = !value || isEmail(value);
        setAdditionalEmail({value: event.target.value, isValid: isValid, isDirty: !clean});
    };

    const handleFileUpload = (event) => {
        for (let i = 0; i < event.target.files.length; i++) {
            files.files.push(event.target.files[i]);
            setFiles({files: files.files, isDirty: false});
        }
    };

    const removeFile = (index) => {
        if (index > -1) {
            files.files.splice(index, 1);
            setFiles({files: files.files, isDirty: files.files.length < 1});
        }
    }

    const handleConfirm = () => {
        const validForm = validateForm();
        if (!validForm) {
            return;
        }
        if (executing) {
            return;
        }
        if (isOverdueApplicationSelected(paymentDate.value.toLocaleDateString("lt"))) {
            setDialog({
                visibility: true,
                message: t('pay.application.overdue.warning'),
                onConfirmation: handleCloseDialog,
                confirmButtonText: t('ok'),
                cancelButtonText: '',
                onCancel: () => {},
            });
            return;
        }
        setExecuting(true);
        const params = {
            paymentReference: paymentReference.value,
            paymentDate: paymentDate.value.toLocaleDateString("lt") + 'T' + paymentDate.value.toLocaleTimeString("lt") + '.000Z',
            purpose: purpose.value,
            payer: payer.value,
            amount: amount.value,
            additionalEmail: additionalEmail.value,
            files: files.files,
        }
        onConfirm(params).then((response) => {
            if (response.error) {
                handleError(response.error);
            } else {
                onCancelConfirm({paymentCompleted: true});
            }
        });
    };

    const handleError = (backendError) => {
        let messageModal;
        if (backendError.code === 403) {
            messageModal = t(backendError.description, {username: backendError.details.username});
        } else {
            messageModal = t(backendError.description);
        }
        setDialog({
            visibility: true,
            message: messageModal,
            onConfirmation: handleCloseDialog,
            confirmButtonText: t('ok'),
            cancelButtonText: '',
            onCancel: handleCloseDialog,
        });
    }

    const validateForm = () => {
        let validForm = true;
        if (paymentReference.length > 200) {
            validForm = false;
        }
        if (files.files.length < 1) {
            setFiles({files: [], isDirty: true});
            validForm = false;
        }
        if (!paymentDate.value) {
            setPaymentDate({value: paymentDate.value, isDirty: true});
            validForm = false;
        }
        if (!purpose.value) {
            setPurpose({value: purpose.value, errorMessage: 'required.field'});
            validForm = false;
        }
        if (!!purpose.errorMessage) {
            validForm = false;
        }
        if (!payer.value) {
            setPayer({value: payer.value, errorMessage: 'required.field'});
            validForm = false;
        }
        if (!!payer.errorMessage) {
            validForm = false;
        }
        if (!amount.isNumber) {
            setAmount({value: 0, isNumber: false, errorMessage: 'required.field'});
            validForm = false;
        }
        if (!amount.isNumber || amount.errorMessage === 'payment.amount.too.small') {
            validForm = false;
        }
        if (!additionalEmail.isValid || additionalEmail.isDirty) {
            validForm = false;
        }
        return validForm;
    }

    const isOverdueApplicationSelected = (dateToCompare) => {
        let now = !!dateToCompare ? new Date(dateToCompare).setHours(0,0,0,0) : (new Date()).setHours(0,0,0,0);
        let overdueApplication = shoppingCartApplications.find((app) => !!app.lastSubmitDate
            && selectedApplications.includes(app.filingNumber)
            && now > new Date(app.lastSubmitDate).setHours(0,0,0,0));
        return !!overdueApplication;
    };

    const summaryShoppingCartsTable =
        !!Object.keys(summaryShoppingCartList).length && !loading ? (
            <ApplicationsTableClientSide
                setSorting={setSorting}
                sortBy={sortProps.sortColumn}
                sortType={sortProps.sortType}
                applications={summaryShoppingCartList}
                tableId={'applicationsForPaymentTable'}
                size={selectedApplications.length}
                page={0}
                noAvailableDataLabel={t('no.signatures')}
            />
        ) : (
            <Box display='flex' alignItems='center' justifyContent='center'>
                <CircularProgress/>
            </Box>
        );

    const handleCloseDialog = () => {
        setDialog({
            visibility: false,
            message: '',
            onConfirmation: () => {
            },
            confirmButtonText: '',
            cancelButtonText: '',
            onCancel: () => {
            },
        });
    };

    return (
        <React.Fragment>
            {/* title */}
            <div className={classes.title}>
                <div>
                    <PageTitle details={t('shopping.carts.summary')}/>
                </div>
            </div>
            <div className={classes.cardTable}>
                <Card canEdit={false}>
                    {summaryShoppingCartsTable}
                    <Grid container direction={'column'} className={classes.inputWrapper}>
                        <Grid item xs sm md className={classes.inputItem}>
                            <InputLabel
                                htmlFor={'paymentReference'}
                                error={!!paymentReference.errorMessage}
                                className={classes.fieldLabel}>
                                {t('payment.reference')}
                            </InputLabel>
                            <Input
                                id={'paymentReference'}
                                className={classes.textInput}
                                placeholder={t('payment.reference.description')}
                                type={'text'}
                                name={'paymentReference'}
                                onChange={handlePaymentReference}
                                value={paymentReference.value}
                                error={!!paymentReference.errorMessage}
                            />

                            {!!paymentReference.errorMessage && (
                                <ErrorInput
                                    errorMessage={t(paymentReference.errorMessage)}
                                    classes={`${classes.inputError}`}
                                />
                            )}
                        </Grid>
                        <Grid item xs sm md className={classes.inputItem}>
                            <InputLabel
                                required={true}
                                htmlFor={'contained-button-file'}
                                error={files.isDirty}
                                className={classes.fieldLabel}>
                                {t('payment.file')}
                            </InputLabel>
                            <input
                                accept={ACCEPTED_FORMATS}
                                className={classes.hiddenInput}
                                id={'contained-button-file'}
                                multiple
                                type={'file'}
                                onChange={handleFileUpload}
                                disabled={readOnly}
                            />
                            <label htmlFor={'contained-button-file'}>
                                <Button variant={'outlined'} startIcon={<AddIcon/>} component={'span'}
                                        className={classes.addBtn} disabled={readOnly}>
                                    {t('add.file')}
                                </Button>
                            </label>
                            <div>
                                <table>
                                    <tbody>
                                    {files.files.map((file, index) => (
                                        <TableRow key={index}>
                                            <TableCell><span>{file.name}</span></TableCell>
                                            <TableCell><Button
                                                onClick={() => removeFile(index)}>{t('remove')}</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            {files.isDirty && (
                                <ErrorInput errorMessage={t('required.field')} classes={`${classes.inputError}`}/>
                            )}
                        </Grid>
                        <Grid item xs sm md className={classes.inputItem}>
                            <InputLabel
                                required={true}
                                htmlFor={'paymentDate'}
                                error={paymentDate.isDirty}
                                className={classes.fieldLabel}>
                                {t('payment.date')}
                            </InputLabel>
                            <DatePicker
                                selected={paymentDate.value}
                                onChange={handlePaymentDate}
                                locale={lt}
                                dateFormat='P'
                                className={classes.datePickerWrapper}
                            />
                            {paymentDate.isDirty && (
                                <ErrorInput
                                    errorMessage={t('payment.date.error', {maxChars: 200})}
                                    classes={`${classes.inputError}`}
                                />
                            )}
                        </Grid>
                        <Grid item xs sm md className={classes.inputItem}>
                            <InputLabel
                                required={true}
                                htmlFor={'purpose'}
                                error={!!purpose.errorMessage}
                                className={classes.fieldLabel}>
                                {t('payment.purpose')}
                            </InputLabel>
                            <Input
                                id={'purpose'}
                                className={classes.textInput}
                                placeholder={t('payment.purpose')}
                                type={'text'}
                                name={'purpose'}
                                onChange={handlePurpose}
                                value={purpose.value}
                                error={!!purpose.errorMessage}
                            />

                            {!!purpose.errorMessage && (
                                <ErrorInput
                                    errorMessage={t(purpose.errorMessage)}
                                    classes={`${classes.inputError}`}
                                />
                            )}
                        </Grid>
                        <Grid item xs sm md className={classes.inputItem}>
                            <InputLabel
                                required={true}
                                htmlFor={'payer'}
                                error={!!payer.errorMessage}
                                className={classes.fieldLabel}>
                                {t('payment.payer')}
                            </InputLabel>
                            <Input
                                id={'payer'}
                                className={classes.textInput}
                                placeholder={t('payment.payer')}
                                type={'text'}
                                name={'payer'}
                                onChange={handlePayer}
                                value={payer.value}
                                error={!!payer.errorMessage}
                            />
                            {!!payer.errorMessage && (
                                <ErrorInput
                                    errorMessage={t(payer.errorMessage, {maxChars: 200})}
                                    classes={`${classes.inputError}`}
                                />
                            )}
                        </Grid>
                        <Grid item xs sm md className={classes.inputItem}>
                            <InputLabel
                                required={true}
                                htmlFor={'amount'}
                                error={!!amount.errorMessage}
                                className={classes.fieldLabel}>
                                {t('payment.amount')}
                            </InputLabel>
                            <Input
                                id={'amount'}
                                className={classes.textInput}
                                placeholder={t('payment.amount.placeholder')}
                                type={'number'}
                                name={'amount'}
                                onChange={handleAmount}
                                value={amount.isNumber ? (amount.value / 100) : ''}
                                error={!!amount.errorMessage}
                            />
                            {!!amount.errorMessage && (
                                <ErrorInput
                                    errorMessage={t(amount.errorMessage)}
                                    classes={`${classes.inputError}`}
                                />
                            )}
                        </Grid>
                        <Grid item xs sm md className={classes.inputItem}>
                            <InputLabel
                                htmlFor={'additionalEmail'}
                                error={additionalEmail.isDirty || !additionalEmail.isValid}
                                className={classes.fieldLabel}>
                                {t('payment.additional.email')}
                            </InputLabel>
                            <Input
                                id={'additionalEmail'}
                                className={classes.textInput}
                                placeholder={t('payment.additional.email.description')}
                                type={'email'}
                                name={'additionalEmail'}
                                onChange={handleAdditionalEmail}
                                value={additionalEmail.value}
                                error={additionalEmail.isDirty || !additionalEmail.isValid}
                            />
                            {additionalEmail.isDirty || !additionalEmail.isValid && (
                                <Typography variant={'body2'} color={'error'} className={classes.inputError}>
                                    {t('invalid.email.format')}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <div className={classes.tableActionsSummary}>
                        <span>{t('applications.selected', {num: selectedApplications.length})}</span>
                        {!!selectedApplications.length && <span
                            className={classes.totalCost}>{`${t('total.cost')}: ${(totalCost / 100).toFixed(2)} €`}</span>}
                    </div>
                    <div className={classes.tableActionsSummary}>
                        <span>
                            <Button id={'cancel-btn'} variant={'outlined'} onClick={onCancelConfirm}>
                                {t('cancel')}
                            </Button>
                        </span>
                        <span>
                            <Button id={'pay-btn'} variant={'contained'}
                                    disabled={!selectedApplications.length || readOnly || executing}
                                    onClick={handleConfirm}>
                                {t('submit')}
                            </Button>
                        </span>
                    </div>
                </Card>
            </div>
            <WarningModal
                visibility={dialog.visibility}
                message={dialog.message}
                onConfirmation={dialog.onConfirmation}
                confirmButtonText={dialog.confirmButtonText}
                cancelButtonText={dialog.cancelButtonText}
                onCancel={dialog.onCancel}
            />
        </React.Fragment>

    );
};

export default PaidInAdvanceTable;
