import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const ApplicationsTableClientSideHeader = ({ sortBy, sortType, setSorting, headers, tableId }) => {
    const sortHeader = (event, sortBy) => {
        event.preventDefault();
        setSorting(sortBy);
    };

    return (
        <TableHead>
            <TableRow>
                {headers.map((item, idx) => (
                    <TableCell id={`${tableId}-col-${item.code}`} key={idx} align={item.align}>
                        {item.sortable ? (
                            <TableSortLabel
                                id={`${tableId}-col-${item.code}-value`}
                                active={sortBy === item.code}
                                direction={sortType.toLowerCase()}
                                onClick={(event) => sortHeader(event, item.code)}>
                                {item.label}
                            </TableSortLabel>
                        ) : (
                            <span id={`${tableId}-col-${item.code}-value`}>{item.label}</span>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default ApplicationsTableClientSideHeader;
