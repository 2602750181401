import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

import { globals as globalsSlice } from '../../app/globalsSlice';
import { ReactComponent as EUIPNLogo } from '../../assets/images/euipn_logo.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/images/linkedIn_icon.svg';
import { t } from '../../providers/TranslationProvider';
import ExternalLinks from '../ExternalLinks';
import FeedbackFormModal from '../FeedbackFormModal';

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: theme.palette.action.dimGrey,
        minHeight: '325px',
    },
    footerLinkLg: {
        color: '#333333',
        fontSize: '14px',
        fontWeight: 'normal',
        textDecorationLine: 'underline',
        paddingBottom: '5px',
        cursor: 'pointer',
    },
    footerInner: {
        padding: '30px 20px 30px 60px', //'30px 60px',
        color: '#ffffff',
        fontSize: '12px',
        width: '100%',
        margin: 'unset',
    },
    info: {
        color: '#ffffff',
    },
    infoItem: {
        lineHeight: '20px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        letterSpacing: 'normal',
        color: '#333333',
    },
    footerversion: {
        float: 'right',
        fontSize: '12px',
        opacity: '.5',
    },
    informationLinksCol: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    section: {
        marginTop: '14px',
    },
    sectionHalf: {
        height: '60%',
    },
    logo: {
        '& svg': {
            maxHeight: '83px',
        },
    },
    social: {
        display: 'flex',
        alignItems: 'center',
    },
    socialBtn: {
        paddingRight: '20px',
    },
    appVersionSection: {
        height: '100%',
        padding: '30px 60px 30px 0',
    },
    appVersion: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0px !important',
    },
}));

const useStylesFooterTitle = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.titleFontWeight,
        fontSize: theme.typography.titleFontSize,
        borderBottom: '3px solid' + theme.palette.text.outlined,
        paddingBottom: '15px',
    },
}));

const FooterTitle = ({ title }) => {
    const classes = useStylesFooterTitle();
    return <Typography className={classes.title}>{title}</Typography>;
};

const Footer = () => {
    const classes = useStyles();
    const { globals } = useSelector(globalsSlice) || {};

    const footerExternalLinks = globals && globals.footer && Object.values(globals.footer);

    const [feedbackFormDisplay, setFeedbackFormDisplay] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault();
        setFeedbackFormDisplay(false);
    };

    const onCancel = (event) => {
        event.preventDefault();
        setFeedbackFormDisplay(false);
    };

    return (
        <div className={classes.footer}>
            <Grid container>
                <Grid item xs={10}>
                    <Grid container justify="center" spacing={5} className={classes.footerInner}>
                        <Grid item xs={4}>
                            <FooterTitle title={t('about.vpb')} />
                            <Typography className={`${classes.informationLinksCol} ${classes.section}`}>
                                <ExternalLinks links={footerExternalLinks} className={classes.footerLinkLg} />
                            </Typography>
                            <Typography className={classes.footerLinkLg} onClick={() => setFeedbackFormDisplay(true)}>
                                {t('footer.user-feedback')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} color={'secondary'}>
                            <div className={classes.sectionHalf}>
                                <FooterTitle title={t('contact.information.title')} />
                                <div className={`${classes.info} ${classes.section}`}>
                                    <Typography variant={'subtitle2'} className={classes.infoItem} component={'div'}>
                                        {t('footer.address')}
                                    </Typography>
                                    <Typography variant={'subtitle2'} className={classes.infoItem} component={'div'}>
                                        {t('information.centre.phone')}: +370 5 278 0290
                                    </Typography>
                                    <Typography variant={'subtitle2'} className={classes.infoItem} component={'div'}>
                                        {t('information.centre.email')}: info@vpb.gov.lt
                                    </Typography>
                                    <Typography variant={'subtitle2'} className={classes.infoItem} component={'div'}>
                                        {t('information.centre.feedback')}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.section}>
                                <FooterTitle title={t('legal.information')} style={{ marginTop: '14px' }} />
                                <div className={`${classes.info} ${classes.section}`}>
                                    <Typography variant={'subtitle2'} className={classes.footerLinkLg} component={'div'}>
                                        <a href={`https://vpb.lrv.lt/asmens-duomenu-apsauga`}
                                            className={`${classes.informationLinksCol} ${classes.section}`} target={'_blank'}>
                                            {t('legal.info')}
                                        </a>
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} color="secondary">
                            <div className={classes.sectionHalf}>
                                <FooterTitle title={t('find.us.here')} />
                                <Typography className={`${classes.social} ${classes.section}`}>
                                    <a href="https://www.linkedin.com/company/27102388" className={classes.socialBtn} target={'_blank'}>
                                        <LinkedInIcon />
                                    </a>
                                </Typography>
                                <Typography variant={'subtitle2'} className={classes.infoItem} component={'div'}>
                                    {t('footer.euipo-cooperation')}
                                </Typography>
                                <EUIPNLogo />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Grid container spacing={5} alignItems={'flex-end'} className={[classes.footerInner, classes.appVersionSection].join(' ')}>
                        <Grid item xs={12} className={classes.appVersion}>
                            <span id="footer_app_version">
                                {globals && globals.ipo && globals.ipo.version && `${t('version')}: ${globals.ipo.version}`}
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
              {/* feedback form window */}
              {feedbackFormDisplay && <FeedbackFormModal onSubmit={onSubmit} onCancel={onCancel}/>}
        </div>
    );
};

export default Footer;
