import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    textArea: {
        width: '100%',
        border: '1px solid #B0BEC5'
    }
}));

const TextArea = ({ rows, name, value, onChange }) => {
    const classes = useStyles();

    return (
        <textarea rows={rows} className={classes.textArea} name={name} onChange={onChange} value={value} />
    );
};

export default TextArea;
