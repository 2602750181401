import React from 'react';

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';

import { t } from '../../providers';

const useStyles = makeStyles((theme) => ({
    paginationSection: {
        marginTop: '20px',
    },
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '@media (max-width:960px)': {
            flexDirection: 'column',
        },
    },
    pageSizeSelector: {
        marginLeft: '11px',
        width: 'auto',
        border: 'none',
        borderRadius: '4px',
        '& .MuiSelect-root': {
            paddingRight: '40px',
        },
        '& .MuiSelect-icon': {
            top: 'unset',
        },
        '@media (max-width:960px)': {
            paddingBottom: '10px',
        },
    },
    textPagination: {
        fontSize: '14px',
        lineHeight: '20px',
        color: theme.palette.text.darkGrey,
        padding: '0 15px',
        '@media (max-width:960px)': {
            padding: '0 15px 5px',
        },
    },
    messageSection: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.detailsGrey,
        fontSize: theme.typography.fontSize,
        '@media (max-width:960px)': {
            justifyContent: 'center',
            paddingBottom: '10px',
        },
    },
}));

const CustomPagination = ({ defaultPageSize, totalElements, size, page, onChangePage, onChangeRowsPerPage, paginationId, message = '' }) => {
    /**
     * pages options generated based on totalElements and default page size
     * Example:
     * Default value is 10.
     * Possibile options:  increment of 10 based on total number of items
     */

    const classes = useStyles();

    const calcTotalPages = (size) => (totalElements % size > 0 ? Math.floor(totalElements / size) + 1 : Math.floor(totalElements / size));

    const totalPages = calcTotalPages(size);
    const totalPagesOnDefaultSize = calcTotalPages(defaultPageSize);

    let rowsPerPageOptions = [];
    for (let i = 1; i <= totalPagesOnDefaultSize; i++) {
        let pageSizeOption = i * defaultPageSize;
        rowsPerPageOptions.push(pageSizeOption);
    }

    let currentPage = page + 1;

    const changePage = (event, newPage) => {
        onChangePage(event, newPage - 1);
    };

    const changePageSize = (value) => {
        onChangeRowsPerPage(value);
    };
    const fromNumber = (page + 1) * size - size + 1;
    const toNumber = (page + 1) * size > totalElements ? totalElements : (page + 1) * size;

    return (
        <div className={classes.paginationSection} id={paginationId}>
            <Grid container>
                {message && (
                    <Grid item xs={12} md={4} lg={6} className={classes.messageSection}>
                        <span>{message}</span>
                    </Grid>
                )}
                <Grid item xs={12} md={message ? 8 : 12} lg={message ? 6 : 12}>
                    <div className={classes.pagination}>
                        {!!rowsPerPageOptions.length && (
                            <Select
                                value={size}
                                className={classes.pageSizeSelector}
                                aria-label={t('results_per_page')}
                                onChange={changePageSize}
                                renderValue={(size) => `${size} ${t('items')}`}>
                                {rowsPerPageOptions.map((pageSize) => (
                                    <MenuItem key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        {!!totalElements && (
                            <Typography className={classes.textPagination} aria-label={`${fromNumber} - ${toNumber} from ${totalElements} items`}>
                                <span>{t('pagination.showing.entries', { from: fromNumber, to: toNumber, total: totalElements })}</span>
                            </Typography>
                        )}
                        {!!totalPages && (
                            <Pagination
                                className={classes.navigation}
                                shape={'rounded'}
                                count={totalPages}
                                page={currentPage}
                                siblingCount={1}
                                onChange={changePage}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default CustomPagination;
