import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import { downloadFile } from '../../common/utils/service';
import { SLICE_NAME as DETAILS_SLICE_NAME } from '../CorrespondenceDetails/constants';
import { SLICE_NAME } from './constants';
import { listParameters as listParametersSlice } from './correspondenceSlice';
import {
    fetchCorrespondence,
    fetchNewCorrespondencesCount,
    fetchExpiringCorrespondences,
    deleteDraft,
    requestDownloadOutgoingDraft,
    updateMessageStatus as _updateMessageStatus,
    updateMessageImportance as _updateMessageImportance 
} from './service';
import { parseRequestData } from './utils';

function* requestCorrespondence() {
    const persistentFilters = yield select(listParametersSlice);
    const parsedParams = parseRequestData(persistentFilters);
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(fetchCorrespondence, {
        params: {
            ...parsedParams,
        },
    });
    if (!response.error) {
        const { content, pageNumber } = response;
        if (!content.length && pageNumber >= 1) {
            yield put({
                type: `${SLICE_NAME}/listParameters`,
                payload: { ...persistentFilters, requestPage: pageNumber - 1 },
            });
        } else {
            yield put({
                type: `${SLICE_NAME}/list`,
                payload: response,
            });
        }
        yield put({
            type: `${DETAILS_SLICE_NAME}/details`,
            payload: null,
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* getCorrespondence() {
    yield takeLatest(`${SLICE_NAME}/requestCorrespondence`, requestCorrespondence);
}

function* updateCorrParameters(action) {
    yield put({ type: `${SLICE_NAME}/listParameters`, payload: action.payload });
}

function* updateParametersCorr() {
    yield takeLatest(`${SLICE_NAME}/updateCorrParameters`, updateCorrParameters);
}

function* requestNewCorrespondencesCount() {
    yield all([put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(fetchNewCorrespondencesCount);
    if (!response.error) {
        yield put({
            type: `${SLICE_NAME}/newMessages`,
            payload: response,
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
}

function* getNewCorrespondencesCount() {
    yield takeLatest(`${SLICE_NAME}/requestNewCorrespondencesCount`, requestNewCorrespondencesCount);
}

function* requestExpiringCorrespondences(action) {
    yield all([put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(fetchExpiringCorrespondences, {
        ...action.payload,
    });
    if (!response.error) {
        yield put({
            type: `${SLICE_NAME}/expiringMessages`,
            payload: response || 0,
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
}

function* getExpiringCorrespondences() {
    yield takeLatest(`${SLICE_NAME}/requestExpiringCorrespondences`, requestExpiringCorrespondences);
}

function* requestDraftCorrespondencePageDelete(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(deleteDraft, {
        ...action.payload,
    });
    if (!response.error) {
        yield call(requestCorrespondence, { payload: {} });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.code === 403 ? response.error : response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* deleteDraftCorrespondencePage() {
    yield takeLatest(`${SLICE_NAME}/requestDraftCorrespondencePageDelete`, requestDraftCorrespondencePageDelete);
}
function* requestOutgoingCorrespondenceDownload(action) {
    yield all([put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(requestDownloadOutgoingDraft, {
        ...action.payload,
    });
    if (!response.error) {
        downloadFile(response);
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
}

function* downloadOutgoingDraft() {
    yield takeLatest(`${SLICE_NAME}/downloadOutgoingDraft`, requestOutgoingCorrespondenceDownload);
}

function* updateMessageImportance(action) {
    yield put({ type: `${SLICE_NAME}/loading`, payload: true });
    const response = yield call(_updateMessageImportance, {
        ...action.payload,
    });
    if (!response.error) {
        console.log('Message updated: ', response);
        yield put({
            type: `${SLICE_NAME}/messageUpdated`,
            payload: response,
        });
        yield call(requestCorrespondence, {});
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* updateMessageStatus(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true })]);
    const response = yield call(_updateMessageStatus, {
        ...action.payload,
    });
    if (!response.error) {
        console.log('Message updated: ', response);
        yield call(requestCorrespondence, {});
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
}

function* updateMessageImportanceSaga() {
    yield takeLatest(`${SLICE_NAME}/updateMessageImportance`, updateMessageImportance);
}

function* updateMessageStatusSaga() {
    yield takeLatest(`${SLICE_NAME}/updateMessageStatus`, updateMessageStatus);
}

export {
    getCorrespondence,
    updateParametersCorr,
    getNewCorrespondencesCount,
    getExpiringCorrespondences,
    deleteDraftCorrespondencePage,
    downloadOutgoingDraft,
    updateMessageImportanceSaga,
    updateMessageStatusSaga,
};
