import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { globals as globalsSlice } from '../../app/globalsSlice';
import { userCan } from '../../common/utils/service';
import ApplicationsGrid from '../../components/ApplicationsGrid';
import ApplicationsTable from '../../components/ApplicationsTable';
import Card from '../../components/Card';
import SwitchButton from '../../components/SwitchButton';
import ApplicationFilters from '../../components/Filters/ApplicationFilters';
import Note from '../../components/Note';
import PageTitle from '../../components/PageTitle';
import CustomPagination from '../../components/Pagination/CustomPagination';
import WarningModal from '../../components/WarningModal';
import { t } from '../../providers';
import { me as meSlice } from '../AuthenticatedPage/authenticationSlice';
import { SLICE_NAME as CORRESPONDENCE_SLICE_NAME } from '../CorrespondencePage/constants';
import { listParameters } from '../CorrespondencePage/correspondenceSlice';
import InitiateEservice from './components/InitiateEservice';
import { SLICE_NAME } from './constants';
import filterItems from './filterItems';
import {
    list,
    loading as loadingSlice,
    error as errorSlice,
    noteLocked,
    loadingNoteLock as loadingNoteLockSLice,
    filters as filtersOptionsSlice,
} from './finalizedApplicationsSlice';
import { applicationsParserGrid, applicationsParserList, filtersParser, myApplicationsExportColumns } from './utils';

const useStyles = makeStyles((_theme) => ({
    container: {
        width: '100%',
        height: '100%',
        maxWidth: 'calc(100% - 236px)',
        '@media (max-width:1100px)': {
            minWidth: '77%',
            maxWidth: 'unset',
        },
    },
    titleAndSwitch: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width:1100px)': {
            display: 'block',
        },
    },
    switch: {
        display: 'flex',
        '@media (max-width:1100px)': {
            float: 'right',
        },
    },
    switchGrid: {
        '&.disabled': {
            cursor: 'not-allowed',
            '& .MuiButtonBase-root': {
                cursor: 'not-allowed',
            },
        },
        '& .MuiFormControlLabel-label': {
            color: '#272833',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '29px',
            paddingLeft: '8px',
        },
    },
    tabs: {
        '& .MuiTab-root': {
            minWidth: '100px',
        },
    },
    tabContainer: {
        width: '100%',
    },
    filtersGrid: {
        marginTop: '30px',
        marginRight: '20px',
    },
    cardTable: {
        marginRight: '20px',
        '@media (max-width:960px)': {
            marginRight: 'unset',
        },
    },
}));

const ALL_IP_RIGHTS = 'all';
const TRADEMARKS = 'trademark';
const DESIGNS = 'design';
const ESERVICES = 'eservice';
const ASC = 'ASC';
const DESC = 'DESC';
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_SORT_COLUMN = 'applicationDate';
const DEFAULT_SORT_TYPE = DESC;

const MyApplicationsPage = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useSelector(loadingSlice);
    const error = useSelector(errorSlice);
    const applications = useSelector(list);
    const lockedNote = useSelector(noteLocked);
    const loadingNoteLock = useSelector(loadingNoteLockSLice);
    const corrParameters = useSelector(listParameters);
    const applicationFiltersOptions = useSelector(filtersOptionsSlice);
    const me = useSelector(meSlice);
    const { globals, filtersOptions } = useSelector(globalsSlice);
    const locationState = props.location.state;

    const { totalResults, pageSize: size, pageNumber, numberOfElements } = applications || {};
    const { application = {} } = globals || {};
    const { impersonatedUser: readOnly } = me || {};

    const [userType, setUserType] = useState('');
    const [sortProps, setSortProps] = useState({ sortColumn: DEFAULT_SORT_COLUMN, sortType: DEFAULT_SORT_TYPE });
    const [gridDisplay, setGridDisplay] = useState(false);
    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0); //index of tabs
    const [applicationsGrid, setApplicationsGrid] = useState([]);
    const [applicationsList, setApplicationsList] = useState([]);
    const [filtersValues, setFiltersValues] = useState({
        searchQuery: locationState && locationState.appNumber ? props.location.state.appNumber : '',
        filters: {},
        filtersChips: {},
    });
    const [requestBodyData, setRequestBodyData] = useState({
        paginationData: {
            page: DEFAULT_PAGE_NUMBER,
            size: DEFAULT_PAGE_SIZE,
        },
        sortingData: {
            sortColumn: sortProps.sortColumn,
            sortType: sortProps.sortType,
        },
        filteringData: filtersParser(filtersValues.filters),
        isDraft: false,
    });
    const [dialog, setDialog] = useState({
        title: '',
        visibility: false,
        message: '',
        onConfirmation: null,
        confirmButtonText: null,
        onCancel: null,
        content: null,
    });
    const [openNote, setOpenNote] = useState(false);
    const [editModeNote, setEditModeNote] = useState(false);
    const [dataNote, setDataNote] = useState({});
    const [selectedApplications, setSelectedApplications] = useState([]);
    const [initiateServicesOptions, setInitiateServicesOptions] = useState([]);
    const eservicesOptions = filtersOptions[ESERVICES];
    const [notValidApplicationsIds, setNotValidApplicationsIds] = useState([]);
    const [notValidApplicationsNumbers, setNotValidApplicationsNumbers] = useState([]);
    const [initiateEserviceFromOption, setInitiateEserviceFromOption] = useState(false);

    const getApplicationReceipt = (appNumber, ipRightType) => {
        dispatch({ type: `${SLICE_NAME}/requestApplicationReceipt`, payload: { appNumber, ipRightType, applicationType: tabs[selectedTab] } });
    };

    const getPaymentInvoice = (filingNumber) => {
        const params = { filingNumber };
        dispatch({ type: `${SLICE_NAME}/getApplicationPaymentInvoice`, payload: { params } });
    };

    const checkCorrespondence = (appNumber) => {
        dispatch({
            type: `${CORRESPONDENCE_SLICE_NAME}/listParameters`,
            payload: {
                ...corrParameters,
                requestPage: 0,
                searchQuery: appNumber,
                selectedTab: ALL_IP_RIGHTS,
            },
        });
        history.push('/correspondence');
    };

    const handleInitiateEservice = (appId) => {
        setNotValidApplicationsIds([]);
        setNotValidApplicationsNumbers([]);
        setSelectedApplications([appId]);
        setInitiateEserviceFromOption(true);
    };

    const duplicateApplication = (appId) => {
        dispatch({ type: `${SLICE_NAME}/requestDuplicateApplication`, payload: { appId, applicationType: tabs[selectedTab] } });
    };

    const handleCloseDialog = () => {
        setDialog({ title: '', visibility: false, message: '', onConfirmation: null, confirmButtonText: null, onCancel: null, content: null });
        setInitiateEserviceFromOption(false);
        dispatch({ type: `${SLICE_NAME}/error`, payload: false });
    };

    const updateNote = (parameters) => {
        const params = {
            requestBody: requestBodyData,
            selectedTab: tabs[selectedTab],
        };
        dispatch({
            type: `${SLICE_NAME}/requestNoteUpdate`,
            payload: {
                params: parameters,
                actionParams: {
                    type: `${SLICE_NAME}/requestMyApplications`,
                    payload: { params },
                },
            },
        });
        setDataNote({});
    };

    const editNote = (parameters) => {
        dispatch({
            type: `${SLICE_NAME}/lockNote`,
            payload: {
                params: parameters,
            },
        });
    };

    const clearLockNote = () => {
        dispatch({ type: `${SLICE_NAME}/noteLocked`, payload: null });
    };

    const handleNoteModal = (note, appNum, ipRightType) => {
        if (!note && !readOnly) setEditModeNote(true);
        setDataNote({ note, appNum, ipRightType, applicationType: tabs[selectedTab] });
        setOpenNote(!(!note && readOnly));
    };

    const handleDisplayChange = () => {
        console.log('tabs[selectedTab]', tabs[selectedTab]);
        if (tabs[selectedTab] === ALL_IP_RIGHTS || tabs[selectedTab] === ESERVICES) {
            setGridDisplay(false); //only list available for e-services
        } else setGridDisplay(!gridDisplay);
    };

    const handleSelectAllApplications = (event) => {
        const { content } = applications || [];
        if (event.target.checked) {
            const newSelecteds = content.map((app) => app.id);
            setSelectedApplications(newSelecteds);
            return;
        }
        setSelectedApplications([]);
    };

    const handleSelectApplication = (_event, num) => {
        const selectedIndex = selectedApplications.indexOf(num);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedApplications, num);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedApplications.slice(1));
        } else if (selectedIndex === selectedApplications.length - 1) {
            newSelected = newSelected.concat(selectedApplications.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selectedApplications.slice(0, selectedIndex), selectedApplications.slice(selectedIndex + 1));
        }

        setSelectedApplications(newSelected);
    };

    const setSorting = (sortColumn) => {
        const isSameKey = sortColumn === sortProps.sortColumn;
        const oppositeSort = sortProps.sortType === ASC ? DESC : ASC;
        const sortType = !isSameKey ? ASC : oppositeSort;
        setSortProps({ sortColumn, sortType });
        setRequestBodyData({
            ...requestBodyData,
            paginationData: {
                page: DEFAULT_PAGE_NUMBER,
                size: size,
            },
            sortingData: {
                sortColumn: sortColumn,
                sortType: sortType,
            },
        });
        setSelectedApplications([]);
        setNotValidApplicationsIds([]);
        setNotValidApplicationsNumbers([]);
    };

    const handleChangePage = (event, newPage) => {
        event.preventDefault();
        if (newPage !== pageNumber) {
            setRequestBodyData({
                ...requestBodyData,
                paginationData: {
                    page: newPage,
                    size: size,
                },
            });
            setSelectedApplications([]);
            setNotValidApplicationsIds([]);
            setNotValidApplicationsNumbers([]);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        const pageSize = event.target.value;
        if (size !== pageSize) {
            setRequestBodyData({
                ...requestBodyData,
                paginationData: {
                    page: 0,
                    size: pageSize,
                },
            });
        }
    };

    const clearFilters = () => {
        setSelectedApplications([]);
        setNotValidApplicationsIds([]);
        setNotValidApplicationsNumbers([]);
        setFiltersValues({ ...filtersValues, filters: {}, filtersChips: {} });
    };

    const handleTabChange = (event, value) => {
        event.preventDefault();
        if (tabs[value] === ALL_IP_RIGHTS || tabs[value] === ESERVICES) setGridDisplay(false); //only list available for e-services
        setFiltersValues({ searchQuery: '', filters: {} });
        setSelectedApplications([]);
        setSortProps({ sortColumn: DEFAULT_SORT_COLUMN, sortType: DEFAULT_SORT_TYPE });
        setRequestBodyData({
            paginationData: {
                page: DEFAULT_PAGE_NUMBER,
                size: DEFAULT_PAGE_SIZE,
            },
            sortingData: {
                sortColumn: DEFAULT_SORT_COLUMN,
                sortType: DEFAULT_SORT_TYPE,
            },
            filteringData: {},
            isDraft: false,
        });
        setSelectedTab(value);
        setInitiateServicesOptions([]);
        setNotValidApplicationsIds([]);
        setNotValidApplicationsNumbers([]);
    };

    const exportApplications = (type) => {
        const exportedColumns = myApplicationsExportColumns(tabs[selectedTab]);
        let params = {
            requestBody: {
                ...requestBodyData,
                columns: type === 'excel' ? exportedColumns : [],
                isDraft: false,
            },
            applicationType: tabs[selectedTab],
            type: type ? type : 'excel',
        };
        if (!params.requestBody.columns.length) delete params.requestBody.columns; // remove columns attribute if empty
        dispatch({ type: `${SLICE_NAME}/requestMyApplicationsExport`, payload: { params } });
    };

    const initiateServices = null;
    /** temporary hidden
    const initiateServices = (serviceName) => {
        setNotValidApplicationsIds([]);
        setNotValidApplicationsNumbers([]);
        dispatch({
            type: `${SLICE_NAME}/requestEserviceValidation`,
            payload: { eserviceName: serviceName, applicationIds: selectedApplications, applicationType: tabs[selectedTab] },
        });
    }; */

    const initiateEservice = (serviceName) => {
        handleCloseDialog();
        initiateServices(serviceName);
    };

    const hasCorrespondenceRole = () => {
        const { authorities } = me;
        return userCan(authorities, 'ROLE_CORRESPONDENCE_READ_WRITE') || userCan(authorities, 'ROLE_CORRESPONDENCE_READ_ONLY');
    };

    useEffect(() => {
        const { authorities, personType } = me;
        setUserType(personType);
        const allowedTabs = [];
        if (userCan(authorities, 'ROLE_TRADEMARKS') || userCan(authorities, 'ROLE_DESIGNS')) allowedTabs.push(ALL_IP_RIGHTS);
        if (userCan(authorities, 'ROLE_TRADEMARKS')) allowedTabs.push(TRADEMARKS);
        if (userCan(authorities, 'ROLE_DESIGNS')) allowedTabs.push(DESIGNS);

        if (allowedTabs.length > 0) {
            allowedTabs.push(ESERVICES);
            setTabs(allowedTabs); //set tabs depending on tabs allowed based on authorities role
            if (locationState && locationState.type) setSelectedTab(allowedTabs.indexOf(props.location.state.type));
        } else {
            history.push('/');
        }
    }, [me, history]);

    useEffect(() => {
        if (tabs.length > 0 && !loading) {
            const params = {
                requestBody: requestBodyData,
                selectedTab: tabs[selectedTab],
            };
            if (!applicationFiltersOptions) {
                dispatch({ type: `${SLICE_NAME}/getApplicationsFilters`, payload: {} });
            }
            dispatch({ type: `${SLICE_NAME}/requestMyApplications`, payload: { params } });

            if (tabs[selectedTab] === TRADEMARKS) {
                setInitiateServicesOptions(eservicesOptions.tmInitiatingTypes || []);
            } else if (tabs[selectedTab] === DESIGNS) {
                setInitiateServicesOptions(eservicesOptions.dsInitiatingTypes || []);
            }
            setSelectedApplications([]);
            setNotValidApplicationsIds([]);
            setNotValidApplicationsNumbers([]);
        }
    }, [dispatch, tabs, requestBodyData]);

    useEffect(() => {
        if (filtersValues.searchQuery) {
            setRequestBodyData({
                ...requestBodyData,
                paginationData: { ...requestBodyData.paginationData, page: 0 },
                filteringData: filtersParser(filtersValues.filters),
                searchingData: filtersValues.searchQuery,
            });
        } else {
            const bodyData = { ...requestBodyData };
            delete bodyData['searchingData'];
            setRequestBodyData({
                ...bodyData,
                paginationData: { ...bodyData.paginationData, page: 0 },
                filteringData: filtersParser(filtersValues.filters),
            });
        }
    }, [filtersValues]);

    useEffect(() => {
        if (tabs.length > 0) {
            if (gridDisplay) {
                const appsGrid = applicationsParserGrid(
                    applications || [],
                    tabs[selectedTab],
                    getApplicationReceipt,
                    checkCorrespondence,
                    getPaymentInvoice,
                    handleNoteModal,
                    duplicateApplication,
                    handleSelectApplication,
                    readOnly,
                    false,
                    selectedApplications,
                    handleInitiateEservice,
                    hasCorrespondenceRole()
                );
                setApplicationsGrid(appsGrid);
            } else {
                const appsList = applicationsParserList(
                    applications || [],
                    tabs[selectedTab],
                    userType,
                    getApplicationReceipt,
                    checkCorrespondence,
                    getPaymentInvoice,
                    handleNoteModal,
                    duplicateApplication,
                    readOnly,
                    handleSelectAllApplications,
                    handleSelectApplication,
                    numberOfElements,
                    true,
                    false,
                    selectedApplications,
                    handleInitiateEservice,
                    hasCorrespondenceRole(),
                    filtersOptions
                );
                setApplicationsList(appsList);
            }
        }
    }, [applications, gridDisplay, selectedTab, tabs, userType, selectedApplications]);

    useEffect(() => {
        if (initiateEserviceFromOption && selectedApplications.length === 1) {
            setDialog({
                ...dialog,
                title: t('initiate.eService'),
                visibility: true,
                content: modalInitiateEservices,
                onCancel: handleCloseDialog,
            });
            setInitiateEserviceFromOption(false);
        }
    }, [selectedApplications]);

    useEffect(() => {
        if (!!error) {
            const { code, extraInfo } = error;
            let messageModal = '';
            if (code === 400 && !!extraInfo) {
                //initiate eService validation error
                messageModal = t(error.description);
                if (!extraInfo.multiplicityError && !!extraInfo.invalidApplicationIds.length) {
                    setNotValidApplicationsIds(error.extraInfo.invalidApplicationIds);
                    setNotValidApplicationsNumbers(error.extraInfo.invalidApplicationNumbers);
                }
            } else {
                setNotValidApplicationsIds([]);
                setNotValidApplicationsNumbers([]);
                messageModal =
                    error.code === 403 ? t(error.description, { username: error.details.username, message: error.details.message }) : t(error);
            }
            setDialog({
                ...dialog,
                visibility: true,
                message: messageModal,
                onConfirmation: handleCloseDialog,
                confirmButtonText: t('ok'),
            });
        }
    }, [error]);

    const applicationTable = !loading ? (
        <ApplicationsTable
            setSorting={setSorting}
            sortBy={sortProps.sortColumn}
            sortType={sortProps.sortType}
            applications={applicationsList}
            tableId={'myApplications'}
            menuItems={[]}
            selectedApplications={selectedApplications}
            notValidApplications={notValidApplicationsIds}
        />
    ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
        </Box>
    );

    const listingApplications = !!notValidApplicationsNumbers.length && (
        <ul>
            {notValidApplicationsNumbers.map((num, idx) => (
                <li key={idx}>{num}</li>
            ))}
        </ul>
    );

    const customPagination = (
        <CustomPagination
            defaultPageSize={DEFAULT_PAGE_SIZE}
            totalElements={totalResults}
            size={size || DEFAULT_PAGE_SIZE}
            page={pageNumber || DEFAULT_PAGE_NUMBER}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            paginationId={'myApplications'}
            message={process.env.REACT_APP_BO_DATASET_ENABLED === 'true' ? t('applications.disclaimer') : ''}
        />
    );

    const modalInitiateEservices = <InitiateEservice options={initiateServicesOptions} initiateAction={initiateEservice} />;

    return (
        <div className={classes.container}>
            {/* title and switch button */}
            <div className={classes.titleAndSwitch}>
                <div>
                    <PageTitle title={t('my.applications')} details={t('my.applications.description')} />
                </div>
                <div className={classes.switch}>
                    <FormControlLabel
                        id={'switchGrid'}
                        className={[classes.switchGrid, (tabs[selectedTab] === ALL_IP_RIGHTS || tabs[selectedTab] === ESERVICES) && 'disabled'].join(' ')}
                        control={<SwitchButton checked={gridDisplay} onChange={handleDisplayChange} />}
                        label={t('grid')}
                    />
                </div>
            </div>

            {/* tabs */}
            <Tabs className={classes.tabs} value={selectedTab} onChange={handleTabChange} textColor="primary">
                {tabs.map((tab, index) => (
                    <Tab id={`tab-${tab}-${index}`} key={`tab-${index}`} label={t(tab + '.tab.title')} />
                ))}
            </Tabs>

            {/* list or grid data display */}
            {tabs.length > 0 && gridDisplay ? (
                <React.Fragment>
                    <div className={classes.filtersGrid}>
                        <ApplicationFilters
                            applicationType={tabs[selectedTab]}
                            filtersValues={filtersValues}
                            setFiltersValues={setFiltersValues}
                            clearFilters={clearFilters}
                            exportApplications={exportApplications}
                            filterItems={filterItems}
                            datesOptions={'dates'}
                            initiateServices={initiateServices}
                            initiateServicesOptions={initiateServicesOptions}
                            selectedApplications={selectedApplications}
                            myApplicationsFilters={'myApplications'}
                            page={'myApplications'}
                        />
                    </div>
                    <ApplicationsGrid applications={applicationsGrid} gridId={'myApplications'} notValidApplications={notValidApplicationsIds} />
                    {customPagination}
                </React.Fragment>
            ) : (
                <div className={classes.cardTable}>
                    <Card canEdit={false}>
                        {tabs[selectedTab] !== ALL_IP_RIGHTS && (
                        <ApplicationFilters
                            applicationType={tabs[selectedTab]}
                            filtersValues={filtersValues}
                            setFiltersValues={setFiltersValues}
                            clearFilters={clearFilters}
                            exportApplications={exportApplications}
                            filterItems={filterItems}
                            datesOptions={'dates'}
                            initiateServices={initiateServices}
                            initiateServicesOptions={initiateServicesOptions}
                            selectedApplications={selectedApplications}
                            myApplicationsFilters={applicationFiltersOptions}
                            page={'myApplications'}
                        />)}
                        {applicationTable}
                        {customPagination}
                    </Card>
                </div>
            )}

            {/* error dialog */}
            {dialog.visibility && (
                <WarningModal
                    title={dialog.title}
                    visibility={dialog.visibility}
                    message={dialog.message}
                    content={dialog.content}
                    onConfirmation={dialog.onConfirmation}
                    confirmButtonText={dialog.confirmButtonText}
                    onCancel={dialog.onCancel}
                    extraContent={listingApplications}
                />
            )}
            {/* note dialog */}
            <Note
                data={dataNote}
                saveNote={updateNote}
                openNote={openNote}
                editNote={editNote}
                setOpenNote={setOpenNote}
                editModeNote={editModeNote}
                setEditModeNote={setEditModeNote}
                maxCharsAllowed={application['note.max-length']}
                readOnly={readOnly}
                lockedNote={lockedNote}
                clearLockNote={clearLockNote}
                loading={loadingNoteLock}
            />
        </div>
    );
};

export default MyApplicationsPage;
