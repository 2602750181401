import React, { useState, useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';

import { GLOBALS_SLICE_NAME } from '../../../../app/constants';
import { countries as countriesSlice, states as statesSlice, loading as loadingSlice } from '../../../../app/globalsSlice';
import inputsMaxChars from '../../../../app/inputsMaxChars';
import { ReactComponent as DropDownArrow } from '../../../../assets/images/unfold_more_24px.svg';
import Card from '../../../../components/Card';
import ErrorInput from '../../../../components/ErrorInput';
import { t } from '../../../../providers';
import {
    loadingUserDetails,
    accountLocked as accountLockedSlice,
    loadingAccountLockUserDetails as loadingAccountLockUserDetailsSlice,
} from '../../accountSlice';

const useStyles = makeStyles((theme) => ({
    fieldLabel: {
        color: theme.palette.text.label,
        fontSize: '10px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: 'normal',
    },
    fieldValue: {
        color: theme.palette.text.value,
        fontSize: '12px',
        letterSpacing: 'normal',
        marginBottom: '10px',
    },
    linkValue: {
        color: theme.palette.text.linkBlue,
        fontSize: '12px',
        letterSpacing: 'normal',
        marginBottom: '10px',
        textDecoration: 'underline',
    },
    textInput: {
        height: '2rem',
        width: '9rem',
        borderRadius: '2px',
        marginTop: '5px',
        marginBottom: '10px',
        '@media (max-width:600px)': {
            width: '9.5rem',
        },
    },
    selectWidth: {
        width: '12rem',
    },
    selectSmallWidth: {
        width: '11rem',
    },
    corrAddressTitle: {
        marginLeft: '-10px',
        marginBottom: '24px',
        marginTop: '20px',
    },
    inputError: {
        marginTop: '-5px',
        marginBottom: '5px',
    },
}));

// Individual
const individualFields = ['firstName', 'surName', 'naturalPersonCode'];

// Business / Company
const companyFields = ['firstName', 'surName', 'organizationName', 'legalEntityCode', 'legalPosition'];

const user2FieldsMapping = {
    'INDIVIDUAL': () => individualFields,
    'COMPANY': () => companyFields,
};

const getFieldsByUser = ({ accountTypeDetails = {} }) => {
    const { personType, accountType } = accountTypeDetails;
    const hasTypes = !!personType && !!accountType;
    return hasTypes ? user2FieldsMapping[accountType]() : [];
};

const UserDetailsCard = ({ initialValues, saveDetails, hasAdministratorRole, handleLockAccount, clearLockAccount }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const countries = useSelector(countriesSlice);
    const loading = useSelector(loadingUserDetails);
    const loadingCountries = useSelector(loadingSlice);
    const accountLocked = useSelector(accountLockedSlice);
    const loadingAccountLockUserDetails = useSelector(loadingAccountLockUserDetailsSlice);
    const states = useSelector(statesSlice);

    const userFields = getFieldsByUser(initialValues);

    const [readonly, setReadonly] = useState(true);

    const [userDetails, setUserDetails] = useState({});

    const [triggerInitialData, setTriggerInitialData] = useState(false); // trigger to get initial data

    const handleValueChange = (event) => {
        const value = event.target.value;
        const keyName = event.target.name;
        const field = { ...userDetails[keyName], value, isDirty: true };
        if (!field.maxChars || (field.maxChars && value.length <= field.maxChars)) {
            let errorMessage = null;
            if (field.required && !value) errorMessage = 'required.field';
            field.errorMessage = errorMessage;

            let newDetailsValue = { ...userDetails, [keyName]: field };
            setUserDetails(newDetailsValue);
        }
    };

    const editCard = () => {
        handleLockAccount('UserDetails');
    };

    const cancelCard = (event) => {
        event.preventDefault();
        setTriggerInitialData(!triggerInitialData);
        setReadonly(true);
    };

    const saveCard = (event) => {
        event.preventDefault();
        const newPersonDetails = Object.keys(userDetails).reduce((acc, cur) => ({ ...acc, [cur]: userDetails[cur].value }), {});
        const updatedDetails = Object.keys(userDetails).reduce(
            (acc, cur) => (userDetails[cur].isDirty ? { ...acc, [cur]: userDetails[cur].value } : { ...acc }),
            {}
        );

        // get the available userFields that can be iterrated
        const availableUserFields = Object.fromEntries(Object.entries(userDetails).filter(([field]) => userFields.includes(field)));

        //check for errors in the specific user Fields
        const hasErrors = Object.keys(availableUserFields).some(
            (field) => userDetails[field].errorMessage && userDetails[field].errorMessage !== null
        );

        let availableUserFieldsWithErrorsMessage = { ...availableUserFields };
        const missingRequiredValues = Object.fromEntries(
            Object.entries(availableUserFieldsWithErrorsMessage).filter(([key, fieldObject]) => {
                let result = false;
                if (fieldObject.required && fieldObject.value === '') {
                    availableUserFieldsWithErrorsMessage[key].errorMessage = 'required.field';
                    result = true;
                }
                return result;
            })
        );
        if (Object.keys(missingRequiredValues).length > 0) setUserDetails({ ...userDetails, availableUserFieldsWithErrorsMessage });

        const canProceed = Object.keys(missingRequiredValues).length <= 0 && !hasErrors;

        if (canProceed) {
            const payload = { personDetails: { ...updatedDetails } };
            saveDetails(payload, 'UserDetails');
        }
    };

    useEffect(() => {
        if (!loadingAccountLockUserDetails && !accountLocked) {
            setReadonly(false);
            clearLockAccount();
        }
    }, [loadingAccountLockUserDetails]);

    useEffect(() => {
        if ((!countries || !countries.length) && !loadingCountries) dispatch({ type: `${GLOBALS_SLICE_NAME}/fetchCountries` });

        const mainAccountDetails = (initialValues && initialValues.mainAccountDetails) || {};

        const newUserDetails = {
            firstName: {
                value: mainAccountDetails.firstName || '',
                placeholder: 'type.first.name',
                label: 'first.name',
                isDirty: false,
                element: 'Input',
                required: true,
                maxChars: inputsMaxChars.firstName,
            },
            surName: {
                value: mainAccountDetails.surName || '',
                placeholder: 'type.your.surname',
                label: 'surname',
                isDirty: false,
                element: 'Input',
                required: true,
                maxChars: inputsMaxChars.lastName,
            },
            organizationName: {
                value: mainAccountDetails.organizationName || '',
                placeholder: 'type.name',
                label: 'organisation.name',
                isDirty: false,
                element: 'Input',
                required: true,
                maxChars: inputsMaxChars.organizationName,
            },
            legalEntityCode: {
                value: mainAccountDetails.legalEntityCode || '',
                placeholder: 'type.legal.entity.code',
                label: 'legal.entity.code',
                isDirty: false,
                element: 'Input',
                maxChars: inputsMaxChars.legalEntityCode,
            },
            legalPosition: {
                value: mainAccountDetails.legalPosition || '',
                placeholder: 'type.legal.position',
                label: 'legal.position',
                isDirty: false,
                element: 'Input',
                required: true,
                maxChars: inputsMaxChars.legalPosition,
                editable: true,
            },
        };

        let finalUserDetails = { ...newUserDetails };
        setUserDetails(finalUserDetails);
        setReadonly(true);
    }, [initialValues, countries, dispatch, states, triggerInitialData]);

    const renderItems = (
        <React.Fragment>
            {Object.keys(userDetails)
                .filter((f) => !userDetails[f].noIterrate && userFields.includes(f))
                .map((keyName, idx) => {
                    const { label, placeholder, value, options, element, isLinkType, errorMessage, required = false, editable = false } = userDetails[keyName];
                    let selectOptions = options || [];
                    const commonProps = {
                        id: `${idx}_${keyName}`,
                        placeholder: t(placeholder),
                        name: keyName,
                        onChange: handleValueChange,
                        value: value || '',
                    };
                    const valueClass = !isLinkType || !value ? classes.fieldValue : classes.linkValue;
                    const diplayedValue = value || '-';
                    const inputOrDropdown =
                        element === 'Input' ? (
                            <Input
                                {...commonProps}
                                className={classes.textInput}
                                type="text"
                                error={!!errorMessage}
                                fullWidth
                                autoFocus={idx === 0}
                            />
                        ) : (
                            <Select
                                {...commonProps}
                                className={`${classes.textInput} ${classes.selectWidth}`}
                                displayEmpty
                                error={!!errorMessage}
                                IconComponent={DropDownArrow}>
                                <MenuItem value="" disabled>
                                    {t(placeholder)}
                                </MenuItem>
                                {(selectOptions || []).map((option, idx) => (
                                    <MenuItem key={`${option.key}_${idx}`} value={option.code}>
                                        {option.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        );

                    return (
                        <div key={`${keyName}_${idx}`}>
                            {readonly ? (
                                <React.Fragment>
                                    <Typography className={classes.fieldLabel}>
                                        {t(label)} {editable && required && ' *'}
                                    </Typography>
                                    <Typography className={valueClass}>{diplayedValue}</Typography>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <InputLabel error={!!errorMessage} htmlFor={keyName} className={classes.fieldLabel}>
                                        {t(label)} {editable && required && ' *'}
                                    </InputLabel>
                                    {editable ? (
                                        inputOrDropdown
                                    ) : (
                                        <Typography className={valueClass}>{diplayedValue}</Typography>
                                    )}
                                    {!!errorMessage && <ErrorInput errorMessage={t(errorMessage)} classes={`${classes.inputError}`} />}
                                </React.Fragment>
                            )}
                        </div>
                    );
                })}
        </React.Fragment>
    );

    return (
        <Card
            title={'user.name.details.title'}
            canEdit={hasAdministratorRole}
            readonly={readonly}
            editCard={editCard}
            saveCard={saveCard}
            cancelCard={cancelCard}
            cardId={'profile_user_details_card'}>
            {loading || loadingAccountLockUserDetails ? <CircularProgress /> : renderItems}
        </Card>
    );
};

export default UserDetailsCard;
