import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import OrSeparator from '../../AuthenticatedPage/components/OrSeparator';

import { getCookie } from '../../../common/utils';
import ErrorInput from '../../../components/ErrorInput';
import { t } from '../../../providers';

const useStyles = makeStyles((theme) => ({
    loginBoxWrapper: {
        display: 'flex',
        flexFlow: 'row nowrap',
    },
    forgotPasswordLink: {
        color: '#212121',
        marginBottom: '1rem',
        fontWeight: '600',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    inputWrapper: {
        marginBottom: '1.5rem',
    },
    // IE11 fix for flexbox - direction column.
    gridItem: {
        flex: '1 1 auto',
    },
    textInput: {
        height: '2.5rem',
        width: '14rem',
        marginTop: '10px',
        marginBottom: '10px',
        '@media (max-width:600px)': {
            width: '12.5rem',
        },
    },
    inputError: {
        marginTop: '5px',
    },
    inputErrorPassword: {
        marginTop: '5px',
        marginBottom: '10px',
    },
    fieldLabel: {
        fontWeight: theme.typography.titleFontWeight,
        fontSize: theme.typography.titleFontSize,
        color: '#546E7A',
    },
    loginButton: {
        width: '11.75rem',
        boxShadow: 'none',
        margin: '0',
        '@media (max-width:600px)': {
            minwidth: '11rem',
            height: '3rem',
        },
    },
    ErrorMessage: {
        color: 'red',
        fontWeight: '600',
        textAlign: 'center',
        height: '24px',
    },
}));

const LoginPageComponent = ({ login, navigateToResetAccount, loading, error }) => {
    const classes = useStyles();
    const applicationName = getCookie('APPLICATION_NAME');

    const [username, setUsername] = useState({ value: '', isDirty: false });
    const [password, setPassword] = useState({ value: '', isDirty: false });

    const handlePasswordChange = (event) => {
        event.preventDefault();
        setPassword({ ...password, value: event.target.value, isDirty: true });
    };

    const handleEmailChange = (event) => {
        event.preventDefault();
        setUsername({ ...username, value: event.target.value, isDirty: true });
    };

    const submitLogin = (event) => {
        event.preventDefault();
        if (!username.value || !password.value) {
            setUsername({ ...username, isDirty: true });
            setPassword({ ...password, isDirty: true });
        } else {
            login({ username: username.value, password: password.value, applicationName });
        }
    };

    const loginForm = (
        <div className={classes.loginBoxWrapper}>
            <div>
            <form onSubmit={submitLogin} noValidate>
                <Grid container direction="column" className={classes.inputWrapper}>
                    <Grid item xs md sm className={classes.gridItem}>
                        <InputLabel error={!!error || (username.isDirty && !username.value)} htmlFor="username" className={classes.fieldLabel}>
                            {t('your.email')}*
                        </InputLabel>
                        <Input
                            id="username"
                            className={classes.textInput}
                            placeholder={t('type.your.email')}
                            type="text"
                            name="username"
                            error={!!error || (username.isDirty && !username.value)}
                            fullWidth
                            autoFocus
                            required
                            onChange={handleEmailChange}
                            value={username.value}
                        />
                        {username.isDirty && !username.value && <ErrorInput errorMessage={t('required.field')} classes={`${classes.inputError}`} />}
                    </Grid>
                </Grid>
                <Grid container direction="column" className={classes.inputWrapper}>
                    <Grid item xs sm md className={classes.gridItem}>
                        <InputLabel error={!!error || (password.isDirty && !password.value)} htmlFor="password" className={classes.fieldLabel}>
                            {t('your.password')}*
                        </InputLabel>
                        <Input
                            id="password"
                            className={classes.textInput}
                            placeholder={t('type.your.password')}
                            type="password"
                            name="password"
                            error={!!error || (password.isDirty && !password.value)}
                            fullWidth
                            required
                            onChange={handlePasswordChange}
                            value={password.value}
                        />
                        {password.isDirty && !password.value && (
                            <ErrorInput errorMessage={t('required.field')} classes={`${classes.inputErrorPassword}`} />
                        )}
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <Typography className={classes.forgotPasswordLink}>
                            <Link onClick={navigateToResetAccount}>{t('i.forgot.my.password')}</Link>
                        </Typography>
                    </Grid>
                    {!!error && (
                        <Grid item xs sm md>
                            <Typography color="error">{t(error)}</Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={0} className={classes.inputWrapper}>
                    <Button variant="contained" className={classes.loginButton} style={{ textTransform: 'none' }} type="submit">
                        {t('log.in.into.my.user.area')}
                    </Button>
                </Grid>
            </form>
            </div>
            <OrSeparator></OrSeparator>
        </div>
    );

    return loading ? <CircularProgress /> : loginForm;
};

export default LoginPageComponent;
