import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import { downloadFile } from '../../common/utils/service';
import { SLICE_NAME as LIST_SLICE_NAME } from '../CorrespondencePage/constants';
import { listParameters as listParametersSlice } from '../CorrespondencePage/correspondenceSlice';
import { parseRequestData } from '../CorrespondencePage/utils';
import { SLICE_NAME } from './constants';
import {
    fetchCorrespondenceDetails,
    initNewDraft,
    deleteDraft,
    sendDraft,
    saveDraft,
    downloadIncoming,
    downloadOutgoing,
    uploadAttachment,
    deleteAttachment,
    downloadAttachment,
    downloadDraftAttachment,
} from './service';

function* requestCorrespondenceDetails(action) {
    const persistentFilters = yield select(listParametersSlice);
    const parsedParams = parseRequestData(persistentFilters);
    const disableLoading = !!action.payload.params.disableLoading;
    yield all([!disableLoading && put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(fetchCorrespondenceDetails, {
        params: {
            ...action.payload.params,
            ...parsedParams,
        },
    });
    if (!response.error) {
        yield put({
            type: `${SLICE_NAME}/details`,
            payload: response,
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    if (!disableLoading) {
        yield put({ type: `${SLICE_NAME}/loading`, payload: false });
    }
}

function* getCorrespondenceDetails() {
    yield takeLatest(`${SLICE_NAME}/requestCorrespondenceDetails`, requestCorrespondenceDetails);
}

function* createDraftMessage(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(initNewDraft, {
        ...action.payload,
    });
    if (!response.error) {
        const params = {
            corrId: response.messageId,
        };
        yield call(requestCorrespondenceDetails, { payload: { params } });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.code === 403 ? response.error : response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* newDraftMessage() {
    yield takeLatest(`${SLICE_NAME}/createDraftMessage`, createDraftMessage);
}

function* requestDraftCorrespondenceDelete(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(deleteDraft, {
        ...action.payload,
    });
    if (!response.error) {
        if (action.payload.messageId) {
            const params = {
                corrId: action.payload.messageId,
            };
            yield call(requestCorrespondenceDetails, { payload: { params } });
        } else if (action.payload.actionParams) {
            yield put({ type: `${LIST_SLICE_NAME}/requestCorrespondence`, payload: action.payload.actionParams });
        }
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* deleteDraftCorrespondence() {
    yield takeLatest(`${SLICE_NAME}/requestDraftCorrespondenceDelete`, requestDraftCorrespondenceDelete);
}

function* requestDraftCorrespondenceSend(action) {
    yield all([put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(sendDraft, {
        ...action.payload,
    });
    if (!response.error) {
        action.payload.redirectToCorrespondence();
        yield put({ type: `${SLICE_NAME}/isSent`, payload: true });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
}

function* sendDraftCorrespondence() {
    yield takeLatest(`${SLICE_NAME}/requestDraftCorrespondenceSend`, requestDraftCorrespondenceSend);
}

function* saveDraftCorrespondence(action) {
    yield all([put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(saveDraft, { ...action.payload });
    if (response.error) {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
}

function* updateDraftCorrespondence() {
    yield takeLatest(`${SLICE_NAME}/saveDraftCorrespondence`, saveDraftCorrespondence);
}

function* requestIncomingCorrespondenceDownload(action) {
    yield all([put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(downloadIncoming, {
        ...action.payload,
    });
    if (!response.error) {
        downloadFile(response);
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
}

function* downloadIncomingMessage() {
    yield takeLatest(`${SLICE_NAME}/downloadIncomingMessage`, requestIncomingCorrespondenceDownload);
}

function* requestOutgoingCorrespondenceDownload(action) {
    yield all([put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(downloadOutgoing, {
        ...action.payload,
    });
    if (!response.error) {
        downloadFile(response);
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
}

function* downloadOutgoingMessage() {
    yield takeLatest(`${SLICE_NAME}/downloadOutgoingMessage`, requestOutgoingCorrespondenceDownload);
}

function* requestUploadAttachmentCorrespondence(action) {
    yield all([put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(uploadAttachment, { ...action.payload });
    if (!response.error) {
        if (action.payload.messageId) {
            const params = {
                corrId: action.payload.messageId,
                disableLoading: true,
            };
            yield call(requestCorrespondenceDetails, { payload: { params } });
        } else if (action.payload.actionParams) {
            yield put({ type: `${LIST_SLICE_NAME}/requestCorrespondence`, payload: action.payload.actionParams });
        }
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.code === 403 ? response.error : response.error.description });
    }
}

function* uploadAttachmentCorrespondence() {
    yield takeLatest(`${SLICE_NAME}/uploadAttachmentCorrespondence`, requestUploadAttachmentCorrespondence);
}

function* requestDeleteAttachmentCorrespondence(action) {
    yield all([put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(deleteAttachment, {
        ...action.payload,
    });
    if (!response.error) {
        if (action.payload.messageId) {
            const params = {
                corrId: action.payload.messageId,
                disableLoading: true,
            };
            yield call(requestCorrespondenceDetails, { payload: { params } });
        } else if (action.payload.actionParams) {
            yield put({ type: `${LIST_SLICE_NAME}/requestCorrespondence`, payload: action.payload.actionParams });
        }
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
}

function* deleteAttachmentCorrespondence() {
    yield takeLatest(`${SLICE_NAME}/deleteAttachmentCorrespondence`, requestDeleteAttachmentCorrespondence);
}

function* requestDownloadAttachmentCorrespondence(action) {
    yield all([put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(downloadAttachment, {
        ...action.payload,
    });
    if (!response.error) {
        downloadFile(response);
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
}

function* downloadAttachmentCorrespondence() {
    yield takeLatest(`${SLICE_NAME}/downloadAttachmentCorrespondence`, requestDownloadAttachmentCorrespondence);
}

function* requestDownloadAttachmentDraftCorrespondence(action) {
    yield all([put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(downloadDraftAttachment, {
        ...action.payload,
    });
    if (!response.error) {
        downloadFile(response);
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
}

function* downloadAttachmentDraftCorrespondence() {
    yield takeLatest(`${SLICE_NAME}/downloadAttachmentDraftCorrespondence`, requestDownloadAttachmentDraftCorrespondence);
}

export {
    getCorrespondenceDetails,
    newDraftMessage,
    deleteDraftCorrespondence,
    sendDraftCorrespondence,
    updateDraftCorrespondence,
    downloadIncomingMessage,
    downloadOutgoingMessage,
    uploadAttachmentCorrespondence,
    deleteAttachmentCorrespondence,
    downloadAttachmentCorrespondence,
    downloadAttachmentDraftCorrespondence,
};
