import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';

import { globals as globalsSlice } from '../../app/globalsSlice';
import Card from '../../components/Card';
import WarningModal from '../../components/WarningModal';
import { t } from '../../providers';
import { me as meSlice } from '../AuthenticatedPage/authenticationSlice';
import { SLICE_NAME as CORRESPONDENCE_SLICE_NAME } from '../CorrespondencePage/constants';
import { listParameters } from '../CorrespondencePage/correspondenceSlice';
import Draft from './components/Draft';
import Incoming from './components/Incoming';
import IncomingMobile from './components/Incoming/IncomingMobile';
import Outgoing from './components/Outgoing';
import { SLICE_NAME } from './constants';
import { details, loading as loadingSlice, error as errorSlice } from './correspondenceDetailsSlice';

const useStyles = makeStyles((_theme) => ({
    container: {
        width: '100%',
        height: '100%',
        maxWidth: 'calc(100% - 236px)',
        '@media (max-width:1100px)': {
            minWidth: '77%',
            maxWidth: 'unset',
        },
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width:1100px)': {
            display: 'block',
        },
    },
    cardTable: {
        marginRight: '20px',
        '@media (max-width:960px)': {
            marginRight: 'unset',
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
}));

const CorrespondenceDetails = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const loading = useSelector(loadingSlice);
    const error = useSelector(errorSlice);
    const { globals } = useSelector(globalsSlice) || {};
    const correspondenceDetails = useSelector(details);
    const persistentFilters = useSelector(listParameters);
    const isTablet = useMediaQuery('(max-width:960px)');
    const locationState = props.location.state || {};
    const me = useSelector(meSlice);

    const { threadId, openReply, initiateReply } = locationState || {};
    let corrDetails = { ...correspondenceDetails } || {};

    const MAX_REPLIES = globals?.application['correspondence.maxReplies'];
    const AUTOSAVE_INTERVAL = globals?.application['correspondence.autosave'];

    const [pageParams, setPageParams] = useState({ initReply: '' });
    const [expandedReply, setExpandedReply] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({
        visibility: false,
        message: '',
        onConfirmation: () => {},
        confirmButtonText: '',
        cancelButtonText: '',
        onCancel: () => {},
    });
    const [createdDraft, setCreatedDraft] = useState(false);
    const [forceSaveOnClose, setForceSaveOnClose] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    const updatePersistentParameters = (params) => dispatch({ type: `${CORRESPONDENCE_SLICE_NAME}/updateCorrParameters`, payload: params });

    const redirectToCorrespondence = (threadId, nextMessage, currentIndex) => {
        const { requestPage, size: pageSize } = persistentFilters;
        const page = requestPage + 1;
        if (nextMessage) {
            if (page * pageSize === currentIndex)
                updatePersistentParameters({
                    ...persistentFilters,
                    requestPage: requestPage + 1,
                });
        } else {
            if ((page - 1) * pageSize === currentIndex - 1)
                updatePersistentParameters({
                    ...persistentFilters,
                    requestPage: requestPage - 1,
                });
        }
        props.history.push({
            pathname: `/correspondence/${threadId}`,
            state: { threadId: threadId },
        });
    };

    const handleClose = (fromSend = false) => {
        const draftFound = corrDetails?.subRows?.find((item) => item.draftStatus === 'DRAFT');
        if (!fromSend && draftFound) {
            setForceSaveOnClose(true);
        } else {
            props.history.push({ pathname: '/correspondence' });
        }
    };

    const getThreadIdFromUrl = () => {
        let threadId = '';
        const pathNames = props.location.pathname.split('/').filter((x) => x);
        if (pathNames[0] === 'correspondence' && pathNames[1]) {
            threadId = pathNames[1];
        }
        return threadId;
    };

    const handleCancelModalAction = (goToGrid = false) => {
        setConfirmDialog({
            visibility: false,
            message: '',
            onConfirmation: () => {},
            confirmButtonText: '',
            cancelButtonText: '',
            onCancel: () => {},
        });
        if (error) dispatch({ type: `${SLICE_NAME}/error`, payload: false });
        if (goToGrid) handleClose();
    };

    const createDraftMessage = (fromModal) => {
        dispatch({ type: `${SLICE_NAME}/createDraftMessage`, payload: { messageId: threadId || getThreadIdFromUrl() } });
        if (fromModal) handleCancelModalAction();
        setCreatedDraft(true);
    };

    const handleReply = () => {
        if (!!Object.keys(corrDetails).length && !!corrDetails.subRows.length) {
            setExpandedReply('');
            //we have replies
            const draftFound = corrDetails.subRows.find((item) => item.draftStatus === 'DRAFT');
            if (draftFound) {
                //edit existing draft
                setExpandedReply(draftFound.id);
            } else {
                //show modal in order to create new draft or not
                setConfirmDialog({
                    visibility: true,
                    message: t('confirm.draft.on.already.replied.correspondence'),
                    onConfirmation: () => createDraftMessage(true),
                    confirmButtonText: t('yes.text'),
                    cancelButtonText: t('no.text'),
                    onCancel: () => handleCancelModalAction(),
                });
            }
        } else {
            //no replies
            createDraftMessage();
        }
        setPageParams({ initReply: '' });
    };

    const downloadAttachment = (attachmentId) => {
        dispatch({ type: `${SLICE_NAME}/downloadAttachmentCorrespondence`, payload: { attachmentId: attachmentId } });
    };

    const downloadAttachmentDraft = (attachmentId) => {
        dispatch({ type: `${SLICE_NAME}/downloadAttachmentDraftCorrespondence`, payload: { attachmentId: attachmentId } });
    };

    const handleDeleteAttachment = (_attachmentId) =>
        setConfirmDialog({
            visibility: true,
            message: t('delete.attachment.correspondence.confirmation'),
            onConfirmation: () => deleteAttachment(_attachmentId),
            confirmButtonText: t('delete'),
            cancelButtonText: t('cancel'),
            onCancel: () => handleCancelModalAction(),
        });

    const deleteAttachment = (_attachmentId) => {
        dispatch({
            type: `${SLICE_NAME}/deleteAttachmentCorrespondence`,
            payload: { attachmentId: _attachmentId, messageId: threadId || getThreadIdFromUrl() },
        });
        handleCancelModalAction();
    };

    const downloadIncomingCorrespondence = (correspondenceId) =>
        dispatch({ type: `${SLICE_NAME}/downloadIncomingMessage`, payload: { corrId: correspondenceId } });

    const downloadOutgoingCorrespondence = (correspondenceId) =>
        dispatch({ type: `${SLICE_NAME}/downloadOutgoingMessage`, payload: { corrId: correspondenceId } });

    const saveDraft = (draftId, content) => {
        dispatch({
            type: `${SLICE_NAME}/saveDraftCorrespondence`,
            payload: {
                draftId,
                messageId: threadId || getThreadIdFromUrl(),
                message: content,
            },
        });
        if (forceSaveOnClose) setForceSaveOnClose(false);
    };

    const sendDraft = (draftId, content) => {
        dispatch({
            type: `${SLICE_NAME}/requestDraftCorrespondenceSend`,
            payload: {
                draftId,
                messageId: threadId || getThreadIdFromUrl(),
                message: content,
                redirectToCorrespondence: () => handleClose(true),
            },
        });
    };

    const handleDeleteDraft = (draftId) =>
        setConfirmDialog({
            visibility: true,
            message: t('delete.draft.correspondence.confirmation'),
            onConfirmation: () => deleteDraft(draftId),
            confirmButtonText: t('delete'),
            cancelButtonText: t('cancel'),
            onCancel: () => handleCancelModalAction(),
        });

    const deleteDraft = (draftId) => {
        dispatch({ type: `${SLICE_NAME}/requestDraftCorrespondenceDelete`, payload: { draftId, messageId: threadId || getThreadIdFromUrl() } });
        handleCancelModalAction();
    };

    const canReadOnly = (authorities) =>
        !authorities.includes('ROLE_CORRESPONDENCE_READ_WRITE') && authorities.includes('ROLE_CORRESPONDENCE_READ_ONLY');

    useEffect(() => {
        const { authorities, impersonatedUser } = me;

        if (canReadOnly(authorities) || !!impersonatedUser) setReadOnly(true);
    }, [me]);

    useEffect(() => {
        const id = threadId || getThreadIdFromUrl();
        const params = { corrId: id };

        if (id && !!Object.keys(persistentFilters).length) {
            if (persistentFilters.selectedTab) {
                dispatch({ type: `${SLICE_NAME}/requestCorrespondenceDetails`, payload: { params } });
                // update new corr/nce counter
                dispatch({ type: `${CORRESPONDENCE_SLICE_NAME}/requestNewCorrespondencesCount` });
            } else {
                props.history.push({ pathname: '/correspondence' });
            }
        }
    }, [dispatch, threadId]);

    useEffect(() => {
        if (locationState && !!Object.keys(locationState).length) {
            setPageParams({ initReply: initiateReply });
        }
    }, [locationState]);

    useEffect(() => {
        setExpandedReply(openReply);
    }, [openReply]);

    useEffect(() => {
        if (correspondenceDetails && !!Object.keys(correspondenceDetails) && createdDraft) {
            const draftFound = correspondenceDetails.subRows.find((item) => item.draftStatus === 'DRAFT');
            setExpandedReply(draftFound.id);
            setCreatedDraft(false);
        }
    }, [correspondenceDetails]);

    useEffect(() => {
        if (!!error) {
            const messageModal =
                error.code === 403 ? t(error.description, { username: error.details.username, message: error.details.message }) : t(error);
            setConfirmDialog({
                visibility: true,
                message: messageModal,
                onConfirmation: () => handleCancelModalAction(!!(error === 'cannot.open.correspondence')),
                confirmButtonText: t('ok'),
                cancelButtonText: '',
                onCancel: () => {},
            });
        }
    }, [error]);

    const incomingContent = isTablet ? (
        <IncomingMobile
            content={corrDetails}
            downloadAttachment={downloadAttachment}
            downloadCorrespondence={downloadIncomingCorrespondence}
            redirectToCorrespondence={redirectToCorrespondence}
            maxRepliesAllowed={MAX_REPLIES}
            onClose={() => handleClose()}
            onReply={handleReply}
            initReply={pageParams.initReply}
            readOnly={readOnly}
        />
    ) : (
        <Incoming
            content={corrDetails}
            downloadAttachment={downloadAttachment}
            downloadCorrespondence={downloadIncomingCorrespondence}
            redirectToCorrespondence={redirectToCorrespondence}
            maxRepliesAllowed={MAX_REPLIES}
            onClose={() => handleClose()}
            onReply={handleReply}
            initReply={pageParams.initReply}
            readOnly={readOnly}
        />
    );

    const parentInfo = corrDetails && {
        applicationNumber: corrDetails?.message?.applicationNumber,
        dossierType: corrDetails?.message?.dossierType,
        messageId: corrDetails?.message?.id,
    };

    const outgoingContent =
        !!Object.keys(corrDetails).length &&
        corrDetails.subRows.map((outgoing, idx) =>
            outgoing.draftStatus === 'SENT' ? (
                <Outgoing
                    key={idx}
                    isTablet={isTablet}
                    content={outgoing}
                    parentInfo={parentInfo}
                    open={expandedReply === outgoing.id}
                    downloadAttachment={downloadAttachmentDraft}
                    downloadCorrespondence={downloadOutgoingCorrespondence}
                />
            ) : (
                <Draft
                    key={idx}
                    isTablet={isTablet}
                    content={outgoing}
                    parentInfo={parentInfo}
                    open={expandedReply === outgoing.id}
                    setOpen={setExpandedReply}
                    downloadAttachment={downloadAttachmentDraft}
                    deleteAttachment={handleDeleteAttachment}
                    previewPdf={downloadOutgoingCorrespondence}
                    onSave={saveDraft}
                    onSend={sendDraft}
                    onDelete={handleDeleteDraft}
                    autoSaveInterval={AUTOSAVE_INTERVAL}
                    forceSave={forceSaveOnClose}
                    readOnly={readOnly}
                />
            )
        );

    return (
        <div className={classes.container}>
            {!loading && corrDetails ? (
                <div className={classes.cardTable}>
                    <Card canEdit={false}>
                        {incomingContent}
                        {outgoingContent}
                        {!isTablet && !!corrDetails?.subRows?.length && (
                            <div className={classes.actions}>
                                <Button className={classes.closeButton} variant="contained" onClick={() => handleClose()}>
                                    {t('close.and.back')}
                                </Button>
                            </div>
                        )}
                    </Card>
                </div>
            ) : (
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100%'}>
                    <CircularProgress />
                </Box>
            )}
            {/* confirmation modal */}
            {confirmDialog.visibility && (
                <WarningModal
                    visibility={confirmDialog.visibility}
                    message={confirmDialog.message}
                    onConfirmation={confirmDialog.onConfirmation}
                    confirmButtonText={confirmDialog.confirmButtonText}
                    cancelButtonText={confirmDialog.cancelButtonText}
                    onCancel={confirmDialog.onCancel}
                />
            )}
        </div>
    );
};

export default CorrespondenceDetails;
