import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ApplicationsTableClientSide from '../../../../../components/ApplicationsTableClientSide';
import Card from '../../../../../components/Card';
import PageTitle from '../../../../../components/PageTitle';
import { t } from '../../../../../providers';

const useStyles = makeStyles((theme) => ({
    cardTable: {
        marginRight: '20px',
        '@media (max-width:960px)': {
            marginRight: 'unset',
        },
    },
    tableActions: {
        marginTop: '10px',
        color: theme.palette.text.detailsGrey,
        fontSize: theme.typography.fontSize,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '&  span': {
            padding: '5px',
        },
        '@media (max-width:960px)': {
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
    },
    totalCost: {
        fontWeight: theme.typography.fontWeightBold,
    },
    blackBold: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '14px',
        color: 'black',
    }
}));

const ShoppingCartListTable = ({
    loading,
    setSorting,
    sortProps,
    shoppingCartList,
    totalCost,
    size,
    number,
    customPagination,
    proceedToPayment,
    proceedToConfirmPayment,
    filters,
    selectedApplications,
    readOnly,
}) => {
    const classes = useStyles();

    const shoppingCartsTable = !loading ? (
        <ApplicationsTableClientSide
            setSorting={setSorting}
            sortBy={sortProps.sortColumn}
            sortType={sortProps.sortType}
            applications={shoppingCartList}
            tableId={'applicationsForPaymentTable'}
            size={size}
            page={number}
            noAvailableDataLabel={t('no.shopping.carts')}
        />
    ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
        </Box>
    );

    return (
        <React.Fragment>
            <Typography className={classes.blackBold}>{t('shopping.carts.description')} </Typography>
            <div className={classes.cardTable}>
                <Card canEdit={false}>
                    {filters}
                    {shoppingCartsTable}
                    <div className={classes.tableActions}>
                        {!selectedApplications.length &&
                            <span className={classes.blackBold}>{t('applications.not.selected')}</span>
                        }
                        {!!selectedApplications.length &&
                            <span>{t('applications.selected', {num: selectedApplications.length})}</span>
                        }
                        {!!selectedApplications.length && <span className={classes.totalCost}>{`${t('total.cost')}: ${(totalCost / 100).toFixed(2)} €`}</span>}
                        <span>
                            <Button
                                variant={'contained'}
                                id={'already-paid-btn'}
                                onClick={proceedToConfirmPayment}
                                disabled={!selectedApplications.length || readOnly}>
                                {t('already.paid')}
                            </Button>
                            <Button
                                variant={'contained'}
                                id={'proceed-to-pay-btn'}
                                onClick={proceedToPayment}
                                disabled={!selectedApplications.length || readOnly}>
                                {t('pay.btn')}
                            </Button>
                        </span>
                    </div>
                    {customPagination}
                </Card>
            </div>
        </React.Fragment>
    );
};

export default ShoppingCartListTable;
