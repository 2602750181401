import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import { LITERALS_SLICE_NAME, NOTIFICATIONS_SLICE_NAME } from '../../app/constants';
import { setLocale, t, getLocale } from '../../providers';
import { me as meSlice } from '../AuthenticatedPage/authenticationSlice';
import { SLICE_NAME as AUTH_SLICE_NAME } from '../AuthenticatedPage/constants';
import { SLICE_NAME } from './constants';
import {
    fetchDetails,
    updateUserLanguage,
    updateUserPersonType,
    updateUserPassword,
    updateDetails,
    registerNewChildAccount,
    fetchChildAccountsPaginated,
    updateChildAccount,
    deactivateChild,
    activateChild,
    deactivateAccount,
    confirmDeactivateAccount,
    lockAccount,
    cancelUsernameChangeRequest,

} from './service';

function* getDetails(action) {
    const fromCard = action.payload || {};
    yield all([put({ type: `${SLICE_NAME}/loading${fromCard}`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);

    const response = yield call(fetchDetails);
    if (!response.error) {
        const { loginDetails, userId, parentUserId, ...rest } = response;
        const user = { ...loginDetails, userId, parentUserId };
        const { language } = user;
        const defaultLanguage = getLocale();
        if (defaultLanguage !== language) {
            setLocale(language);
            yield put({ type: `${LITERALS_SLICE_NAME}/fetchLiterals`, payload: { language } });
        }
        yield all([put({ type: `${SLICE_NAME}/details`, payload: { ...rest } }), put({ type: `${AUTH_SLICE_NAME}/me`, payload: user })]);
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading${fromCard}`, payload: false });
}

function* getAccountDetails() {
    yield takeLatest(`${SLICE_NAME}/getAccountDetails`, getDetails);
}

function* setLanguage(action) {
    const me = yield select(meSlice);
    const username = (me && me.username) || null;
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(updateUserLanguage, { ...(action.payload || {}), userName: username });
    if (!response.error) {
        setLocale(response.language);
        yield all([
            put({
                type: `${SLICE_NAME}/requestResult`,
                payload: { ...response, language: action.payload.language, ok: true },
            }),
            put({ type: `${AUTH_SLICE_NAME}/me`, payload: { ...me, language: action.payload.language } }),
            put({ type: `${LITERALS_SLICE_NAME}/fetchLiterals`, payload: { language: response.language } }),
        ]);
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* updateLanguage() {
    yield takeLatest(`${SLICE_NAME}/updateLanguage`, setLanguage);
}

function* setPersonType(action) {
    const me = yield select(meSlice);
    const username = (me && me.username) || null;
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(updateUserPersonType, { ...(action.payload || {}), userName: username });
    if (!response.error) {
        const detailsResponse = yield call(fetchDetails);
        if (!detailsResponse.error) {
            const { ...rest } = detailsResponse;
            yield all([put({ type: `${SLICE_NAME}/details`, payload: { ...rest } }), put({ type: `${AUTH_SLICE_NAME}/me`, payload: me })]);
        } else {
            yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
        }
        yield all([
            put({
                type: `${SLICE_NAME}/requestResult`,
                payload: { ...response, personType: action.payload.personType, ok: true },
            }),
            put({ type: `${AUTH_SLICE_NAME}/me`, payload: { ...me, personType: action.payload.personType } }),
        ]);
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* updatePersonType() {
    yield takeLatest(`${SLICE_NAME}/updatePersonType`, setPersonType);
}

function* updatePassword(action) {
    const me = yield select(meSlice);
    const username = (me && me.username) || null;
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(updateUserPassword, { ...(action.payload || {}), userName: username });
    if (!response.error) {
        yield put({
            type: `${SLICE_NAME}/requestResult`,
            payload: { ...response, ok: true },
        });
        yield put({
            type: `${NOTIFICATIONS_SLICE_NAME}/addNotification`,
            payload: { message: t('password.change.success') },
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* changePassword() {
    yield takeLatest(`${SLICE_NAME}/updatePassword`, updatePassword);
}

function* updateProfile(action) {
    const user = yield select(meSlice);
    const { userId, username } = user;
    yield all([
        put({ type: `${SLICE_NAME}/loading${action.payload.fromCard}`, payload: true }),
        put({ type: `${SLICE_NAME}/error`, payload: false }),
    ]);
    const response = yield call(updateDetails, { ...(action.payload.requestBody || {}), userId: userId });
    if (!response.error) {
        const detailsResponse = yield call(fetchDetails, { userName: username });
        if (!detailsResponse.error) {
            delete detailsResponse.loginDetails;
            const { ...rest } = detailsResponse;
            yield all([put({ type: `${SLICE_NAME}/details`, payload: { ...rest } }), put({ type: `${AUTH_SLICE_NAME}/me`, payload: user })]);
        } else {
            yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
        }
        yield put({
            type: `${NOTIFICATIONS_SLICE_NAME}/addNotification`,
            payload: { message: t('information.successfully.modified') },
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading${action.payload.fromCard}`, payload: false });
}

function* updateAccountDetails() {
    yield takeLatest(`${SLICE_NAME}/updateAccountDetails`, updateProfile);
}

function* createNewChildAccount(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(registerNewChildAccount, action.payload);
    if (!response.error) {
        yield put({
            type: `${SLICE_NAME}/requestResult`,
            payload: { ...response, ok: true },
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* createChildAccount() {
    yield takeLatest(`${SLICE_NAME}/createChildAccount`, createNewChildAccount);
}

function* requestChildAccounts(action) {
    const me = yield select(meSlice);
    const username = (me && me.username) || null;
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(fetchChildAccountsPaginated, { ...action.payload, userName: username });
    if (!response.error) {
        yield put({
            type: `${SLICE_NAME}/childAccounts`,
            payload: response,
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* getChildAccounts() {
    yield takeLatest(`${SLICE_NAME}/requestChildAccounts`, requestChildAccounts);
}

function* requestUpdateChildAccount(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(updateChildAccount, action.payload);
    if (!response.error) {
        yield put({
            type: `${SLICE_NAME}/requestResult`,
            payload: { ...response, ok: true, fromAction: 'editChildAccount' },
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* editChildAccount() {
    yield takeLatest(`${SLICE_NAME}/editChildAccount`, requestUpdateChildAccount);
}

function* requestDeactivateChildAccount(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(deactivateChild, action.payload);
    if (!response.error) {
        yield put({
            type: `${SLICE_NAME}/requestResult`,
            payload: { ...response, ok: true, fromAction: 'deactivateChildAccount' },
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* deactivateChildAccount() {
    yield takeLatest(`${SLICE_NAME}/deactivateChildAccount`, requestDeactivateChildAccount);
}

function* requestActivateChildAccount(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(activateChild, action.payload);
    if (!response.error) {
        yield put({
            type: `${SLICE_NAME}/requestResult`,
            payload: { ...response, ok: true, fromAction: 'activateChildAccount' },
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* activateChildAccount() {
    yield takeLatest(`${SLICE_NAME}/activateChildAccount`, requestActivateChildAccount);
}

function* requestDeactivateAccount() {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(deactivateAccount);
    if (!response.error) {
        yield put({
            type: `${SLICE_NAME}/deactivateResult`,
            payload: { ...response, ok: true, action: 'deactivate' },
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* deactivateParentAccount() {
    yield takeLatest(`${SLICE_NAME}/deactivateParentAccount`, requestDeactivateAccount);
}

function* requestConfirmDeactivateAccount() {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(confirmDeactivateAccount);
    if (!response.error) {
        yield put({
            type: `${SLICE_NAME}/deactivateResult`,
            payload: { ...response, ok: true, action: 'confirmDeactivate' },
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* confirmDeactivateParentAccount() {
    yield takeLatest(`${SLICE_NAME}/confirmDeactivateParentAccount`, requestConfirmDeactivateAccount);
}

function* requestLockAccountDetails(action) {
    yield all([
        put({ type: `${SLICE_NAME}/loadingAccountLock${action.payload.fromCard}`, payload: true }),
        put({ type: `${SLICE_NAME}/error`, payload: false }),
    ]);
    const response = yield call(lockAccount, action.payload);
    if (!response.error) {
        yield put({ type: `${SLICE_NAME}/accountLocked`, payload: false });
    } else {
        if (response.error.description.includes('locked')) {
            yield put({ type: `${SLICE_NAME}/accountLocked`, payload: true });
        }
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.code === 403 ? response.error : response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loadingAccountLock${action.payload.fromCard}`, payload: false });
}

function* lockAccountDetails() {
    yield takeLatest(`${SLICE_NAME}/lockAccountDetails`, requestLockAccountDetails);
}

function* cancelUsernameChangeHandler(action) {
    yield put({ type: `${SLICE_NAME}/loading`, payload: true });
    yield put({ type: `${SLICE_NAME}/error`, payload: false });
    const response = yield call(cancelUsernameChangeRequest, action.payload);
    if (typeof response === 'object' && response.error === undefined) {
        yield put({
            type: `${SLICE_NAME}/requestResult`,
            payload: { ...response, ok: true, fromAction: 'cancelUsernameChange' },
        });
    } else {
        yield put({
            type: `${SLICE_NAME}/error`,
            payload: response.error,
        });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* cancelUsernameChangeSaga() {
    yield takeLatest(`${SLICE_NAME}/cancelUsernameChange`, cancelUsernameChangeHandler);
}


export {
    activateChildAccount,
    createChildAccount,
    confirmDeactivateParentAccount,
    deactivateChildAccount,
    deactivateParentAccount,
    editChildAccount,
    getAccountDetails,
    getChildAccounts,
    updateLanguage,
    updatePersonType,
    changePassword,
    updateAccountDetails,
    lockAccountDetails,
    cancelUsernameChangeSaga,
};
