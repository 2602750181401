import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ApplicationsTable from '../../components/ApplicationsTable';
import Card from '../../components/Card';
import ApplicationFilters from '../../components/Filters/ApplicationFilters';
import CustomPagination from '../../components/Pagination/CustomPagination';
import WarningModal from '../../components/WarningModal';
import { t } from '../../providers';
import { me as meSlice } from '../AuthenticatedPage/authenticationSlice';
import { SLICE_NAME } from './constants';
import filterItems from './filterItems';
import { list, loading as loadingSlice, error as errorSlice } from './paymentHistorySlice';
import { filtersParser, paymentHistoryParserList } from './utils';

const useStyles = makeStyles((_theme) => ({
    cardTable: {
        marginRight: '20px',
        '@media (max-width:960px)': {
            marginRight: 'unset',
        },
    },
}));

const TRADEMARKS = 'trademark';
const DESIGNS = 'design';
const ASC = 'ASC';
const DESC = 'DESC';
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_SORT_COLUMN = 'PAYMENT_DATE';
const DEFAULT_SORT_TYPE = DESC;

const PaymentHistory = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const me = useSelector(meSlice);
    const loading = useSelector(loadingSlice);
    const paymentHistoryList = useSelector(list);
    const error = useSelector(errorSlice);

    const { totalResults, pageSize, pageNumber } = paymentHistoryList || {};

    const [availableAuthorities, setAvailableAuthorities] = useState([]);
    const [paymentHistoryParsed, setPaymentHistoryParsed] = useState([]);
    const [filtersValues, setFiltersValues] = useState({ searchQuery: '', filters: {}, filtersChips: {} });
    const [requestBodyData, setRequestBodyData] = useState({
        requestPage: DEFAULT_PAGE_NUMBER,
        size: DEFAULT_PAGE_SIZE,
        sort: DEFAULT_SORT_COLUMN,
        sortType: DEFAULT_SORT_TYPE,
        filterCriteria: filtersParser(filtersValues.filters),
    });
    const [dialog, setDialog] = useState({
        visibility: false,
        message: '',
        onConfirmation: () => {},
        confirmButtonText: '',
        cancelButtonText: '',
        onCancel: () => {},
    });

    const handleCloseDialog = () => {
        setDialog({
            visibility: false,
            message: '',
            onConfirmation: () => {},
            confirmButtonText: '',
            cancelButtonText: '',
            onCancel: () => {},
        });
    };

    const handleChangePage = (event, newPage) => {
        event.preventDefault();
        if (newPage !== pageNumber) {
            setRequestBodyData({ ...requestBodyData, requestPage: newPage, size: pageSize });
        }
    };

    const handleChangeRowsPerPage = (event) => {
        const newPageSize = event.target.value;
        if (pageSize !== newPageSize) {
            setRequestBodyData({ ...requestBodyData, requestPage: 0, size: newPageSize });
        }
    };

    const setSorting = (sortColumn) => {
        const isSameKey = sortColumn === requestBodyData.sort;
        const oppositeSort = requestBodyData.sortType === ASC ? DESC : ASC;
        const sortType = !isSameKey ? ASC : oppositeSort;
        setRequestBodyData({ ...requestBodyData, requestPage: DEFAULT_PAGE_NUMBER, size: pageSize, sort: sortColumn, sortType: sortType });
    };

    const clearFilters = () => {
        setFiltersValues({ ...filtersValues, filters: {}, filtersChips: {} });
    };

    const downloadInvoice = (paymentId) => {
        dispatch({ type: `${SLICE_NAME}/downloadInvoice`, payload: { params: { paymentId } } });
    };

    useEffect(() => {
        const { authorities } = me;

        const allowedTypes = [];
        if (authorities && authorities.includes('ROLE_TRADEMARKS')) allowedTypes.push(TRADEMARKS);
        if (authorities && authorities.includes('ROLE_DESIGNS')) allowedTypes.push(DESIGNS);
        if (!!allowedTypes.length) {
            setAvailableAuthorities(allowedTypes);
        } else {
            history.push('/');
        }
    }, [me, history]);

    useEffect(() => {
        if (!loading) dispatch({ type: `${SLICE_NAME}/getPaymentHistory`, payload: { params: { requestBodyData } } });
    }, [dispatch, requestBodyData]);

    useEffect(() => {
        const parsedData = paymentHistoryParserList(paymentHistoryList || [], downloadInvoice);
        setPaymentHistoryParsed(parsedData);
    }, [paymentHistoryList]);

    useEffect(() => {
        if (filtersValues.searchQuery) {
            setRequestBodyData({
                ...requestBodyData,
                requestPage: 0,
                filterCriteria: filtersParser(filtersValues.filters),
                search: filtersValues.searchQuery,
            });
        } else {
            const bodyData = { ...requestBodyData };
            delete bodyData['search'];
            setRequestBodyData({
                ...bodyData,
                requestPage: 0,
                filterCriteria: filtersParser(filtersValues.filters),
            });
        }
    }, [filtersValues]);

    useEffect(() => {
        if (!!error) {
            const messageModal =
                error.code === 403 ? t(error.description, { username: error.details.username, message: error.details.message }) : t(error);
            setDialog({
                visibility: true,
                message: messageModal,
                onConfirmation: handleCloseDialog,
                confirmButtonText: t('ok'),
                cancelButtonText: '',
                onCancel: handleCloseDialog,
            });
        }
    }, [error]);

    return (
        <div className={classes.container}>
            <div className={classes.cardTable}>
                <Card canEdit={false}>
                    <ApplicationFilters
                        filtersValues={filtersValues}
                        setFiltersValues={setFiltersValues}
                        clearFilters={clearFilters}
                        filterItems={filterItems}
                        authorities={availableAuthorities}
                        filterType={'eservicesTypes'}
                    />
                    {!loading ? (
                        <React.Fragment>
                            <ApplicationsTable
                                setSorting={setSorting}
                                sortBy={requestBodyData.sort}
                                sortType={requestBodyData.sortType}
                                applications={paymentHistoryParsed}
                                tableId={'paymentHistory'}
                            />
                            <CustomPagination
                                defaultPageSize={DEFAULT_PAGE_SIZE}
                                totalElements={totalResults}
                                size={pageSize || DEFAULT_PAGE_SIZE}
                                page={pageNumber || DEFAULT_PAGE_NUMBER}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                paginationId={'paymentHistory'}
                            />
                        </React.Fragment>
                    ) : (
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                </Card>
            </div>
            <WarningModal
                visibility={dialog.visibility}
                message={dialog.message}
                onConfirmation={dialog.onConfirmation}
                confirmButtonText={dialog.confirmButtonText}
                cancelButtonText={dialog.cancelButtonText}
                onCancel={dialog.onCancel}
            />
        </div>
    );
};

export default PaymentHistory;
