import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import ApplicationsTable from '../../../components/ApplicationsTable';
import Card from '../../../components/Card';
import { t } from '../../../providers';

const useStyles = makeStyles((theme) => ({
    cardTable: {
        marginRight: '20px',
        '@media (max-width:960px)': {
            marginRight: 'unset',
        },
    },
    pagination: {
        marginTop: '80px',
        '@media (max-width:960px)': {
            marginTop: 0,
        },
    },
}));

const HomepageListTable = ({
        loading,
        setSorting,
        sortProps,
        applicationsList,
        pageSize,
        pageNumber,
        customPagination,
    }) => {

    const classes = useStyles();

    const homepageTable = !loading ? (
        <ApplicationsTable
            setSorting={setSorting}
            sortBy={sortProps.sortColumn}
            sortType={sortProps.sortType}
            applications={applicationsList}
            tableId={'homepageTable'}
            size={pageSize}
            page={pageNumber}
            noAvailableDataLabel={t('no.recent.applications')}
        />
    ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
        </Box>
    );

    return (
        <React.Fragment>
            <div className={classes.cardTable}>
                <Card canEdit={false}>
                    {homepageTable}
                    {customPagination}
                </Card>
            </div>
        </React.Fragment>
    );
};

export default HomepageListTable;
