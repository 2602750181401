import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { t } from '../../../providers';

const useStyles = makeStyles((theme) => ({
    options: {
        '& ul': {
            padding: 0,
            margin: 0,
            '& li': {
                listStyle: 'none',
                margin: '1px 0 ',
                padding: '6px',
                '&:hover': {
                    background: theme.palette.text.lightBg,
                    borderRadius: '5px',
                    cursor: 'pointer',
                },
            },
        },
    },
}));
const InitiateEservice = ({ options, initiateAction }) => {
    const classes = useStyles();
    return (
        <div className={classes.options}>
            <ul>
                {options.map(({ key, label }, idx) => (
                    <li key={idx} onClick={() => initiateAction(key)}>
                        {t(label)}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default InitiateEservice;
