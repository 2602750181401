import { sendRequest } from '../../common/utils';

export const fetchShoppingCarts = ({ params }) => {
    const { sortColumn, sortType } = params;
    const url = '/payments/shoppingCart';
    return sendRequest({
        url,
        method: 'POST',
        prefix: '/backend',
        body: {
            sort: sortColumn,
            sortType,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const payApp = ({ params }) => {
    const { filingNumbers: filingNumbers, paymentReference: reference, additionalEmail: additionalEmail } = params;
    const url = '/payments';
    return sendRequest({
        url,
        method: 'POST',
        prefix: '/backend',
        body: {
            filingNumbers,
            reference,
            additionalEmail,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code, details: error.details } };
        });
};

export const confirmPayment = ({ params }) => {
    const url = '/payments/inAdvance';
    return sendRequest({
        url,
        method: 'POST',
        prefix: '/backend',
        headers: { 'Content-Type': 'multipart/form-data' },
        returnJSON: false,
        body: params,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message ? error.message : 'something.went.wrong', code: error.code, details: error.details } };
        });
};

export const submitApp = ({ params }) => {
    const { filingNumber } = params;
    const url = `/payments/submit/${filingNumber}`;
    return sendRequest({
        url,
        method: 'POST',
        prefix: '/backend',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code, details: error.details } };
        });
};
