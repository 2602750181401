import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Card from '../../../../components/Card';
import { t } from '../../../../providers';

const useStyles = makeStyles((theme) => ({
    fieldLabel: {
        color: theme.palette.text.label,
        fontSize: '10px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: 'normal',
    },
    fieldValue: {
        color: theme.palette.text.value,
        fontSize: '12px',
        letterSpacing: 'normal',
        marginBottom: '10px',
    },
}));

const AccountTypeCard = ({ initialValues }) => {
    const classes = useStyles();
    const isRepresentative = initialValues.personType === 'Representative';
    const accountTypeLabel = isRepresentative ? 'type.representative' : 'type.applicant';
    const isPatentAttorney = isRepresentative && initialValues.patentAttorneyId;
    return (
        <Card title={'user.account.type.title'} canEdit={false} cardId={'profile_account_type_card'}>
            <Typography className={classes.fieldLabel}>{t('account.type')}</Typography>
            <Typography className={classes.fieldValue}>{(initialValues && t(initialValues.personType)) || '-'}</Typography>
            <Typography className={classes.fieldLabel}>{t(accountTypeLabel)}</Typography>
            {!isPatentAttorney && (
                <Typography className={classes.fieldValue}>{(initialValues && t(`legal.type.${initialValues.accountType}`)) || '-'}</Typography>
            )}
            {isPatentAttorney && (
                <React.Fragment>
                    <Typography className={classes.fieldValue}>{t('patent.attorney')}</Typography>
                    <Typography className={classes.fieldLabel}>{t('patent.attorney.id')}</Typography>
                    <Typography className={classes.fieldValue}>{(initialValues && initialValues.patentAttorneyId) || '-'}</Typography>
                </React.Fragment>
            )}
        </Card>
    );
};

export default AccountTypeCard;
