import React, {useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import CustomPagination from '../../components/Pagination/CustomPagination';
import WarningModal from '../../components/WarningModal';
import {t} from '../../providers';
import {me as meSlice} from '../AuthenticatedPage/authenticationSlice';
import SignaturesListTable from './components/SignaturesListTable';
import {SLICE_NAME} from './constants';
import {error as errorSlice, list, loading as loadingSlice} from './signaturesSlice';
import {signaturesParserList} from './utils';

const useStyles = makeStyles((_theme) => ({
    container: {
        width: '100%',
        height: '100%',
        maxWidth: 'calc(100% - 236px)',
        '@media (max-width:1100px)': {
            minWidth: '77%',
            maxWidth: 'unset',
        },
    },
}));

const TRADEMARKS = 'trademark';
const DESIGNS = 'design';

const ASC = 'ASC';
const DESC = 'DESC';
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_SORT_COLUMN = 'eserviceCode';
const DEFAULT_SORT_TYPE = ASC;

const SignaturesPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useSelector(loadingSlice);
    const error = useSelector(errorSlice);
    const signatures = useSelector(list);
    const me = useSelector(meSlice);

    const {impersonatedUser: readOnly} = me || {};

    const [sortProps, setSortProps] = useState({sortColumn: DEFAULT_SORT_COLUMN, sortType: DEFAULT_SORT_TYPE});

    const [signaturesList, setSignaturesList] = useState([]);
    const [dialog, setDialog] = useState({
        visibility: false,
        message: '',
        content: '',
        extraContent: '',
        onConfirmation: () => {
        },
        confirmButtonText: '',
        cancelButtonText: '',
        onCancel: () => {
        },
        enableClickOutside: true,
    });

    const [totalListElements, setTotalListElements] = useState(0);
    const [number, setNumber] = useState(0);
    const [size, setSize] = useState(DEFAULT_PAGE_SIZE);

    const handleCloseDialog = () => {
        setDialog({
            ...dialog,
            visibility: false,
            message: '',
            content: '',
            extraContent: '',
            onConfirmation: () => {
            },
            confirmButtonText: '',
            cancelButtonText: '',
            onCancel: () => {
            },
        });
        if (error) dispatch({type: `${SLICE_NAME}/error`, payload: false});
    };

    const downloadDraftReceipt = (appNumber) => {
        dispatch({type: `${SLICE_NAME}/downloadDraftReceipt`, payload: {params: {appNumber}}});
    };

    const handleDeleteApplication = (filingNumber) => {
        const params = {filingNumber, sortProps};
        dispatch({type: `${SLICE_NAME}/deleteApplication`, payload: {params}});
        handleCloseDialog();
    };

    const deleteApplication = (appNumber) => {
        setDialog({
            ...dialog,
            visibility: true,
            message: t('delete.application'),
            onConfirmation: () => handleDeleteApplication(appNumber),
            confirmButtonText: t('delete'),
            cancelButtonText: t('cancel'),
            onCancel: handleCloseDialog,
        });
    };

    const handleSigningApplication = (filingNumber) => {
        setDialog({
            ...dialog,
            visibility: true,
            message: t('sign.application.information'),
            content: t('sign.application.content'),
            extraContent: t('sign.application.extraContent'),
            onConfirmation: () => handleSigning(filingNumber),
            confirmButtonText: t('proceed.to.sign'),
            cancelButtonText: t('cancel'),
            onCancel: handleCloseDialog,
        });
    };

    const handleSigning = (filingNumber) => {
        dispatch({type: `${SLICE_NAME}/signApplication`, payload: {filingNumber}});
        handleCloseDialog();
    };

    const setSorting = (sortColumn) => {
        const isSameKey = sortColumn === sortProps.sortColumn;
        const oppositeSort = sortProps.sortType === ASC ? DESC : ASC;
        const sortType = !isSameKey ? ASC : oppositeSort;
        setSortProps({sortColumn, sortType});
    };

    const handleChangePage = (event, newPage) => {
        event.preventDefault();
        if (newPage !== number) {
            setNumber(newPage);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        const pageSize = event.target.value;
        if (size !== pageSize) {
            setSize(pageSize);
            setNumber(0);
        }
    };

    useEffect(() => {
        const params = {...sortProps};
        dispatch({type: `${SLICE_NAME}/requestSignatures`, payload: {params}});
    }, [dispatch, sortProps]);

    useEffect(() => {
        if (signatures && signatures.length > 0 && !readOnly) {
            let toPush = [];
            signatures.map((signature) => {
                toPush.push(signature.number);
            });
            setTotalListElements(toPush.length);
        }
    }, [signatures]);

    useEffect(() => {
        if (!!error) {
            let messageModal;
            if (error.code === 403) {
                messageModal = t(error.description, {username: error.details.username});
            } else {
                if (error.externalError) messageModal = `${t(error.message)} ${error.externalError}`;
                else messageModal = t(error);
            }

            setDialog({
                visibility: true,
                message: messageModal,
                onConfirmation: handleCloseDialog,
                confirmButtonText: t('ok'),
                cancelButtonText: '',
                onCancel: handleCloseDialog,
            });
        }
    }, [error]);

    useEffect(() => {
        const signsList = signaturesParserList(
            signatures || [],
            downloadDraftReceipt,
            deleteApplication,
            handleSigningApplication,
            readOnly,
            true
        );
        setSignaturesList(signsList);
        setTotalListElements(signsList.data.length);
    }, [signatures]);

    const customPagination = (
        <CustomPagination
            defaultPageSize={DEFAULT_PAGE_SIZE}
            totalElements={totalListElements}
            size={size || DEFAULT_PAGE_SIZE}
            page={number || DEFAULT_PAGE_NUMBER}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            paginationId={'signatures'}
        />
    );

    return (
        <div className={classes.container}>
            {/* data display */}
            <SignaturesListTable
                loading={loading}
                setSorting={setSorting}
                sortProps={sortProps}
                signaturesList={signaturesList}
                size={size || DEFAULT_PAGE_SIZE}
                number={number}
                customPagination={customPagination}
                readOnly={readOnly}
            />
            {/* dialog */}
            <WarningModal
                visibility={dialog.visibility}
                message={dialog.message}
                content={dialog.content}
                extraContent={dialog.extraContent}
                onConfirmation={dialog.onConfirmation}
                confirmButtonText={dialog.confirmButtonText}
                cancelButtonText={dialog.cancelButtonText}
                onCancel={dialog.onCancel}
                enableClickOutside={dialog.enableClickOutside}
            />
        </div>
    );
};

export default SignaturesPage;
