const getLocalStorage = (params) => {
    let result = '';
    try {
        result = JSON.parse(localStorage.getItem(params));
    } catch (e) {
        result = null;
    }
    return result;
};

const getLocalStorageNoParse = (params) => {
    return localStorage.getItem(params);
};

const setLocalStorage = (params) => {
    params.forEach((param) => {
        localStorage.setItem(param.item, param.value);
    });
};

const deleteLocalStorage = (params) => {
    params.forEach((param) => {
        localStorage.removeItem(param);
    });
};

const setCookie = (cName, cValue, expDays) => {
    let date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/';
};

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

const updateCookie = (variable, value) => {
    document.cookie = variable + '=' + value + ';';
};

const deleteCookie = (name) => (document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;');

export { getLocalStorage, getLocalStorageNoParse, setLocalStorage, deleteLocalStorage, setCookie, getCookie, updateCookie, deleteCookie };
