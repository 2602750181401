const filters = {
    eservicesTypes: [
        {
            globalsKey: 'trademark',
            filterid: 'TRADEMARK',
            name: 'trademark',
            placeholder: 'trademarks',
            filterOptions: [],
            multiple: true,
        },
        {
            globalsKey: 'design',
            filterid: 'DESIGN',
            name: 'design',
            placeholder: 'designs',
            filterOptions: [],
            multiple: true,
        },
    ],
};

const getDropDownFilters = (category) => filters[category] || [];

export default { getDropDownFilters };
