import { all } from 'redux-saga/effects';

import {
    registerAccount,
    requestForgotPassword,
    requestResetPassword,
    requestValidateToken,
    sendNewVerificationCode,
    validateCredentials,
    verifyAccount,
    getUserInfo,
    impersonatedUserLogout,
    getEgovAuthenticationData,
    verifyUsernameChange,
    requestUsernameChangeNewVerificationCode,
} from '../pages/AuthenticatedPage/model';
import {
    getCorrespondenceDetails,
    newDraftMessage,
    deleteDraftCorrespondence,
    sendDraftCorrespondence,
    updateDraftCorrespondence,
    downloadIncomingMessage,
    downloadOutgoingMessage,
    uploadAttachmentCorrespondence,
    deleteAttachmentCorrespondence,
    downloadAttachmentCorrespondence,
    downloadAttachmentDraftCorrespondence,
} from '../pages/CorrespondenceDetails/model';
import {
    getCorrespondence,
    updateParametersCorr,
    getNewCorrespondencesCount,
    getExpiringCorrespondences,
    deleteDraftCorrespondencePage,
    downloadOutgoingDraft,
    updateMessageImportanceSaga,
    updateMessageStatusSaga,
} from '../pages/CorrespondencePage/model';
import {
    cancelUsernameChangeSaga,
    createChildAccount,
    getAccountDetails,
    updateLanguage,
    updatePersonType,
    changePassword,
    updateAccountDetails,
    getChildAccounts,
    deactivateChildAccount,
    editChildAccount,
    activateChildAccount,
    confirmDeactivateParentAccount,
    deactivateParentAccount,
    lockAccountDetails,
} from '../pages/MyAccountPage/model';
import {
    getMyApplications,
    getApplicationReceipt,
    getMyApplicationsExport,
    updateNote,
    lockNote,
    getDuplicateApplication,
    getEserviceValidation,
    getApplicationPaymentInvoice,
    getApplicationsFilters,
    getHomepageApplicationCount,
    getWaitingForSignatureApplicationCount,
    getWaitingForPaymentApplicationCount,
} from '../pages/MyApplicationsPage/model';
import {
    getDraftApplications,
    deleteDraftApplication,
    getMyDraftsExport,
    resumeDraftApplication,
    lockDraftApplication,
    unlockDraftApplication,
} from '../pages/MyDraftsPage/model';
import { getPaymentHistory, downloadPaymentInvoice } from '../pages/PaymentHistory/model';
import {
    getShoppingCarts,
    deleteApplicationPayments,
    submitWithoutPayment,
    downloadDraftReceiptPayments,
    payApplications,
} from '../pages/PaymentsPage/model';
import {
    getSignatures,
    signApplication,
    deleteApplication,
    downloadDraftReceipt,
} from '../pages/SignaturesPage/model';
import { login, logout, requestLogoutRedirect } from '../pages/SSOPage/model';
import { fetchLiterals, fetchCountries, fetchGlobals, submitUserFeedback } from './appModel';
// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        activateChildAccount(),
        cancelUsernameChangeSaga(),
        changePassword(),
        confirmDeactivateParentAccount(),
        createChildAccount(),
        deactivateChildAccount(),
        deactivateParentAccount(),
        editChildAccount(),
        getAccountDetails(),
        getChildAccounts(),
        getUserInfo(),
        fetchCountries(),
        fetchGlobals(),
        fetchLiterals(),
        submitUserFeedback(),
        login(),
        logout(),
        requestForgotPassword(),
        registerAccount(),
        requestLogoutRedirect(),
        requestResetPassword(),
        requestValidateToken(),
        sendNewVerificationCode(),
        updateAccountDetails(),
        updateLanguage(),
        updatePersonType(),
        lockAccountDetails(),
        validateCredentials(),
        verifyAccount(),
        getEgovAuthenticationData(),
        verifyUsernameChange(),
        requestUsernameChangeNewVerificationCode(),
        getMyApplications(),
        getApplicationReceipt(),
        getMyApplicationsExport(),
        updateNote(),
        lockNote(),
        getDuplicateApplication(),
        getEserviceValidation(),
        getApplicationPaymentInvoice(),
        getApplicationsFilters(),
        getHomepageApplicationCount(),
        getWaitingForSignatureApplicationCount(),
        getWaitingForPaymentApplicationCount(),
        getDraftApplications(),
        deleteDraftApplication(),
        resumeDraftApplication(),
        getMyDraftsExport(),
        lockDraftApplication(),
        unlockDraftApplication(),
        getCorrespondence(),
        getNewCorrespondencesCount(),
        getExpiringCorrespondences(),
        updateParametersCorr(),
        getCorrespondenceDetails(),
        newDraftMessage(),
        deleteDraftCorrespondence(),
        sendDraftCorrespondence(),
        updateDraftCorrespondence(),
        downloadIncomingMessage(),
        downloadOutgoingMessage(),
        uploadAttachmentCorrespondence(),
        updateMessageImportanceSaga(),
        updateMessageStatusSaga(),
        getSignatures(),
        signApplication(),
        deleteApplication(),
        downloadDraftReceipt(),
        deleteAttachmentCorrespondence(),
        downloadAttachmentCorrespondence(),
        downloadAttachmentDraftCorrespondence(),
        getShoppingCarts(),
        deleteApplicationPayments(),
        submitWithoutPayment(),
        downloadDraftReceiptPayments(),
        payApplications(),
        deleteDraftCorrespondencePage(),
        downloadOutgoingDraft(),
        getPaymentHistory(),
        downloadPaymentInvoice(),
        impersonatedUserLogout(),
    ]);
}
