import React from 'react';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { ReactComponent as CheckIcon } from '../..//assets/images/done_24px.svg';
import { ReactComponent as CaretDouble } from '../../assets/images/caretDouble.svg';
import { ReactComponent as DropDownArrow } from '../../assets/images/unfold_more_24px.svg';
import { t } from '../../providers';

export const customHeaderMultiRangePicker = ({ classes, handleDropdownChange, dateType = '', dropdownOptions, ranges, clearSelectedDate }) => {
    const selectedDateTypeLabel = dropdownOptions.find((option) => {
        const checkedDateType = typeof dateType === 'object' ? dateType.key : dateType;
        return option.key === checkedDateType;
    });

    return (
        <div className={classes.datepickerCustomHeader}>
            <Select
                className={classes.textInput}
                displayEmpty
                label={t('select.please')}
                onChange={(e) => handleDropdownChange(e.target.value)}
                value={typeof dateType === 'object' ? dateType.key ?? '' : dateType}
                IconComponent={DropDownArrow}>
                <MenuItem value="" disabled>
                    {t('select.date.type')}
                </MenuItem>
                {dropdownOptions.map((option, idx) => {
                    const selected = ranges && ranges.some((range) => range.dateType === option.key);
                    return (
                        <MenuItem key={`${option.key}_${idx}`} value={option.key} className={selected ? classes.selectedDate : ''}>
                            <div className={classes.dateOption}>{t(option.label)}</div>
                            {selected && <CheckIcon />}
                        </MenuItem>
                    );
                })}
            </Select>
            {selectedDateTypeLabel && (
                <Button className={classes.clearButton} variant="contained" onClick={clearSelectedDate} id={'clearSelectedDate'}>
                    {`${t(selectedDateTypeLabel.label + '.clear')}`}
                </Button>
            )}
        </div>
    );
};

export const customEndAdornmentMultiRangePicker = ({ ranges, classes }) => {
    const selectedRangeLength = (ranges || []).reduce((acc, cur) => {
        return !!cur.dateType && !!cur.startDate && !!cur.endDate ? acc + 1 : acc;
    }, 0);
    return !!selectedRangeLength ? (
        <React.Fragment>
            <div className={classes.valueWithChip}>
                <Chip className={classes.chip} label={selectedRangeLength} />
            </div>

            <InputAdornment position="end" className={classes.doubleCarretIcon}>
                <CaretDouble />
            </InputAdornment>
        </React.Fragment>
    ) : null;
};
