import React, {useEffect, useState} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import {makeStyles} from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import {platformUrl as platformUrlSlice, platformPaymentData as platformPaymentDataSlice } from '../../../paymentsSlice';

import ApplicationsTableClientSide from '../../../../../components/ApplicationsTableClientSide';
import Card from '../../../../../components/Card';
import ErrorInput from '../../../../../components/ErrorInput';
import PageTitle from '../../../../../components/PageTitle';
import {t} from '../../../../../providers';
import Typography from "@material-ui/core/Typography";
import inputsMaxChars from "../../../../../app/inputsMaxChars";
import {freeOfInvalidChars, isEmail} from "../../../../../common/utils/service";

const useStyles = makeStyles((theme) => ({
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width:1100px)': {
            display: 'block',
        },
    },
    cardTable: {
        marginRight: '20px',
        '@media (max-width:960px)': {
            marginRight: 'unset',
        },
    },
    tableActionsSummary: {
        color: theme.palette.text.detailsGrey,
        fontSize: theme.typography.fontSize,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '&  span': {
            padding: '5px',
        },
        '@media (max-width:700px)': {
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
    },
    totalCost: {
        fontWeight: theme.typography.fontWeightBold,
    },
    inputWrapper: {
        marginTop: '30px',
    },
    inputItem: {
        marginBottom: '10px',
        flex: '1 1 auto',
    },
    errorInput: {
        marginTop: '5px',
    },
    textInput: {
        margin: '10px 0',
    },
    selectWidth: {
        '@media (max-width:960px)': {
            width: '-webkit-fill-available',
        },
    },
}));

const ProceedToPaymentTable = ({
                                   loading,
                                   summaryShoppingCartList,
                                   selectedApplications,
                                   setSorting,
                                   sortProps,
                                   totalCost,
                                   onPay,
                                   onCancelProceedToPay,
                                   readOnly,
                               }) => {
    const classes = useStyles();
    const platformUrl = useSelector(platformUrlSlice);
    const platformPaymentData = useSelector(platformPaymentDataSlice);
    const [paymentReference, setPaymentReference] = useState({value: '', errorMessage: null});
    const [additionalEmail, setAdditionalEmail] = useState({value: '', isValid: true, isDirty: false});

    const handlePaymentReference = (event) => {
        const value = event.target.value;
        let error = null;
        if (!freeOfInvalidChars(value)) error = 'invalid.characters.error';
        if (value.length <= 200) setPaymentReference({value: event.target.value, errorMessage: error});
    };

    const handleAdditionalEmail = (event) => {
        const value = event.target.value;
        if (!inputsMaxChars.email || value.length <= inputsMaxChars.email) {
            const isValid = !value || isEmail(value);
            setAdditionalEmail({value: event.target.value, isValid, isDirty: true});
        }
    };

    const handlePay = (event) => {
        event.preventDefault();
        if (!additionalEmail.isValid) return;
        let email = additionalEmail.value;
        onPay(paymentReference.value, email);
    };

    useEffect(() => {
        if (!!platformUrl && !!platformPaymentData) {
            document.getElementById('paymentForm').submit();
        }
    }, [platformUrl, platformPaymentData]);


    const summaryShoppingCartsTable =
        !!Object.keys(summaryShoppingCartList).length && !loading ? (
            <ApplicationsTableClientSide
                setSorting={setSorting}
                sortBy={sortProps.sortColumn}
                sortType={sortProps.sortType}
                applications={summaryShoppingCartList}
                tableId={'applicationsForPaymentTable'}
                size={selectedApplications.length}
                page={0}
                noAvailableDataLabel={t('no.signatures')}
            />
        ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress/>
            </Box>
        );

    return (
        <React.Fragment>
            {/* title */}
            <div className={classes.title}>
                <div>
                    <PageTitle details={t('shopping.carts.summary')}/>
                </div>
            </div>
            <div className={classes.cardTable}>
                <Card canEdit={false}>
                    {summaryShoppingCartsTable}
                    <Grid container direction={'column'} className={classes.inputWrapper}>
                        <Grid item xs sm md className={classes.inputItem}>
                            <InputLabel
                                htmlFor={'paymentReference'}
                                error={!!paymentReference.errorMessage}
                                className={classes.fieldLabel}>
                                {t('payment.reference')}
                            </InputLabel>
                            <Input
                                id={'paymentReference'}
                                className={classes.textInput}
                                placeholder={t('payment.reference.description')}
                                type={'text'}
                                name={'paymentReference'}
                                onChange={handlePaymentReference}
                                value={paymentReference.value}
                                error={!!paymentReference.errorMessage}
                            />

                            {!!paymentReference.errorMessage && (
                                <ErrorInput
                                    errorMessage={t(paymentReference.errorMessage)}
                                    classes={`${classes.inputError}`}
                                />
                            )}
                        </Grid>
                        <Grid item xs sm md className={classes.inputItem}>
                            <InputLabel
                                htmlFor={'additionalEmail'}
                                error={additionalEmail.isDirty && !additionalEmail.isValid}
                                className={classes.fieldLabel}>
                                {t('payment.additional.email')}
                            </InputLabel>
                            <Input
                                id={'additionalEmail'}
                                className={classes.textInput}
                                placeholder={t('payment.additional.email.description')}
                                type={'email'}
                                name={'additionalEmail'}
                                onChange={handleAdditionalEmail}
                                value={additionalEmail.value}
                                error={additionalEmail.isDirty && !additionalEmail.isValid}
                            />
                            {additionalEmail.isDirty && !additionalEmail.isValid && (
                                <Typography variant={'body2'} color={'error'} className={classes.inputError}>
                                    {t('invalid.email.format')}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <div className={classes.tableActionsSummary}>
                        <span>{t('applications.selected', {num: selectedApplications.length})}</span>
                        {!!selectedApplications.length && <span
                            className={classes.totalCost}>{`${t('total.cost')}: ${(totalCost / 100).toFixed(2)} €`}</span>}
                    </div>
                    <div className={classes.tableActionsSummary}>
                        <span>
                            <Button id={'cancel-btn'} variant={'outlined'} onClick={onCancelProceedToPay}>
                                {t('cancel')}
                            </Button>
                        </span>
                        <span>
                            <Button id={'pay-btn'} variant={'contained'}
                                    disabled={!selectedApplications.length || readOnly} onClick={handlePay}>
                                {t('pay.btn')}
                            </Button>
                        </span>
                    </div>
                </Card>
            </div>
            <form id="paymentForm" noValidate method="post" action={platformUrl}>
                <input type="hidden" id="data" name="data" value={!!platformPaymentData ? platformPaymentData : ''}/>
            </form>
        </React.Fragment>
    );
};

export default ProceedToPaymentTable;
