import { createSlice } from '@reduxjs/toolkit';

import { SLICE_NAME } from './constants';

export const slice = createSlice({
    name: SLICE_NAME,
    initialState: {
        list: null,
        error: null,
        loading: false,
    },
    reducers: {
        list: (state, action) => {
            state.list = action.payload;
        },
        loading: (state, action) => {
            state.loading = action.payload;
        },
        error: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const list = (state) => state[SLICE_NAME].list;
export const loading = (state) => state[SLICE_NAME].loading;
export const error = (state) => state[SLICE_NAME].error;
export default slice.reducer;
