export default {
    trademark: {
        services: [
            {
                title: 'e.filing',
                description: 'file.application',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'tm.efiling.url',
            },
            {
                title: 'e.services',
                description: 'file.eService.trademarks',
                externalPath: false,
                requiredProfileDetails: false,
                path: 'trademark/e-services',
            },
            {
                title: 'search.trademark',
                description: 'search.trademark.IPO',
                externalPath: true,
                requiredProfileDetails: false,
                path: 'search.trademark.url',
            },
            {
                title: 'search.national.tm',
                description: 'search.national.trademark',
                externalPath: true,
                requiredProfileDetails: false,
                path: 'search.national.trademark.url',
            },
            {
                title: 'search.eu.tm',
                description: 'search.eu.trademark',
                externalPath: true,
                requiredProfileDetails: false,
                path: 'search.eu.trademark.url',
            },
            {
                title: 'search.madrid',
                description: 'search.madrid.desc',
                externalPath: true,
                requiredProfileDetails: false,
                path: 'search.madrid.url',
            },
        ],
        eServices: [
            {
                title: 'generic.request',
                category: 'other',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'tm.generic.url',
            },
            {
                title: 'renewal',
                category: 'extend',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'tm.renewal.url',
            },
            {
                title: 'change.owner.details',
                category: 'change',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'tm.change.url',
            },
            {
                title: 'transfer',
                category: 'transfer',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'tm.transfer.url',
            },
            {
                title: 'limit.goods.services',
                category: 'change',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'tm.limitation.url',
            },
            {
                title: 'division.trademark',
                category: 'change',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'tm.division.url',
            },
            {
                title: 'register.licence',
                category: 'transfer',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'tm.licence.url',
            },
            {
                title: 'withdrawal.surrender',
                category: 'cancel',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'tm.withdrawal.url',
            },
            {
                title: 'objection',
                category: 'protect',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'tm.objection.url',
            },
            {
                title: 'opposition',
                category: 'protect',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'tm.opposition.url',
            },
            {
                title: 'invalidity',
                category: 'cancel',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'tm.invalidity.url',
            },
            {
                title: 'revocation',
                category: 'cancel',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'tm.revocation.url',
            },
            {
                title: 'general',
                category: 'other',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'tm.general.url',
            },
            {
                title: 'general.appeal',
                category: 'other',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'appeal.general.url',
            },
        ],
        categories: ['extend', 'protect', 'cancel', 'change', 'transfer', 'other'],
    },
    design: {
        services: [
            {
                title: 'e.filing',
                description: 'file.design',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'ds.efiling.url',
            },
            {
                title: 'e.services',
                description: 'file.eService.designs',
                externalPath: false,
                requiredProfileDetails: false,
                path: 'design/e-services',
            },
            {
                title: 'search.design',
                description: 'search.design.IPO',
                externalPath: true,
                requiredProfileDetails: false,
                path: 'search.design.url',
            },
            {
                title: 'search.national.ds',
                description: 'search.national.design',
                externalPath: true,
                requiredProfileDetails: false,
                path: 'search.national.design.url',
            },
            {
                title: 'search.eu.ds',
                description: 'search.eu.design',
                externalPath: true,
                requiredProfileDetails: false,
                path: 'search.eu.design.url',
            },
            {
                title: 'search.hague',
                description: 'search.hague.desc',
                externalPath: true,
                requiredProfileDetails: false,
                path: 'search.hague.url',
            },
        ],
        eServices: [
            {
                title: 'generic.request',
                category: 'other',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'ds.generic.url',
            },
            {
                title: 'renewal',
                category: 'extend',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'ds.renewal.url',
            },
            {
                title: 'change.owner.details',
                category: 'change',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'ds.change.url',
            },
            {
                title: 'transfer',
                category: 'transfer',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'ds.transfer.url',
            },
            {
                title: 'general',
                category: 'other',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'ds.general.url',
            },
            {
                title: 'general.appeal',
                category: 'other',
                externalPath: true,
                requiredProfileDetails: true,
                path: 'appeal.general.url',
            },
        ],
        categories: ['extend', 'cancel', 'change', 'transfer', 'other'],
    },
};
