import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { t } from '../../providers';

const useStyles = makeStyles((theme) => ({
    dialogOverride: {
        '& .MuiInputBase-root': {
            border: '1px solid #E7E7ED',
            borderRadius: '4px',
            height: 'unset',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '400',
        },
        '& .Mui-error': {
            borderColor: theme.palette.error.main,
        },
    },
    noteDialogTitle: {
        color: '#000000',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '20px',
        padding: '10px 0 20px',
    },
    closeButton: {
        cursor: 'pointer',
        fill: '#424242',
    },
    dialogContent: {
        wordBreak: 'break-all',
        padding: '8px 10px 18px',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '400',
        maxHeight: '115px',
    },
}));

const NoteModal = ({ title, noteValue, open, onClose, onEdit, onDelete, onSave, editMode, maxCharsAllowed, readOnly, loading }) => {
    const classes = useStyles();

    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(noteValue);
    }, [noteValue]);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const cancel = () => {
        setValue(noteValue || '');
        onClose();
    };

    const triggerDelete = () => {
        setValue(noteValue || '');
        onDelete();
    };

    const noteNotification = t('note.maxChars.notification', { maxChars: maxCharsAllowed }, 'returnString');

    const modalContent = (
        <React.Fragment>
            <DialogContent className={classes.dialogContent}>
                {!editMode && !!noteValue ? (
                    <DialogContentText>{noteValue} </DialogContentText>
                ) : (
                    <React.Fragment>
                        <TextField
                            autoFocus
                            id="noteInput"
                            rows={4}
                            multiline
                            fullWidth
                            value={value || ''}
                            placeholder={noteNotification}
                            inputProps={{ maxLength: maxCharsAllowed }}
                            onChange={handleChange}
                            error={!!value && value.length > maxCharsAllowed}
                        />
                        {!!value && value.length > maxCharsAllowed && (
                            <Typography variant="body2" color="error">
                                {noteNotification}
                            </Typography>
                        )}
                    </React.Fragment>
                )}
            </DialogContent>

            <DialogActions>
                {editMode ? (
                    <Button variant="contained" onClick={() => onSave(value)} disabled={!!value && value.length > maxCharsAllowed}>
                        {t('save')}
                    </Button>
                ) : (
                    <Button variant="outlined" onClick={onEdit} disabled={readOnly}>
                        {t('edit')}
                    </Button>
                )}
                <Button onClick={triggerDelete} disabled={!noteValue || readOnly}>
                    {t('delete')}
                </Button>
            </DialogActions>
        </React.Fragment>
    );

    return (
        <Dialog open={open} aria-labelledby="note-dialog" className={classes.dialogOverride} onBackdropClick={cancel}>
            <div id="note-dialog-title" className={classes.noteDialogTitle}>
                <span>{title}</span>
                <CloseIcon className={classes.closeButton} onClick={cancel} />
            </div>
            {!loading ? (
                modalContent
            ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
        </Dialog>
    );
};

export default NoteModal;
