import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { globals as globalsSlice } from '../../../../app/globalsSlice';
import WarningModal from '../../../../components/WarningModal';
import { t } from '../../../../providers';
import { me as meSlice } from '../../../AuthenticatedPage/authenticationSlice';
import { details as detailsSlice, error as errorSlice } from '../../accountSlice';
import { SLICE_NAME } from '../../constants';
import AccountTypeCard from './AccountTypeCard';
import AddressCard from './AddressCard';
import ConfirmUpdateDialog from './ConfirmUpdateDialog';
import ContactInfoCard from './ContactInfoCard';
import UserDetailsCard from './UserDetailsCard';

const ProfileDetails = () => {
    const dispatch = useDispatch();
    const me = useSelector(meSlice);
    const { globals } = useSelector(globalsSlice);
    const details = useSelector(detailsSlice);
    const error = useSelector(errorSlice);
    const ipoName = (globals && globals.ipo && globals.ipo.name) || '';
    const { userType, authorities } = me || {};
    const hasAdministratorRole = userType === 'PARENT' || (authorities || []).includes('ROLE_ACCOUNT_ADMINISTRATOR');
    const isBusinessAccount = (!!details && details.accountTypeDetails.accountType === 'COMPANY');

    const [open, setOpen] = useState(false);
    const [updatePayload, setUpdatePayload] = useState(null);
    const [dialog, setDialog] = useState({
        title: '',
        visibility: false,
        message: '',
        onConfirmation: null,
        confirmButtonText: null,
        onCancel: null,
    });

    useEffect(() => {
        const { username } = me;
        if (!details) {
            dispatch({ type: `${SLICE_NAME}/getAccountDetails`, payload: { userName: username } });
        }
    }, [dispatch, details, me]);

    const saveDetails = (payload, fromCard) => {
        setUpdatePayload({ requestBody: payload, fromCard });
        setOpen(true);
    };

    const updateCard = () => {
        if (updatePayload) {
            dispatch({ type: `${SLICE_NAME}/updateAccountDetails`, payload: updatePayload });
        }
        setOpen(false);
    };

    const cancelUpdate = (event) => {
        event.preventDefault();
        setOpen(false);
    };

    const handleCloseDialog = () => {
        setDialog({ title: '', visibility: false, message: '', onConfirmation: null, confirmButtonText: null, onCancel: null });
        dispatch({ type: `${SLICE_NAME}/error`, payload: false });
    };

    const handleLockAccount = (fromCard) => {
        dispatch({ type: `${SLICE_NAME}/lockAccountDetails`, payload: { fromCard } });
    };

    const clearLockAccount = () => {
        dispatch({ type: `${SLICE_NAME}/accountLocked`, payload: null });
    };

    useEffect(() => {
        if (!!error) {
            setDialog({
                ...dialog,
                visibility: true,
                message: t(error.description, { username: error.details.username, message: error.details.message }),
                onConfirmation: handleCloseDialog,
                confirmButtonText: t('ok'),
            });
        }
    }, [error]);

    return (
        <React.Fragment>
            <AccountTypeCard initialValues={(details && details.accountTypeDetails) || {}} />
            <UserDetailsCard
                initialValues={details || {}}
                saveDetails={saveDetails}
                hasAdministratorRole={hasAdministratorRole && isBusinessAccount}
                handleLockAccount={handleLockAccount}
                clearLockAccount={clearLockAccount}
            />
            <AddressCard
                initialValues={details || {}}
                saveDetails={saveDetails}
                hasAdministratorRole={hasAdministratorRole}
                handleLockAccount={handleLockAccount}
                clearLockAccount={clearLockAccount}
                isBusinessAccount={isBusinessAccount}
            />
            <ContactInfoCard
                initialValues={details || {}}
                saveDetails={saveDetails}
                hasAdministratorRole={hasAdministratorRole}
                handleLockAccount={handleLockAccount}
                clearLockAccount={clearLockAccount}
            />
            <ConfirmUpdateDialog open={open} onAccept={updateCard} onCancel={cancelUpdate} ipoName={ipoName} />
            <WarningModal
                title={dialog.title}
                visibility={dialog.visibility}
                message={dialog.message}
                onConfirmation={dialog.onConfirmation}
                confirmButtonText={dialog.confirmButtonText}
                onCancel={dialog.onCancel}
            />
        </React.Fragment>
    );
};

export default ProfileDetails;
