import React from 'react';

import { t } from '../../providers';
import Button from "@material-ui/core/Button";

export const signaturesParserList = (
    signatures,
    downloadDraftReceipt,
    deleteApplication,
    handleSigningApplication,
    readOnly,
    enableSorting = false
) => {
    const content = signatures;

    let signaturesViewList = {
        headers: [
            { label: t('type'), code: 'eserviceCode', align: 'left', sortable: enableSorting },
            { label: t('user.reference'), code: 'userReference', align: 'left', sortable: enableSorting },
            { label: t('application.number'), code: 'associatedRight', align: 'left', sortable: enableSorting },
            { label: t('application.registration.number'), code: 'registrationNumber', align: 'left', sortable: false },
            { label: '', code: 'sign', align: 'left', sortable: false},
            { label: '', code: 'actions', align: 'left', sortable: false },
        ].filter((item) => typeof item === 'object'), //remove non-objects from headers
    };

    let dataList = [];

    // table/list data
    const parsedSignatures = (content || []).map((signature) => [
        {
            label: 'type',
            value: signature.type ? t(`app.type.${signature.type}`) : '',
        },
        {
            label: 'userReference',
            value: signature.userReference ? signature.userReference : '',
        },
        {
            label: 'applicationNumber',
            value: signature.applicationNumber ? signature.applicationNumber : '',
        },
        {
            label: 'registrationNumber',
            value: signature.registrationNumber ? signature.registrationNumber : '',
        },
        {
            label: 'signButton',
            value: <Button onClick={() => handleSigningApplication(signature.filingNumber)}>{t('sign.application')}</Button>,
        },
        {
            label: 'menuItems',
            value: [
                { label: t('draft.receipt'), action: () => downloadDraftReceipt(signature.filingNumber) },
                { label: t('delete'), action: () => deleteApplication(signature.filingNumber) },
            ].filter((item) => typeof item === 'object'),
        },
    ]);

    //remove non-objects from data
    dataList = parsedSignatures.map((sign) => sign.filter((item) => typeof item === 'object'));

    signaturesViewList = {
        ...signaturesViewList,
        data: dataList,
    };

    return signaturesViewList;
};
