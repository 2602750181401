import React, { useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';

import { globals as globalsSlice } from '../../../../app/globalsSlice';
import { ReactComponent as DropDownArrow } from '../../../../assets/images/unfold_more_24px.svg';
import Card from '../../../../components/Card';
import { t, getLocale } from '../../../../providers';
import { me as meSlice } from '../../../AuthenticatedPage/authenticationSlice';
import helpers from '../../../SSOPage/SSOHelpers';
import { loading as loadingSlice, requestResult, deactivateResult } from '../../accountSlice';
import { SLICE_NAME } from '../../constants';
import CannotDeactivateDialog from './CannotDeactivateDialog';
import ConfirmDeactivateDialog from './ConfirmDeactivateDialog';
import DeactivateSuccessDialog from './DeactivateSuccessDialog';

const useStyles = makeStyles((theme) => ({
    changePasswordButton: {
        // height: '2rem',
        minWidth: '10rem',
        borderRadius: '3px',
        marginLeft: '0',
        display: 'block',
    },
    buttonMargin: {
        marginBottom: '7.5rem',
    },
    title: {
        marginTop: '30px',
    },
    fieldLabel: {
        color: theme.palette.text.label,
        fontSize: '10px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: 'normal',
    },
    textInput: {
        height: '2rem',
        width: '12rem',
        borderRadius: '2px',
        marginBottom: '10px',
        '@media (max-width:600px)': {
            width: '9.5rem',
        },
    },
    checkbox: {
        marginLeft: '-6px',
        marginTop: '16px',
        marginBottom: '7.5rem',
        '& .MuiFormControlLabel-label': {
            color: theme.palette.text.label,
            fontSize: '14px',
            marginLeft: '4px',
            marginRight: '10px',
        },
        '& path': {
            fill: theme.palette.primary.main,
        },
    },
}));

const Preferences = () => {
    const classes = useStyles();

    const lang = getLocale();

    const dispatch = useDispatch();
    const me = useSelector(meSlice);
    const response = useSelector(requestResult);
    const deactivateResponse = useSelector(deactivateResult);
    const loading = useSelector(loadingSlice);
    const { globals } = useSelector(globalsSlice);
    const [language, setLanguage] = useState(lang);
    const [personType, setPersonType] = useState(false);
    const [cannotDeactivateOpen, setCannotDeactivateOpen] = useState(false);
    const [confirmDeactivateOpen, setConfirmDeactivateOpen] = useState(false);
    const [deactivateSuccessOpen, setDeactivateSuccessOpen] = useState(false);

    const { impersonatedUser: readOnly } = me || {};

    useEffect(() => {
        const { username } = me;
        if (username && (!me || !me.email)) dispatch({ type: `${SLICE_NAME}/getAccountDetails`, payload: { userName: username } });
        else {
            setLanguage(me.language);
            setPersonType(me.personType);
        }
    }, [dispatch, me]);

    useEffect(() => {
        if (response) {
            if (response.language && response.language !== language) {
                setLanguage(response.language);
                dispatch({ type: `${SLICE_NAME}/requestResult`, payload: null });
            }
            if (response.personType && response.personType !== personType) {
                setPersonType(response.personType);
                dispatch({ type: `${SLICE_NAME}/requestResult`, payload: null });
            }
        }
        return () => {
            dispatch({ type: `${SLICE_NAME}/requestResult`, payload: null });
        };
    }, [response, dispatch, language]);

    useEffect(() => {
        const { action, reason, valid } = deactivateResponse || {};
        if (!!deactivateResponse) {
            if (!valid && reason === 'ACTIVE_CHILD_ACCOUNTS') {
                setCannotDeactivateOpen(true);
            }
            if (valid && action === 'deactivate') {
                setConfirmDeactivateOpen(true);
            }
            if (valid && action === 'confirmDeactivate') {
                setDeactivateSuccessOpen(true);
            }
            dispatch({ type: `${SLICE_NAME}/deactivateResult`, payload: null });
        }
    }, [deactivateResponse, dispatch]);

    const deactivateAccount = (event) => {
        event.preventDefault();
        dispatch({ type: `${SLICE_NAME}/deactivateParentAccount`, payload: null });
    };

    const closeCannotDeactivateDialog = (event) => {
        event.preventDefault();
        setCannotDeactivateOpen(false);
    };

    const closeConfirmation = (event) => {
        event.preventDefault();
        setConfirmDeactivateOpen(false);
    };

    const deactivateMyAccount = (event) => {
        event.preventDefault();
        dispatch({ type: `${SLICE_NAME}/confirmDeactivateParentAccount`, payload: null });
        setConfirmDeactivateOpen(false);
    };

    const logout = (_event) => {
        helpers.logout();
    };

    const onSuccessDeactivate = (event) => {
        event.preventDefault();
        logout();
    };

    const updateLanguage = (event) => {
        const value = event.target.value;
        if (language === value) return;
        dispatch({ type: `${SLICE_NAME}/updateLanguage`, payload: { language: value } });
    };

    const updatePersonType = (event) => {
        const value = event.target.value;
        if (personType === value) return;
        dispatch({ type: `${SLICE_NAME}/updatePersonType`, payload: { personType: value } });
    };

    const languages = Object.keys(globals.supportedLanguages || {}).map((key) => globals.supportedLanguages[key]);
    const personTypes = [globals.account.applicant, globals.account.representative];

    const render = (
        <>
            <Card title={'user.profile.preferences.title'} canEdit={false} cardId={'preferences_card'}>
                <Typography className={[classes.fieldLabel, classes.title].join(' ')}>{t('preferred.language')}</Typography>
                <Select
                    className={classes.textInput}
                    displayEmpty
                    label={t('select.language')}
                    onChange={updateLanguage}
                    value={language}
                    IconComponent={DropDownArrow}>
                    <MenuItem value="" disabled>
                        {t('select.language')}
                    </MenuItem>
                    {languages.map(({ key, value, code }, idx) => (
                        <MenuItem key={`${key}_${idx}`} value={code}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
                <Typography className={classes.fieldLabel}>{t('preferred.account.type')}</Typography>
                <Select
                    className={classes.textInput}
                    displayEmpty
                    label={t('select.account.type')}
                    onChange={updatePersonType}
                    value={personType}
                    IconComponent={DropDownArrow}>
                    <MenuItem value="" disabled>
                        {t('select.account.type')}
                    </MenuItem>
                    {personTypes.map((type, idx) => (
                        <MenuItem key={idx} value={type}>
                            {t(type)}
                        </MenuItem>
                    ))}
                </Select>
            </Card>
            <CannotDeactivateDialog open={cannotDeactivateOpen} onAccept={closeCannotDeactivateDialog} />
            <ConfirmDeactivateDialog open={confirmDeactivateOpen} onAccept={deactivateMyAccount} onCancel={closeConfirmation} />
            <DeactivateSuccessDialog open={deactivateSuccessOpen} onAccept={onSuccessDeactivate} />
        </>
    );

    return loading ? <CircularProgress /> : render;
};

export default Preferences;
