export const encodeString = (s) => {
    return encodeURIComponent(s);
};

export const stringUrlToObject = (s) => {
    let objParams = {};
    for (const param of s
        .trim()
        .replace(/^[?#&]/, '')
        .split('&')) {
        if (param.indexOf('=') === -1) {
            return param;
        }

        let [key, value] = param.split('=');
        if (key) {
            objParams[key] = value;
        }
    }
    return objParams;
};

export const encodeGetParams = (queryObject) => {
    let returnStringParams = '';
    if (Object.keys(queryObject).length !== 0 && queryObject.constructor === Object) {
        returnStringParams =
            '?' +
            Object.entries(queryObject)
                .map((kv) => kv.map(encodeString).join('='))
                .join('&');
    } else {
        returnStringParams = queryObject;
    }

    return returnStringParams;
};

export const encodeGetParamsString = (s) => {
    return encodeGetParams(stringUrlToObject(s));
};

// Based on https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const body2formBody = (body) => {
    const formBody = Object.keys(body).map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`;
    });
    return formBody.join('&');
};

export const isEmail = (email) => emailRegexp.test(email);

const numberRegexp = /^[1-9]\d*$/;

export const isNumber = (number) => numberRegexp.test(number);

const validCharsRegexp = /^[!-~ ąčęėįšųūžĄČĘĖĮŠŲŪŽ]*$/;

export const freeOfInvalidChars = (value) => validCharsRegexp.test(value);

export const capitalize = (text) => {
    return text
        .toLowerCase()
        .trim()
        .split(' ')
        .map((i) => i[0].toUpperCase() + i.substr(1))
        .reduce((ac, i) => `${ac} ${i}`);
};

export const text2CamelCase = (text) => {
    return text
        .toLowerCase()
        .trim()
        .split(' ')
        .map((i, idx) => (idx > 0 ? i[0].toUpperCase() + i.substr(1) : i[0] + i.substr(1)))
        .reduce((cs, i) => `${cs}${i}`);
};

export const base64ToBlobPdf = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: 'application/pdf' });
};

export const userCan = (authorities, type) => authorities && authorities.includes(type);

export const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
};

export const downloadFile = (res) => {
    const filename = res.headers.get('Content-Disposition').split('filename=')[1].replace(/"/g, '');
    res.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
    });
};

export const saveDownloadedFile = (response) => {
    const filename = response.headers.get('Content-Disposition').split('filename=')[1].replace(/"/g, '');
    response.blob().then((blob) => {
        var data = new Blob([blob], {type: "application/pdf"});
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(data);
        a.download = filename;
        a.click();
    });
}

export const openPDFinNewTab = (res) => {
    res.blob().then((blob) => {
        const file = new Blob([blob], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);
        window.open(fileUrl, '_blank');
    });
};

export const numberWithDecimals = (num, decimals) => (Math.round(num * 100) / 100).toFixed(decimals);
