import React, { useEffect, useState } from 'react';

import { convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import MUIRichTextEditor from 'mui-rte';

const Editor = ({ data, label, controls, contentEditor, setContentHTML, setContentChanged, readOnly, uploadComponent }) => {
    const [content, setContent] = useState('');
    const Upload = () => uploadComponent;
    const onRTEChange = (event) => {
        let html = stateToHTML(event.getCurrentContent());
        setContentHTML(html === '<p><br></p>' ? '' : html);
        if (contentEditor !== html) setContentChanged(true);
    };

    useEffect(() => {
        if (data) {
            setContentHTML(data);
            const contentHTML = convertFromHTML(data);
            const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
            const content = JSON.stringify(convertToRaw(state));
            setContent(content);
        }
    }, [data]);

    return (
        <MUIRichTextEditor
            defaultValue={content}
            label={label}
            controls={controls.concat('custom-upload')}
            customControls={[
                {
                    name: 'custom-upload',
                    component: Upload,
                    type: 'block',
                },
            ]}
            onChange={onRTEChange}
            readOnly={readOnly}
        />
    );
};

export default Editor;
