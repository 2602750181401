import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';

import inputsMaxChars from '../../../../app/inputsMaxChars';
import ErrorInput from '../../../../components/ErrorInput';
import { t } from '../../../../providers';
import { isEmail } from '../../../../common/utils/service';
import { error as errorSlice, loading as loadingSlice, requestResult } from '../../accountSlice';
import { SLICE_NAME } from '../../constants';
import { initEgovLogin } from '../../../AuthenticatedPage/service';

const useStyles = makeStyles((theme) => ({
    dialogOverride: {
        color: 'black',
        '& .MuiDialog-paper': {
            padding: '6px',
            paddingTop: '14px',
            maxWidth: '500px',
        },
    },
    email: {
        color: theme.palette.text.mainBlue,
    },
    inputWrapper: {
        marginBottom: '1.5rem',
    },
    textInput: {
        height: '2.5rem',
        width: '14rem',
        marginTop: '10px',
        marginBottom: '10px',
        '@media (max-width:600px)': {
            width: '12.5rem',
        },
    },
    inputItem: {
        marginBottom: '10px',
        flex: '1 1 auto',
    },
    inputError: {
        marginTop: '5px',
    },
    fieldLabel: {
        color: theme.palette.text.label,
        fontSize: '10px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: 'normal',
    },
    actionButton: {
        minWidth: '66px',
    },
}));

const ChangeUsernameDialog = ({ open, closeDialog }) => {
    const classes = useStyles();
    const error = useSelector(errorSlice);
    const loading = useSelector(loadingSlice);
    const response = useSelector(requestResult);
    const dispatch = useDispatch();

    const [email, setEmail] = useState({ value: '', isValid: false });
    const [ticket, setTicket] = useState('');
    const [authenticationUrl, setAuthenticationUrl] = useState('');
    const [errorMessageKey, setErrorMessageKey] = useState(new URLSearchParams(window.location.search).get('errorMessageKey'));

    const handleCloseDialog = () => {
        /* reset input fields */
        setEmail({ value: '', isValid: false });
        /* clear account/error if there is error */
        if (!!error) 
            dispatch({ type: `${SLICE_NAME}/error`, payload: false });
        closeDialog();
    };

    useEffect(() => {
        if (response && response.ok && response.fromAction === 'requestUsernameChange') {
            handleCloseDialog();
        } else if (error && !!error.email){
            setEmail({ ...email, value: error.email});
        }
    }, [dispatch, response]);

    const handleEmailChange = (event) => {
        if (!inputsMaxChars.email || event.target.value.length <= inputsMaxChars.email) {
            const isValidEmail = isEmail(event.target.value);
            setEmail({ ...email, value: event.target.value, isDirty: true, isValid: isValidEmail});
        }
    };

    const submitEmail = (event) => {
        event.preventDefault();
        if(!!email.value && email.isValid){
            initEgovLogin({url: '/epaslaugos/auth/username-change', callbackUrl: callbackUrl, newUsername: email.value })
            .then(({ ticket, authenticationUrl, ...error }) => {
                if (!!ticket && !!authenticationUrl) {
                    setTicket(ticket);
                    setAuthenticationUrl(authenticationUrl);
                    document.getElementById('authentication_init_form').submit();
                } else if (!!error && error.message){
                    dispatch({ type: `${SLICE_NAME}/error`, payload: {description : error.message, code : error.code}});
                } else {
                    setErrorMessageKey('service.unavailable');
                }
            });
        }
    };

    const callbackUrl = window.location.protocol + '//' + window.location.host + '/epaslaugos/auth/callback/username-change';

    return (
        <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="reset-account-accept-dialog" className={classes.dialogOverride}>
            <form onSubmit={submitEmail} noValidate>
                <DialogTitle id="form-dialog-title">{t('update.email.dialog')}</DialogTitle>
                <DialogContent>
                    <Grid container direction="column" className={classes.inputWrapper}>
                         <Grid item xs sm md className={classes.inputItem}>
                            <InputLabel
                                htmlFor='email'
                                error={(email.isDirty && !email.value)}
                                className={classes.fieldLabel}>
                                {t('new.email')}*
                            </InputLabel>
                            <Input
                                id='email'
                                className={classes.textInput}
                                placeholder={t('type.login.email')}
                                type={'text'}
                                name='email'
                                fullWidth
                                onChange={handleEmailChange}
                                value={email.value}
                                error={(email.isDirty && !email.value)}
                            />
                            {email.isDirty && !email.isValid && (
                                <ErrorInput errorMessage= {t(!!email.value ? 'invalid.email.format' : 'required.field')} 
                                            classes={`${classes.inputError}`} />
                            )}
                        </Grid>
                        {!!error && (
                            <Grid item xs sm md>
                                <Typography color="error" className={classes.rPInfoMessage}>
                                    {t(error.description)}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {!loading ? (
                        <>
                            <Button className={classes.actionButton} variant="contained" onClick={handleCloseDialog} type="button">
                                {t('cancel')}
                            </Button>
                            <Button className={classes.actionButton} variant="outlined" onClick={submitEmail}>
                                {t('save')}
                            </Button>
                        </>
                    ) : (
                        <CircularProgress />
                    )}
                </DialogActions>
            </form>
            <form id="authentication_init_form" noValidate method="post" action={authenticationUrl}>
                <input type="hidden" id="ticket" name="ticket" value={ticket} />
            </form>
            <Grid container direction="column">
                <Typography color="error">{t(errorMessageKey)}</Typography>
            </Grid>            
        </Dialog>
    );
};

export default ChangeUsernameDialog;
