import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

import {ReactComponent as NoteIcon} from '../../assets/images/note_24px.svg';
import {ReactComponent as EmptyNoteIcon} from '../../assets/images/note_empty_24px.svg';
import {capitalize} from '../../common/utils/service';
import ApplicationImage from '../../components/ApplicationImage';
import NoImage from '../../components/NoImage';
import {t, bareDateTimeFormat} from '../../providers';

const ALL_IP_RIGHTS = 'all';
const TRADEMARKS = 'trademark';
const DESIGNS = 'design';
const ESERVICES = 'eservice';

const handleTrademarkNiceClassification = (classification) =>
    classification.split(',').map((item, idx) => (
        <span key={idx} className={'classification-item'}>
            {item}
        </span>
    ));

const handleDesignLocarnos = (locarnos) =>
    Object.values(locarnos).map((item, idx) => (
        <React.Fragment key={idx}>
            {item.mainClass && item.subClass ?
                <span className={'classification-item'}>{`${item.mainClass}.${item.subClass}`}</span> : ''}
        </React.Fragment>
    ));

const valueWithTooltip = (text) => (
    <Tooltip arrow title={text} placement={'left'}>
        <span>{text}</span>
    </Tooltip>
);

const checkboxLabel = (checkAll, indeterminate, checked, onChange, readOnly, id) => {
    const onChangeValue = !readOnly ? onChange : () => {};
    return (
        <Checkbox
            indeterminate={checkAll ? indeterminate : null}
            checked={checked}
            onChange={onChangeValue}
            inputProps={{'aria-label': checkAll ? 'select all applications' : `select ${id}`}}
            disabled={readOnly}
        />
    );
};

export const applicationsParserGrid = (
    applications,
    category,
    getApplicationReceipt,
    checkCorrespondence,
    getPaymentInvoice,
    handleNoteModal,
    duplicateApplication,
    handleSelectApplication,
    readOnly,
    checkBoxDisabled = false,
    selectedApplications = [],
    handleInitiateEservice = null,
    hasCorrespondenceRole = true
) => {
    const {content} = applications;
    const isSelected = (number) => selectedApplications.indexOf(number) !== -1;
    const enableCorrespondence = hasCorrespondenceRole && !category.includes(ESERVICES);

    return (content || []).map((app) => {
        return {
            checkbox:
                !checkBoxDisabled &&
                !category.includes(ESERVICES) &&
                checkboxLabel(false, null, isSelected(app.id), (event) => handleSelectApplication(event, app.id), readOnly, app.id),
            image: app.graphicalRepresentation ? <ApplicationImage image={app.graphicalRepresentation}/> : <NoImage/>,
            infoSection: [
                {
                    label: t('filing.number'),
                    code: 'number',
                    value: app.number ? app.number : ''
                },
                category.includes(DESIGNS) && {
                    label: t('design.number'),
                    code: 'design.number',
                    value: app.designNumber ? app.designNumber : '',
                },
                {
                    label: t('application.date'),
                    value: (app.applicationDate && bareDateTimeFormat(app.applicationDate, 'YY-mm-dd')) || '',
                },
                {
                    label: t('application.number'),
                    value: app.associatedRight
                },
            ].filter((item) => typeof item === 'object'),
            details: [
                {
                    label: t('notes'),
                    value: (
                        <React.Fragment>
                            {app.note ? (
                                <Tooltip arrow title={app.note} placement="top">
                                    <NoteIcon
                                        onClick={() => handleNoteModal(app.note, app.uniqueNumber, app.ipRightType)}/>
                                </Tooltip>
                            ) : (
                                <EmptyNoteIcon
                                    onClick={() => handleNoteModal(app.note, app.uniqueNumber, app.ipRightType)}
                                    style={{cursor: readOnly ? 'not-allowed' : 'pointer'}}
                                />
                            )}
                        </React.Fragment>
                    ),
                    primaryClass: 'noteSection',
                },
                category.includes(TRADEMARKS) && {
                    label: t('type'),
                    value: app.type ? t(`trademark.type.${app.type}`) : '',
                },
                category.includes(TRADEMARKS) && {
                    label: t('application.kind'),
                    value: app.kind ? t(`tm.kind.${app.kind}`) : '',
                },
                category.includes(TRADEMARKS) && {
                    label: t('denomination'),
                    value: app.denomination ? app.denomination : '',
                },
                category.includes(TRADEMARKS) && {
                    label: t('application.nice.classification'),
                    value: app.niceClass && app.niceClass.length > 0 ? handleTrademarkNiceClassification(app.niceClass) : '',
                },
                category.includes(DESIGNS) && {
                    label: t('indication.product'),
                    value: app.indication ? app.indication : '',
                },
                category.includes(DESIGNS) && {
                    label: t('application.class'),
                    value: app.locarnos && app.locarnos.length > 0 ? handleDesignLocarnos(app.locarnos) : '',
                },
                category.includes(DESIGNS) && {
                    label: t('defer.publication'),
                    value: app.deferPublication ? t('deferPublicationFlag.yes') : t('deferPublicationFlag.no'),
                },
                category.includes(DESIGNS) && {
                    label: t('designers.name'),
                    value: app.designer ? app.designer : '',
                },
                {label: t('status'), value: app.status ? t('status.' + app.status) : '', extraClass: 'status'},
                {
                    label: t('application.status.date'),
                    value: (app.statusDate && bareDateTimeFormat(app.statusDate, 'YY-mm-dd')) || '',
                },
                {
                    label: t('application.registration.number'),
                    value: app.registrationNumber ? app.registrationNumber : '',
                },
                {
                    label: t('application.registration.date'),
                    value: (app.registrationDate && bareDateTimeFormat(app.registrationDate, 'YY-mm-dd')) || '',
                },
                {
                    label: t('application.expiration.date'),
                    value: (app.expirationDate && bareDateTimeFormat(app.expirationDate, 'YY-mm-dd')) || '',
                },
                {
                    label: t('application.publication.date'),
                    value: (app.publicationDate && bareDateTimeFormat(app.publicationDate, 'YY-mm-dd')) || '',
                },
                {label: t('applicants.name'), value: app.applicant ? app.applicant : ''},
                {label: t('representatives.name'), value: app.representative ? app.representative : ''},
            ],
            menuItems: [
                {
                    label: t('view.application.receipt'),
                    action: () => getApplicationReceipt(app.number, app.ipRightType)
                },
                enableCorrespondence && {
                    label: t('view.correspondence'),
                    action: () => checkCorrespondence(app.associatedRight)
                },
                {label: t('view.payment.invoice'), action: () => getPaymentInvoice(app.number)},
                !readOnly && {
                    label: app.note ? t('edit.note') : t('add.note'),
                    action: () => handleNoteModal(app.note, app.uniqueNumber, app.ipRightType),
                },
                /** hidden until next release
                !readOnly && !category.includes(ESERVICES) && {
                    label: t('initiate.eService'),
                    action: () => handleInitiateEservice(app.id)
                },
                 */
                !readOnly && category.includes(TRADEMARKS) && {
                    label: t('duplicate'),
                    action: () => duplicateApplication(app.id)
                },
            ].filter((item) => typeof item === 'object'),
        };
    });
};

export const applicationsParserList = (
    applications,
    category,
    userType,
    getApplicationReceipt,
    checkCorrespondence,
    getPaymentInvoice,
    handleNoteModal,
    duplicateApplication,
    readOnly,
    handleSelectAllApplications,
    handleSelectApplication,
    rowCount,
    enableSorting = false,
    checkBoxDisabled = false,
    selectedApplications = [],
    handleInitiateEservice = null,
    hasCorrespondenceRole = true,
    filtersOptions,
) => {
    const {content} = applications;

    const isSelected = (number) => selectedApplications.indexOf(number) !== -1;
    const numSelected = selectedApplications.length;
    const indeterminateAll = numSelected > 0 && numSelected < rowCount;
    const checkedAll = rowCount > 0 && numSelected === rowCount;

    const enabledCheckbox = !checkBoxDisabled && !category.includes(ESERVICES);
    const enableCorrespondence = hasCorrespondenceRole && !category.includes(ESERVICES);

    const translateTrademarkType = (typeValue) => {
        if (filtersOptions && filtersOptions.trademark && filtersOptions.trademark.types) {
            let found = filtersOptions.trademark.types.find(type => type.key === typeValue).label;
            if (!!found) {
                return t(found);
            }
        }
        return typeValue;
    }

    // table/list column definitions
    let applicationsViewList = (category) => {
        let headers = [];
        if(category.includes(ALL_IP_RIGHTS)){
            headers = [
                enabledCheckbox && {
                    label: checkboxLabel(true, indeterminateAll, checkedAll, handleSelectAllApplications, readOnly), align: 'left', sortable: false,
                },
                { label: t('eService.name'), code: 'eserviceName', align: 'left', sortable: enableSorting, },
                { label: t('denomination'), code: 'denomination', align: 'left', sortable: enableSorting, },
                { label: t('application.date'), code: 'applicationDate', align: 'left', sortable: enableSorting},
                { label: t('application.number'), code: 'number', align: 'left', sortable: enableSorting },
                { label: t('applicants.name'), code: 'applicant', align: 'left', sortable: enableSorting},
                { label: t('status'), code: 'status', align: 'left', sortable: enableSorting},
                { label: t('user.reference'), code: 'userReference', align: 'left', sortable: false },
            ];
        };
        if(category.includes(TRADEMARKS)){
            headers = [
                enabledCheckbox && {
                    label: checkboxLabel(true, indeterminateAll, checkedAll, handleSelectAllApplications, readOnly), align: 'left', sortable: false,
                },
                { label: t('graphical.representation'), code: 'graphical.representation' },
                { label: t('denomination'), code: 'denomination', align: 'left', sortable: enableSorting, },
                { label: t('application.number'), code: 'number', align: 'left', sortable: enableSorting },
                { label: t('application.date'), code: 'applicationDate', align: 'left', sortable: enableSorting},
                { label: t('applicants.name'), code: 'applicant', align: 'left', sortable: enableSorting},
                { label: t('type'), code: 'type', align: 'left', sortable: enableSorting },
                { label: t('status'), code: 'status', align: 'left', sortable: enableSorting},
                //hide until phase 2
                //{ label: t('notes'), code: 'notes', align: 'center', sortable: false },
                { label: t('user.reference'), code: 'userReference', align: 'left', sortable: false },
                { label: '', code: 'actions', align: 'left', sortable: false},
            ];
        };
        if(category.includes(DESIGNS)){
            headers = [
                enabledCheckbox && {
                    label: checkboxLabel(true, indeterminateAll, checkedAll, handleSelectAllApplications, readOnly), align: 'left', sortable: false,
                },
                { label: t('graphical.representation'), code: 'graphical.representation' },
                { label: t('design.name'), code: 'designName', align: 'center', sortable: false, },
                { label: t('application.number'), code: 'number', align: 'left', sortable: enableSorting },
                { label: t('application.date'), code: 'applicationDate', align: 'left', sortable: enableSorting},
                { label: t('applicants.name'), code: 'applicant', align: 'left', sortable: enableSorting},
                { label: t('design.number'), code: 'designNumber', align: 'left', sortable: enableSorting, },
                { label: t('status'), code: 'status', align: 'left', sortable: enableSorting},
                //hide until phase 2
                //{ label: t('notes'), code: 'notes', align: 'center', sortable: false },
                { label: t('user.reference'), code: 'userReference', align: 'left', sortable: false },
                { label: '', code: 'actions', align: 'left', sortable: false},
            ];
        }
        if(category.includes(ESERVICES)){
            headers = [
                enabledCheckbox && {
                    label: checkboxLabel(true, indeterminateAll, checkedAll, handleSelectAllApplications, readOnly), align: 'left', sortable: false,
                },
                { label: t('eService.name'), code: 'eserviceName', align: 'left', sortable: enableSorting, },
                { label: t('application.or.number'), code: 'number', align: 'left', sortable: enableSorting },
                { label: t('application.date'), code: 'applicationDate', align: 'left', sortable: enableSorting},
                { label: t('applicants.name'), code: 'applicant', align: 'left', sortable: enableSorting},
                { label: t('status'), code: 'status', align: 'left', sortable: enableSorting},
                //hide until phase 2
                //{ label: t('notes'), code: 'notes', align: 'center', sortable: false },
                { label: t('user.reference'), code: 'userReference', align: 'left', sortable: false },
                { label: t('filing.number'), code: 'number', align: 'left', sortable: enableSorting },
                { label: '', code: 'actions', align: 'left', sortable: false},
            ];
        }
        return { headers : headers.filter((item) => typeof item === 'object') }; //remove non-objects from headers
    };

    let dataList = [];

    // table/list data
    const parsedApp = (content || []).map(app => {
            const menuItems = {
                label: 'menuItems',
                value: [
                    {
                        label: t('view.application.receipt'),
                        action: () => getApplicationReceipt(app.number, app.ipRightType)
                    },
                    enableCorrespondence && {
                        label: t('view.correspondence'),
                        action: () => {
                            checkCorrespondence(app.associatedRight);
                        },
                    },
                    {label: t('view.payment.invoice'), action: () => getPaymentInvoice(app.number)},
                    // hide until phase 2
                    // !readOnly && !category.includes(ESERVICES) && {
                    //     label: app.note ? t('edit.note') : t('add.note'),
                    //     action: () => handleNoteModal(app.note, app.number, app.designNumber, app.ipRightType),
                    // },
                    // !readOnly && !category.includes(ESERVICES) && { label: t('initiate.eService'), action: () => handleInitiateEservice(app.id) },
                    // !readOnly && category.includes(TRADEMARKS) && { label: t('duplicate'), action: () => duplicateApplication(app.id) },
                ].filter((item) => typeof item === 'object'),
            };

            const notes = {
                label: t('notes'),
                value: (
                    <div>
                        {app.note ? (
                            <Tooltip arrow title={app.note} placement="top">
                                <NoteIcon onClick={() => handleNoteModal(app.note, app.uniqueNumber, app.ipRightType)} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                        ) : (
                            <EmptyNoteIcon
                                onClick={() => handleNoteModal(app.note, app.uniqueNumber, app.ipRightType)}
                                style={{ cursor: readOnly ? 'not-allowed' : 'pointer' }}
                            />
                        )}
                    </div>
                ),
                align: 'center',
            };

            if(category.includes(ALL_IP_RIGHTS)){
                return [
                    enabledCheckbox &&
                    { label: 'selectedApp', value: checkboxLabel(false, null, isSelected(app.id), (event) => handleSelectApplication(event, app.id), readOnly, app.id) },
                    { label: t('eserviceName'), value: app.eserviceName ? t('eservice.name.' + app.eserviceName) : '' },
                    { label: 'denomination', value: app.denomination ? app.denomination : '' },
                    { label: 'application.date', value: (app.applicationDate && bareDateTimeFormat(app.applicationDate, 'lt')) || ''},
                    { label: 'associatedRight', value: app.associatedRight ? valueWithTooltip(app.associatedRight) : '' },
                    { label: 'applicant', value: app.applicant ? valueWithTooltip(app.applicant) : ''},
                    { label: 'status', value: app.status ? t('status.' + app.status) : ''},
                    { label: 'user.reference', value: app.userReference}
                ];
            }
            if(category.includes(TRADEMARKS)){
                return [
                    enabledCheckbox &&
                    { label: 'selectedApp', value: checkboxLabel(false, null, isSelected(app.id), (event) => handleSelectApplication(event, app.id), readOnly, app.id) },
                    { label: 'graphicalRepresentation', value: !!app.graphicalRepresentation ? <ApplicationImage image={app.graphicalRepresentation}/> : <NoImage/> },
                    { label: 'denomination', value: app.denomination ? app.denomination : '' },
                    { label: 'associatedRight', value: app.associatedRight ? valueWithTooltip(app.associatedRight) : '' },
                    { label: 'application.date', value: (app.applicationDate && bareDateTimeFormat(app.applicationDate, 'lt')) || ''},
                    { label: 'applicant', value: app.applicant ? valueWithTooltip(app.applicant) : ''},
                    { label: 'type', value: app.type ? t(`trademark.type.${app.type}`) : ''},
                    { label: 'status', value: app.status ? t('status.' + app.status) : ''},
                    // notes,
                    { label: 'user.reference', value: app.userReference},
                    menuItems
                ];
            }
            if(category.includes(DESIGNS)){
                return [
                    enabledCheckbox &&
                    { label: 'selectedApp',         value: checkboxLabel(false, null, isSelected(app.id), (event) => handleSelectApplication(event, app.id), readOnly, app.id) },
                    { label: 'graphicalRepresentation', value: !!app.graphicalRepresentation ? <ApplicationImage image={app.graphicalRepresentation}/> : <NoImage/> },
                    { label: 'design.name',         value: app.indication ? app.indication : '' },
                    { label: 'associatedRight',     value: app.associatedRight ? valueWithTooltip(app.associatedRight) : '' },
                    { label: 'application.date',    value: (app.applicationDate && bareDateTimeFormat(app.applicationDate, 'lt')) || '' },
                    { label: 'applicant',           value: app.applicant ? valueWithTooltip(app.applicant) : ''},
                    { label: 'designNumber',        value: app.designNumber ? app.designNumber : '' },
                    { label: 'status',              value: app.status ? t('status.' + app.status) : '' },
                    // notes, //hide until phase 2
                    { label: 'user.reference',      value: app.userReference},
                    menuItems
                ];
            }
            if(category.includes(ESERVICES)){
                return [
                    enabledCheckbox && { label: 'selectedApp', value: checkboxLabel(false, null, isSelected(app.id), (event) => handleSelectApplication(event, app.id), readOnly, app.id) },
                    { label: t('eserviceName'), value: app.eserviceName ? t('eservice.name.' + app.eserviceName) : '' },
                    { label: t('associatedRight'), value: app.associatedRight ? valueWithTooltip(app.associatedRight) : '' },
                    { label: t('application.date'), value: (app.applicationDate && bareDateTimeFormat(app.applicationDate, 'lt')) || '' },
                    { label: t('applicant'), value: app.applicant ? valueWithTooltip(app.applicant) : '' },
                    { label: t('status'), value: app.status ? t('status.' + app.status) : ''},
                    // notes, //hide until phase 2
                    { label: 'user.reference', value: app.userReference },
                    { label: 'filing.number', value: app.number ? valueWithTooltip(app.number) : '' },
                    menuItems
                ];
            }
        }
    );

    //remove non-objects from data
    dataList = parsedApp.map((app) => {
        return app.filter((item) => typeof item === 'object');
    });

    applicationsViewList = {
        ...applicationsViewList(category),
        data: dataList,
    };

    return applicationsViewList;
};

export const filtersParser = (filters) => {
    const startDateTime = 'T00:00:00';
    const endDateTime = 'T23:59:59';
    let parsedFilters = {...filters};
    // remove mark word from trademark types
    if (parsedFilters.types && parsedFilters.types.length > 0) {
        const parsedTypes = parsedFilters.types.map((item) => item.replace(' mark', ''));
        parsedFilters = {...parsedFilters, types: parsedTypes};
    }

    // Normalize date ranges to API accepted date range data type, if any.
    if (parsedFilters.dates) {
        parsedFilters = {
            ...parsedFilters,
            dates: parsedFilters.dates.reduce((allDates, {dateType, startDate, endDate}) => {
                if (!!dateType && !!startDate && !!endDate) {
                    allDates.push({
                        date: dateType,
                        dateFrom: `${bareDateTimeFormat(startDate, 'YY-mm-dd')}${startDateTime}`,
                        dateTo: `${bareDateTimeFormat(endDate, 'YY-mm-dd')}${endDateTime}`,
                    });
                }
                return allDates;
            }, []),
        };
    }

    //some status labels represent multiple statuses separated by comma, we split them before sending to backend
    if(filters.statuses && filters.statuses.length > 0){
        let statuses = [];
        filters.statuses.map(item => {
            if(item.indexOf(',') > 1){
                statuses = statuses.concat(item.split(','));
            } else {
                statuses.push(item);
            }
        });
        parsedFilters = {
            ...parsedFilters,
            statuses: statuses
        }
    }
    return parsedFilters;
};

export const myApplicationsExportColumns = (category) => {
    let columnLabels = [];
    if (category.includes(ESERVICES))
        columnLabels = [
            t('eService.name'),
            t('app.number'),
            t('application.date'),
            t('applicants.name'),
            t('application.status'),
            t('user.reference'),
            t('filing.number'),
        ];
    if (category.includes(DESIGNS))
        columnLabels = [

            t('design.name'),
            t('app.number'),
            t('application.date'),
            t('applicants.name'),
            t('design.number'),
            t('application.status'),
            t('user.reference'),
            t('filing.number'),
        ];
    if (category.includes(TRADEMARKS))
        columnLabels = [
            t('denomination'),
            t('app.number'),
            t('application.date'),
            t('applicants.name'),
            t('type'),
            t('application.status'),
            t('user.reference'),
            t('filing.number'),
        ];
    return columnLabels
        .filter((item) => typeof item !== 'boolean')
        .map((colName) => colName.toUpperCase());
};
