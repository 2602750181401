/* eslint-disable import/order */
import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { ReactComponent as ArrowDownIcon } from '../../assets/images/arrow_drop_down_24px.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/images/arrow_drop_up_24px.svg';

const useStyles = makeStyles((theme) => ({
    mobileSorting: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 5px',
        fontSize: theme.typography.fontSize,
        lineHeight: '20px',
    },
    info: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '@media (max-width:400px)': {
            flexDirection: 'column',
            alignItems: 'start',
        },
    },
    customText: { fontWeight: 600 },
    currentSort: { display: 'inline-flex', alignItems: 'center' },
    currentSortBy: {
        margin: '0 5px 0 10px',
        '@media (max-width:400px)': {
            margin: 0,
        },
    },
    menuIcon: { padding: '0 5px' },
}));

const ASC = 'ASC';
const DESC = 'DESC';

const MobileSorting = ({ text, sortableFields, sortBy, sortType, handleSorting }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAction = (column, type) => {
        handleSorting(true, column, type);
        handleClose();
    };

    const currentSortBy = sortableFields.find((item) => item.value === sortBy)?.label;
    return (
        <div className={classes.mobileSorting}>
            <div className={classes.info}>
                <span className={classes.customText}>{text}</span>
                <div className={classes.currentSort}>
                    <span className={classes.currentSortBy}>{currentSortBy}</span>
                    {sortType === DESC ? (
                        <ArrowDownIcon onClick={() => handleAction('', ASC)} />
                    ) : (
                        <ArrowUpIcon onClick={() => handleAction('', DESC)} />
                    )}
                </div>
            </div>
            <div>
                <IconButton
                    className={classes.menuIcon}
                    id={'sorting-options'}
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    {sortableFields
                        .filter((item) => typeof item === 'object') // display only menuItems that is type of object, with label and action props
                        .map((item, index) => (
                            <MenuItem id={`sorting-option-${index}`} key={index} onClick={() => handleAction(item.value, '')}>
                                {item.label}
                            </MenuItem>
                        ))}
                </Menu>
            </div>
        </div>
    );
};

export default MobileSorting;
