import React, { useEffect, useRef, useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

import { ReactComponent as DownloadIcon } from '../../../../assets/images/download.svg';
import { ReactComponent as ReplyIcon } from '../../../../assets/images/reply_24px.svg';
import Accordion from '../../../../components/Accordion';
import { bareDateTimeFormat, t } from '../../../../providers';
import Attachments from '../Attachments';
import Details from '../Details';

const useStyles = makeStyles((theme) => ({
    messageSection: {
        color: theme.palette.text.greyMain,
        marginBottom: '30px',
        height: '150px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '3px',
            backgroundColor: theme.palette.scrollbar.main,
        },
        '@media (max-width:960px)': {
            height: 'unset',
            minHeight: '50px',
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingBottom: '10px',
        '@media (max-width:960px)': { marginRight: '10px' },
    },
    actionIcon: {
        '@media (max-width:960px)': {
            padding: '12px',
            '&:hover': { cursor: 'pointer' },
        },
    },
    headerActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        transition: '.2s all ease-in-out',
        '& #download-btn-header:hover': { cursor: 'pointer' },
        '& svg': {
            paddingLeft: '10px',
        },
    },
    hidden: { opacity: 0 },
}));

const Outgoing = ({ isTablet, content = {}, parentInfo, open, downloadAttachment, downloadCorrespondence }) => {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);
    const { id, actionDate, userFullNameAndEmail, body: messageBody, messageSearchAttachment: attachments } = content;
    const { applicationNumber, dossierType } = parentInfo;
    const replyRef = useRef(null);

    const handleFieldsView = (fieldvalue, textValue) => (fieldvalue ? textValue || fieldvalue : '');

    useEffect(() => {
        setExpanded(open);
        if (open) replyRef.current.scrollIntoView();
    }, [open]);

    const handleChange = () => setExpanded(!expanded);

    const details = isTablet ? (
        <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={10}>
                <Details label={`${t('sent.date')}:`} value={handleFieldsView(actionDate, bareDateTimeFormat(actionDate))} />
                <Details label={`${t('sender')}:`} value={handleFieldsView(userFullNameAndEmail)} />
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                {/*
                <div className={classes.actions}>
                    <DownloadIcon className={classes.actionIcon} id={'downloadBtn'} onClick={() => downloadCorrespondence(id)} />
                </div>
                */}
            </Grid>
        </Grid>
    ) : (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <Details label={`${t('sent.date')}:`} value={handleFieldsView(actionDate, bareDateTimeFormat(actionDate))} />
            </Grid>
            <Grid item xs={12} md={4}>
                <Details label={`${t('sender')}:`} value={handleFieldsView(userFullNameAndEmail)} />
            </Grid>
            <Grid item xs={12} md={4} align={'right'}>
                <div className={[classes.headerActions, expanded ? classes.hidden : ''].join(' ')}>
                    {/*
                    <Tooltip arrow title={t('download')} placement={'top'}>
                        <GetAppOutlinedIcon
                            id={'download-btn-header'}
                            aria-label={'download'}
                            onClick={() => downloadCorrespondence(id)}
                            color={'secondary'}
                        />
                    </Tooltip>
                    */}
                    <ReplyIcon />
                </div>
            </Grid>
        </Grid>
    );

    return (
        <Accordion
            replyRef={replyRef}
            expanded={expanded}
            handleChange={handleChange}
            title={`${t('reply.noun')} - ${handleFieldsView(t('correspondence.category.' + dossierType))} - ${handleFieldsView(applicationNumber)}`}
            id={`reply${id}`}
            visibleDetails={details}>
            <div>
                {/* message */}
                <div className={classes.messageSection} dangerouslySetInnerHTML={{ __html: messageBody }} />
                {/* attachments */}
                {attachments && !!attachments.length && <Attachments attachments={attachments} handleDownload={downloadAttachment} />}
            </div>

            {/* actions */}
            {/* !isTablet && (
                <div className={classes.actions}>
                    <Button
                        className={classes.closeButton}
                        id={'download-btn'}
                        endIcon={<GetAppOutlinedIcon />}
                        onClick={() => downloadCorrespondence(id)}>
                        {t('download')}
                    </Button>
                </div>
            ) */}
        </Accordion>
    );
};

export default Outgoing;
