import { sendRequest } from '../../common/utils';

export const fetchPaymentHistory = ({ params }) => {
    const { requestBodyData } = params;
    const url = '/payments/history/search';
    return sendRequest({
        url,
        method: 'POST',
        prefix: '/backend',
        body: { ...requestBodyData },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};
export const downloadReceipt = ({ params }) => {
    const { paymentId } = params;
    const url = `/payments/history/download/invoice/${paymentId}`;
    return sendRequest({
        url,
        method: 'GET',
        prefix: '/backend',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: 'payment.invoice.failed', code: error.code, details: error.details } };
        });
};
