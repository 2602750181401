import React from 'react';

import Typography from '@material-ui/core/Typography';

import { t } from '../../providers';

const ErrorMessages = ({ errorMessage, classes = '' }) => {
    const messages = errorMessage.split(',');
    return (
        <Typography variant="body2" color="error" className={classes}>
            <ul>
                {messages.map((item, idx) => (
                    <li>{t(item)}</li>
                ))}
            </ul>
        </Typography>
    );
};

export default ErrorMessages;
