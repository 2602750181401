import React from 'react';

import { Link } from 'react-router-dom';

import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

import {ReactComponent as NoteIcon} from '../../assets/images/note_24px.svg';
import {ReactComponent as EmptyNoteIcon} from '../../assets/images/note_empty_24px.svg';
import {capitalize} from '../../common/utils/service';
import ApplicationImage from '../../components/ApplicationImage';
import NoImage from '../../components/NoImage';
import {t, bareDateTimeFormat} from '../../providers';

const TRADEMARKS = 'trademark';
const DESIGNS = 'design';
const ESERVICES = 'eservice';

const handleTrademarkNiceClassification = (classification) =>
    classification.split(',').map((item, idx) => (
        <span key={idx} className={'classification-item'}>
            {item}
        </span>
    ));

const handleDesignLocarnos = (locarnos) =>
    Object.values(locarnos).map((item, idx) => (
        <React.Fragment key={idx}>
            {item.mainClass && item.subClass ?
                <span className={'classification-item'}>{`${item.mainClass}.${item.subClass}`}</span> : ''}
        </React.Fragment>
    ));

const valueWithTooltip = (text) => (
    <Tooltip arrow title={text} placement={'left'}>
        <span>{text}</span>
    </Tooltip>
);

/**
 * application status link navigates to a particular status page 
 * @param {string} applicationStatus
 * @returns application status as link or plain text
 */
const valueWithStatusLink = (applicationStatus) =>
    {
        let pathname;
        if(applicationStatus === 'WAITING_FOR_PAYMENT')
            pathname = '/payments/shopping-cart';
        else if (applicationStatus === 'WAITING_FOR_SIGNATURE')
            pathname = '/signatures';
        return !!pathname ? (
            <Link
                to={{ pathname: pathname, }}>
                {t('status.' + applicationStatus)}
            </Link>
        ) : (
            t('status.' + applicationStatus)
        )
    };

const checkboxLabel = (checkAll, indeterminate, checked, onChange, readOnly, id) => {
    const onChangeValue = !readOnly ? onChange : () => {};
    return (
        <Checkbox
            indeterminate={checkAll ? indeterminate : null}
            checked={checked}
            onChange={onChangeValue}
            inputProps={{'aria-label': checkAll ? 'select all applications' : `select ${id}`}}
            disabled={readOnly}
        />
    );
};

export const applicationsParserList = (
    applications,
    getApplicationReceipt,
    enableSorting = false
) => {
    const {content} = applications;

    let applicationsViewList = {
        headers: [
            { label: t('type'), code: 'eserviceCode', align: 'left', sortable: enableSorting },
            { label: t('user.reference'), code: 'userReference', align: 'left', sortable: enableSorting },
            { label: t('application.or.number'), code: 'associatedRight', align: 'left', sortable: enableSorting },
            { label: t('applicants.name'), code: 'applicant', align: 'left', sortable: enableSorting},
            { label: t('status'), code: 'status', align: 'left', sortable: enableSorting},
            { label: t('filing.number'), code: 'number', align: 'left', sortable: enableSorting },
            { label: '', code: 'actions', align: 'left', sortable: false },
        ].filter((item) => typeof item === 'object'), //remove non-objects from headers
    };

     let dataList = [];

    // table/list data
    const parsedApp = (content || []).map((app) => [
        { label: t('eserviceName'), value: app.eserviceName ? t('eservice.name.' + app.eserviceName) : '' },
        { label: 'user.reference', value: app.userReference },
        { label: t('associatedRight'), value: app.associatedRight ? valueWithTooltip(app.associatedRight) : '' },
        { label: t('applicant'), value: app.applicant ? valueWithTooltip(app.applicant) : ''},
        { label: t('status'), value: app.status ? valueWithStatusLink(app.status) : ''},
        { label: 'filing.number', value: app.number ? valueWithTooltip(app.number) : ''},
        {
            label: 'menuItems',
            value: [
                { label: t('view.application.receipt'), action: () => getApplicationReceipt(app.number, app.ipRightType) }
            ].filter((item) => typeof item === 'object'),
        },
    ]);

    //remove non-objects from data
    dataList = parsedApp.map((app) => {
        return app.filter((item) => typeof item === 'object');
    });

    applicationsViewList = {
        ...applicationsViewList,
        data: dataList,
    };

    return applicationsViewList;
};
