import React, { useEffect, useState } from 'react';

import { t } from '../../providers';
import WarningModal from '../WarningModal';
import NoteModal from './NoteModal';

const Note = ({
    data,
    saveNote,
    editNote,
    openNote,
    setOpenNote,
    editModeNote,
    setEditModeNote,
    maxCharsAllowed = 100,
    readOnly,
    lockedNote,
    clearLockNote,
    loading,
}) => {
    const { note, appNum: number, ipRightType, applicationType } = data;

    const [noteValue, setNoteValue] = useState(note || '');
    const [confirmationDelete, setConfirmationDelete] = useState(false);
    const [editTriggered, setEditTriggered] = useState(false);

    useEffect(() => {
        setNoteValue(note);
    }, [note]);

    useEffect(() => {
        if (editTriggered)
            if (lockedNote) {
                closeNote();
                clearLockNote();
            } else {
                setEditModeNote(true);
            }
    }, [lockedNote]);

    const closeNote = () => {
        clearLockNote();
        setEditModeNote(false);
        setOpenNote(false);
        setConfirmationDelete(false);
        setEditTriggered(false);
    };

    const onSaveNote = (value) => {
        if (value && value.length <= maxCharsAllowed) {
            setNoteValue(value);
            saveNote({ appNumber: number, note: value, ipRightType, applicationType });
            closeNote();
        }
    };

    const triggerDeleteNoteModal = () => {
        closeNote();
        setConfirmationDelete(true);
    };

    const onEditNote = () => {
        setEditTriggered(true);
        editNote({ appNumber: number, ipRightType, applicationType });
    };

    const onDeleteNote = () => {
        setNoteValue('');
        saveNote({ appNumber: number, note: '', ipRightType, applicationType });
        closeNote();
    };

    return (
        <React.Fragment>
            <NoteModal
                title={t('notes.for.application', { applicationNumber: number })}
                open={openNote}
                noteValue={noteValue}
                editMode={editModeNote}
                onClose={closeNote}
                onSave={onSaveNote}
                onDelete={triggerDeleteNoteModal}
                maxCharsAllowed={maxCharsAllowed}
                readOnly={readOnly}
                onEdit={onEditNote}
                loading={editTriggered && loading}
            />
            {/* confirmation dialog */}
            <WarningModal
                title={t('delete.note')}
                visibility={confirmationDelete}
                message={t('delete.note.confirmation')}
                cancelButtonText={t('cancel')}
                cancelButtonStyle={'contained'}
                onCancel={closeNote}
                onConfirmation={onDeleteNote}
                confirmButtonText={t('confirm')}
                confirmButtonStyle={'outlined'}
            />
        </React.Fragment>
    );
};

export default Note;
