import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { globals as globalsSlice } from '../../app/globalsSlice';
import { getLocalStorage, setLocalStorage } from '../../common/utils';
import { me as meSlice } from '../../pages/AuthenticatedPage/authenticationSlice';
import { SLICE_NAME as CORR_SLICE_NAME } from '../../pages/CorrespondencePage/constants';
import { expiringMessages, listParameters } from '../../pages/CorrespondencePage/correspondenceSlice';
import { bareDateTimeFormat, t } from '../../providers';
import WarningModal from '../WarningModal';

const AlertExpiringMessages = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const me = useSelector(meSlice) || {};
    const { globals } = useSelector(globalsSlice) || {};
    const countExpiringMessages = useSelector(expiringMessages) || 0;
    const corrParameters = useSelector(listParameters);

    const expiringDateThreshold = globals?.application['correspondence.expiration.period'] || 172800; // 172800sec = 2days
    const startDate = new Date(new Date().getTime()); //now
    const endDate = new Date(new Date().getTime() + expiringDateThreshold * 1000);

    const [showAlert, setShowAlert] = useState(false);

    const [infoDialog, setInfoDialog] = useState({
        visibility: false,
        message: '',
        onConfirmation: () => {},
        confirmButtonText: '',
        cancelButtonText: '',
        onCancel: () => {},
    });

    const updatePersistentParameters = (params) => dispatch({ type: `${CORR_SLICE_NAME}/updateCorrParameters`, payload: params });

    const handleCancelModalAction = () => {
        setInfoDialog({
            visibility: false,
            message: '',
            onConfirmation: () => {},
            confirmButtonText: '',
            cancelButtonText: '',
            onCancel: () => {},
        });
        setShowAlert(false);
    };

    const reviewExpiringCorrespondence = () => {
        const dueDateFilter = {
            dates: [
                {
                    dateType: 'dueDate',
                    dateTypeLabel: 'correspondence.filter.date.dueDate',
                    endDate: endDate,
                    startDate: startDate,
                },
            ],
        };
        updatePersistentParameters({
            ...corrParameters,
            filters: { ...dueDateFilter, requiresReply: 'true' },
            filtersChips: { ...dueDateFilter, requiresReply: ['correspondence.filter.reply.yes'] },
        });

        handleCancelModalAction();
        history.push({ pathname: '/correspondence' });
    };

    useEffect(() => {
        const showExpiringMessages = getLocalStorage('showExpiringMessages') || false;

        if (!showExpiringMessages) {
            if (!Object.keys(me).length) setLocalStorage([{ item: 'showExpiringMessages', value: false }]);
            else if (!showAlert) {
                const { authorities } = me;
                const hasCorrespondenceRole =
                    authorities && (authorities.includes('ROLE_CORRESPONDENCE_READ_ONLY') || authorities.includes('ROLE_CORRESPONDENCE_READ_WRITE'));

                if (hasCorrespondenceRole) {
                    setLocalStorage([{ item: 'showExpiringMessages', value: true }]);

                    const params = {
                        requestBody: {
                            filterCriteria: {
                                dates: [
                                    {
                                        date: 'dueDate',
                                        dateTo: bareDateTimeFormat(endDate, 'filter', 'end'),
                                        dateFrom: bareDateTimeFormat(startDate, 'filter', 'start'),
                                    },
                                ],
                            },
                        },
                    };
                    dispatch({ type: `${CORR_SLICE_NAME}/requestExpiringCorrespondences`, payload: { params } });
                }
            }
        }
    }, [me]);

    useEffect(() => {
        if (!showAlert && !!countExpiringMessages) setShowAlert(true);
    }, [countExpiringMessages]);

    useEffect(() => {
        if (showAlert) {
            setInfoDialog({
                visibility: true,
                message: t('alert.expiring.messages.count', { count: countExpiringMessages }),
                onConfirmation: reviewExpiringCorrespondence,
                confirmButtonText: t('review'),
                cancelButtonText: t('cancel'),
                onCancel: handleCancelModalAction,
            });
        }
    }, [showAlert]);

    return (
        <React.Fragment>
            {infoDialog.visibility && (
                <WarningModal
                    visibility={infoDialog.visibility}
                    message={infoDialog.message}
                    onConfirmation={infoDialog.onConfirmation}
                    confirmButtonText={infoDialog.confirmButtonText}
                    cancelButtonText={infoDialog.cancelButtonText}
                    onCancel={infoDialog.onCancel}
                />
            )}
        </React.Fragment>
    );
};

export default AlertExpiringMessages;
