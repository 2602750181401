import { sendRequest } from '../../common/utils';

export const queryForgotPassword = (email) => {
    return sendRequest({
        url: '/user/password/reset',
        method: 'POST',
        body: { email },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const queryValidateResetToken = (token) => {
    return sendRequest({
        url: '/user/password/validate-token',
        method: 'POST',
        body: { token },
    })
        .then((response) => {
            if (!response.valid) return { error: { description: response.message, code: 400 } };
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const queryResetPassword = (payload) => {
    return sendRequest({
        url: '/user/password/confirmation',
        method: 'POST',
        body: payload,
    })
        .then((response) => {
            return { ...response, ok: true };
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const validateEmailAndPassword = ({ userName, email, password }) => {
    return sendRequest({
        url: '/user/registration/validation',
        method: 'POST',
        body: { userName, email, password },
    })
        .then((response) => {
            return { ...response, fromAction: 'validateCredentials' };
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const createAccount = (params) => {
    return sendRequest({
        url: '/user/registration/creation',
        method: 'POST',
        body: { ...params },
    })
        .then((response) => {
            return { ...response, fromAction: 'registerAccount' };
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const requestVerificationCode = (params) => {
    return sendRequest({
        url: '/user/registration/resend-token',
        method: 'POST',
        body: { ...params },
    })
        .then((response) => {
            return { ...response, fromAction: 'requestNewVerificationCode' };
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const requestAccountVerification = (params) => {
    return sendRequest({
        url: '/user/registration/confirmation',
        method: 'POST',
        body: { ...params },
    })
        .then((response) => {
            return { ...response, fromAction: 'verifyAccount' };
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

/**
 * Request for authenticated via E-government user data. The ticket is valid only once.
 * @returns 
 */
export const requestEgovAuthenticationData = ({ticket}) => {
    return sendRequest({
        prefix: '/',
        url: 'epaslaugos/auth/callback/new-account/' + ticket,
        method: 'GET'
        })
        .then((response) => {
            return {
                'firstName': response.firstName,
                'surName': response.lastName,
                'email': response.emailAddress,
                'telephone': response.phoneNumber,
                'naturalPersonCode': response.personalCode,
                'legalEntityCode': response.companyCode,
                'organizationName': response.companyName,
                'isPatentAttorney': response.isPatentAttorney,
                'fromAction': 'requestEgovAuthenticationData',
            };
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const requestUserInfo = () => {
    return sendRequest({
        url: '/auth/me',
        method: 'GET',
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const impersonatedLogout = () => {
    return sendRequest({
        url: '/logout/impersonate',
        method: 'GET',
        prefix: '/backend',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const initEgovLogin = ({url = '/epaslaugos/auth/init-login', ...data}) => {
    const bodyParams = [];
    for (var prop in data){
        if (data.hasOwnProperty(prop)) {
            bodyParams.push(prop + "=" + encodeURIComponent(data[prop]));
        }
    }
    const body = bodyParams.join('&');
    return fetch(url, {
        body: body,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'post',
    })
    .then((response) => response.json())
    .catch((error) => {
        return { error: { description: error.message, code: error.code } };
    });
};

export const verifyUsernameChangeToken = (params) => {
    return sendRequest({
        url: '/user/change-username/confirmation',
        method: 'POST',
        body: { ...params },
    })
        .then((response) => {
            return { ...response, fromAction: 'verifyUsernameChange' };
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const requestUsernameChangeNewVerificationToken = (params) => {
    return sendRequest({
        url: '/user/change-username/resend-token',
        method: 'POST',
        body: { ...params },
    })
        .then((response) => {
            return { ...response, fromAction: 'requestUsernameChangeNewVerificationCode' };
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};