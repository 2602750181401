import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { userCan } from '../../common/utils/service';
import ApplicationsTable from '../../components/ApplicationsTable';
import Card from '../../components/Card';
import ApplicationFilters from '../../components/Filters/ApplicationFilters';
import PageTitle from '../../components/PageTitle';
import CustomPagination from '../../components/Pagination/CustomPagination';
import WarningModal from '../../components/WarningModal';
import { t } from '../../providers';
import { me as meSlice } from '../AuthenticatedPage/authenticationSlice';
import { SLICE_NAME as APP_SLICE_NAME } from '../MyApplicationsPage/constants';
import { SLICE_NAME } from './constants';
import { list, loading as loadingSlice, error as errorSlice } from './draftApplicationsSlice';
import filterItems from './filterItems';
import { draftApplicationsParserList, filtersParser, myDraftsExportColumns } from './utils';

const useStyles = makeStyles((_theme) => ({
    container: {
        width: '100%',
        height: '100%',
        maxWidth: 'calc(100% - 236px)',
        '@media (max-width:1100px)': {
            minWidth: '77%',
            maxWidth: 'unset',
        },
    },
    filtersGrid: {
        margin: '30px 20px 40px 0',
    },
    tabs: {
        '& .MuiTab-root': {
            minWidth: '100px',
        },
    },
    tabContainer: {
        width: '100%',
    },
    cardTable: {
        marginRight: '20px',
        '@media (max-width:960px)': {
            marginRight: 'unset',
        },
    },
}));

const TRADEMARKS = 'trademark';
const DESIGNS = 'design';
const ESERVICES = 'eservice';
const ASC = 'ASC';
const DESC = 'DESC';
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_SORT_COLUMN = 'lastModifiedDate';
const DEFAULT_SORT_TYPE = DESC;

const MyDraftsPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useSelector(loadingSlice);
    const applications = useSelector(list);
    const loggedInUser = useSelector(meSlice);
    const error = useSelector(errorSlice);

    const { totalResults, pageSize: size, pageNumber } = applications || {};
    const { impersonatedUser: readOnly } = loggedInUser || {};

    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0); //index of tabs
    const [sortProps, setSortProps] = useState({ sortColumn: DEFAULT_SORT_COLUMN, sortType: DEFAULT_SORT_TYPE });
    const [draftApplicationsList, setDraftApplicationsList] = useState([]);
    const [filtersValues, setFiltersValues] = useState({ searchQuery: '', filters: {} });
    const [requestBodyData, setRequestBodyData] = useState({
        paginationData: {
            page: DEFAULT_PAGE_NUMBER,
            size: DEFAULT_PAGE_SIZE,
        },
        sortingData: {
            sortColumn: sortProps.sortColumn,
            sortType: sortProps.sortType,
        },
        filteringData: filtersParser(filtersValues.filters),
        isDraft: true,
    });
    const [dialog, setDialog] = useState({
        visibility: false,
        message: '',
        onConfirmation: () => {},
        confirmButtonText: '',
        cancelButtonText: '',
        onCancel: () => {},
    });

    const handleCloseDialog = () => {
        setDialog({
            visibility: false,
            message: '',
            onConfirmation: () => {},
            confirmButtonText: '',
            cancelButtonText: '',
            onCancel: () => {},
        });
    };

    const deleteApplication = (appId, ipRightType) => {
        setDialog({
            visibility: true,
            message: t('delete.application.failed'),
            onConfirmation: () => handleAcceptDeletion(appId, ipRightType),
            confirmButtonText: t('delete.draft.application'),
            cancelButtonText: t('cancel'),
            onCancel: handleCloseDialog,
        });
    };

    const handleAcceptDeletion = (appId, ipRightType) => {
        const params = {
            requestBody: requestBodyData,
            selectedTab: tabs[selectedTab],
        };
        dispatch({
            type: `${SLICE_NAME}/requestDraftApplicationDelete`,
            payload: {
                appId: appId,
                ipRightType: ipRightType,
                applicationType: tabs[selectedTab],
                actionParams: {
                    type: `${SLICE_NAME}/requestDraftApplications`,
                    payload: { params },
                },
            },
        });
        handleCloseDialog();
    };

    const resumeApplication = (appId, ipRightType) => {
        dispatch({ type: `${SLICE_NAME}/requestResumeDraftApplication`, payload: { appId, ipRightType, applicationType: tabs[selectedTab] } });
    };

    const lockApplication = (appId, ipRightType) => {
        const params = {
            requestBody: requestBodyData,
            selectedTab: tabs[selectedTab],
        };
        dispatch({
            type: `${SLICE_NAME}/requestLockDraftApplication`,
            payload: {
                appId: appId,
                ipRightType: ipRightType,
                applicationType: tabs[selectedTab],
                actionParams: {
                    type: `${SLICE_NAME}/requestDraftApplications`,
                    payload: { params },
                },
            },
        });
    };

    const unlockApplication = (appId, ipRightType) => {
        const params = {
            requestBody: requestBodyData,
            selectedTab: tabs[selectedTab],
        };
        dispatch({
            type: `${SLICE_NAME}/requestUnlockDraftApplication`,
            payload: {
                appId: appId,
                ipRightType: ipRightType,
                applicationType: tabs[selectedTab],
                actionParams: {
                    type: `${SLICE_NAME}/requestDraftApplications`,
                    payload: { params },
                },
            },
        });
    };

    const duplicateApplication = (appId) => {
        dispatch({ type: `${APP_SLICE_NAME}/requestDuplicateApplication`, payload: { appId, applicationType: tabs[selectedTab] } });
    };

    const handleChangePage = (event, newPage) => {
        event.preventDefault();
        if (newPage !== pageNumber) {
            setRequestBodyData({
                ...requestBodyData,
                paginationData: {
                    page: newPage,
                    size: size,
                },
            });
        }
    };

    const handleChangeRowsPerPage = (event) => {
        const pageSize = event.target.value;
        if (size !== pageSize) {
            setRequestBodyData({
                ...requestBodyData,
                paginationData: {
                    page: 0,
                    size: pageSize,
                },
            });
        }
    };

    const handleTabChange = (event, value) => {
        event.preventDefault();
        setFiltersValues({ searchQuery: '', filters: {} });
        setSortProps({ sortColumn: DEFAULT_SORT_COLUMN, sortType: DEFAULT_SORT_TYPE });
        setRequestBodyData({
            ...requestBodyData,
            paginationData: {
                page: DEFAULT_PAGE_NUMBER,
                size: DEFAULT_PAGE_SIZE,
            },
            sortingData: {
                sortColumn: DEFAULT_SORT_COLUMN,
                sortType: DEFAULT_SORT_TYPE,
            },
            filteringData: {},
            isDraft: true,
        });
        setSelectedTab(value);
    };

    const clearFilters = () => {
        setFiltersValues({ ...filtersValues, filters: {}, filtersChips: {} });
    };

    const exportApplications = (type) => {
        const exportedColumns = myDraftsExportColumns(tabs[selectedTab]);
        const params = {
            requestBody: {
                ...requestBodyData,
                columns: type === 'excel' ? exportedColumns : [],
                isDraft: true,
            },
            applicationType: tabs[selectedTab],
            type: type ? type : 'excel',
        };
        if (!params.requestBody.columns.length) delete params.requestBody.columns; // remove columns attribute if empty
        dispatch({ type: `${SLICE_NAME}/requestMyDraftsExport`, payload: { params } });
    };

    useEffect(() => {
        const { authorities } = loggedInUser;
        const allowedTabs = [];
        if (userCan(authorities, 'ROLE_TRADEMARKS')) allowedTabs.push(TRADEMARKS);
        if (userCan(authorities, 'ROLE_DESIGNS')) allowedTabs.push(DESIGNS);

        if (allowedTabs.length > 0) {
            allowedTabs.push(ESERVICES);
            setTabs(allowedTabs); //set tabs depending on tabs allowed based on authorities role
        } else {
            history.push('/');
        }
    }, [loggedInUser, history]);

    useEffect(() => {
        if (tabs.length > 0 && !loading) {
            const params = {
                requestBody: requestBodyData,
                selectedTab: tabs[selectedTab],
            };
            dispatch({ type: `${SLICE_NAME}/requestDraftApplications`, payload: { params } });
        }
    }, [dispatch, tabs, requestBodyData]);

    useEffect(() => {
        if (filtersValues.searchQuery) {
            setRequestBodyData({
                ...requestBodyData,
                paginationData: { ...requestBodyData.paginationData, page: 0 },
                filteringData: filtersParser(filtersValues.filters),
                searchingData: filtersValues.searchQuery,
            });
        } else {
            const bodyData = { ...requestBodyData };
            delete bodyData['searchingData'];
            setRequestBodyData({
                ...bodyData,
                paginationData: { ...bodyData.paginationData, page: 0 },
                filteringData: filtersParser(filtersValues.filters),
            });
        }
    }, [filtersValues]);

    useEffect(() => {
        if (tabs.length > 0) {
            const draftAppsList = draftApplicationsParserList(
                applications || [],
                tabs[selectedTab],
                deleteApplication,
                resumeApplication,
                lockApplication,
                unlockApplication,
                duplicateApplication,
                readOnly
            );
            setDraftApplicationsList(draftAppsList);
        }
    }, [applications, tabs, selectedTab]);

    useEffect(() => {
        if (!!error) {
            const messageModal =
                error.code === 403 ? t(error.description, { username: error.details.username, message: error.details.message }) : t(error);
            setDialog({
                visibility: true,
                message: messageModal,
                onConfirmation: handleCloseDialog,
                confirmButtonText: t('ok'),
                cancelButtonText: '',
                onCancel: handleCloseDialog,
            });
        }
    }, [error]);

    const setSorting = (sortColumn) => {
        const isSameKey = sortColumn === sortProps.sortColumn;
        const oppositeSort = sortProps.sortType === ASC ? DESC : ASC;
        const sortType = !isSameKey ? ASC : oppositeSort;
        setSortProps({ sortColumn, sortType });
        setRequestBodyData({
            ...requestBodyData,
            paginationData: {
                page: DEFAULT_PAGE_NUMBER,
                size: size,
            },
            sortingData: {
                sortColumn: sortColumn,
                sortType: sortType,
            },
        });
    };

    const customPagination = (
        <CustomPagination
            defaultPageSize={DEFAULT_PAGE_SIZE}
            totalElements={totalResults}
            size={size || DEFAULT_PAGE_SIZE}
            page={pageNumber || DEFAULT_PAGE_NUMBER}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            paginationId={'myDrafts'}
        />
    );

    return (
        <div className={classes.container}>
            {/* title */}
            <PageTitle title={t('my.draft.applications')} details={t('my.draft.application.description')} />

            {/* tabs */}
            <Tabs className={classes.tabs} value={selectedTab} onChange={handleTabChange} textColor="primary">
                {tabs.map((tab, index) => (
                    <Tab id={`tab-${tab}-${index}`} key={`tab-${index}`} label={t(tab + '.tab.title')} />
                ))}
            </Tabs>

            {/* list */}
            <div className={classes.cardTable}>
                <Card canEdit={false}>
                    {/* filters - search  */}
                    <ApplicationFilters
                        applicationType={tabs[selectedTab]}
                        filtersValues={filtersValues}
                        setFiltersValues={setFiltersValues}
                        clearFilters={clearFilters}
                        requestBodyData={requestBodyData}
                        exportApplications={exportApplications}
                        filterItems={filterItems}
                        datesOptions={'draftDates'}
                    />

                    {/** list */}
                    {!loading ? (
                        <React.Fragment>
                            <ApplicationsTable
                                setSorting={setSorting}
                                sortBy={sortProps.sortColumn}
                                sortType={sortProps.sortType}
                                applications={draftApplicationsList}
                                tableId={'myDrafts'}
                            />
                            {customPagination}
                        </React.Fragment>
                    ) : (
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                </Card>
            </div>

            <WarningModal
                visibility={dialog.visibility}
                message={dialog.message}
                onConfirmation={dialog.onConfirmation}
                confirmButtonText={dialog.confirmButtonText}
                cancelButtonText={dialog.cancelButtonText}
                onCancel={dialog.onCancel}
            />
        </div>
    );
};

export default MyDraftsPage;
