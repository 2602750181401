import { sendRequest } from '../../common/utils';

export const fetchCorrespondence = ({ params }) => {
    const { requestBody, selectedTab } = params;
    const searchType = selectedTab !== 'all' ? (selectedTab + 's').toUpperCase() : null;
    const url = '/correspondences/message/search';
    return sendRequest({
        url,
        method: 'POST',
        prefix: '/backend',
        body: {
            ...requestBody,
            searchType: searchType,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const fetchNewCorrespondencesCount = () => {
    const url = '/correspondences/message/incoming/new';
    return sendRequest({
        url,
        method: 'GET',
        prefix: '/backend',
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const fetchExpiringCorrespondences = ({ params }) => {
    const { requestBody } = params;
    const url = '/correspondences/message/expiring';
    return sendRequest({
        url,
        method: 'POST',
        prefix: '/backend',
        body: requestBody,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const deleteDraft = (params) => {
    const { draftId } = params;
    const url = `/correspondences/draft/${draftId}`;
    return sendRequest({
        url,
        method: 'DELETE',
        prefix: '/backend',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code, details: error.details } };
        });
};

export const requestDownloadOutgoingDraft = (params) => {
    const { corrId } = params;
    const url = `/correspondences/download/draft/${corrId}`;
    return sendRequest({
        url,
        method: 'GET',
        prefix: '/backend',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};


export const updateMessageImportance = (payload) => {
    console.log('updateMessageImportance', payload);
    const { messageId, important } = payload;
    const url = `/correspondences/message/${messageId}`;
    return sendRequest({
        url,
        method: 'PATCH',
        prefix: '/backend',
        returnJSON: false,
        body: [{
            op: 'replace',
            path: '/important',
            value: important,
        }],
    })
    .then((response) => {
        return response;
    })
    .catch((error) => {
        return { error: { description: 'something.went.wrong', code: error.code } };
    });
};
    
export const updateMessageStatus = (payload) => {
    console.log('updateMessageStatus', payload);
    const { messageId, messageStatus } = payload;
    const url = `/correspondences/message/${messageId}`;
    return sendRequest({
        url,
        method: 'PATCH',
        prefix: '/backend',
        returnJSON: false,
        body: [{
            op: 'replace',
            path: '/messageStatus',
            value: messageStatus,
        }],
    })
    .then((response) => {
        return response;
    })
    .catch((error) => {
        return { error: { description: 'something.went.wrong', code: error.code } };
    });
};