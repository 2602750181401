import React, { } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as ListArrowIcon } from '../../../../assets/images/arrow_right_24px.svg';
import { t } from '../../../../providers';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: theme.typography.titleFontWeight,
        fontSize: theme.typography.titleFontSize,
        color: theme.palette.text.outlined,
    },
    buttonGroup: {
        marginTop: '40px',
    },
    fieldsList: {
        marginBottom: '20px',
        wordBreak: 'break-word',
    },
    listItem: {
        marginTop: '10px',
        paddingLeft: '8px',
    },
    listIcon: {
        '& path': {
            fill: theme.palette.text.listgrey,
        },
    },
    checkbox: {
        marginLeft: '-6px',
    },
    checkboxLabel: {
        color: '#1E88E5',
        fontSize: '14px',
        textDecoration: 'underline',
        marginLeft: '4px',
        marginRight: '10px',
    },
    editButton: {
        minWidth: '54px',
        marginLeft: '0',
        marginRight: '18px',
    },
    submitButton: {
        minWidth: '100px',
    },
}));

const StepThree = ({ accountDetails = [], backToStepTwo, proceedToNextStep }) => {
    const classes = useStyles();

    const submitStep = (event) => {
        event.preventDefault();
        proceedToNextStep();
    };

    return (
        <div>
            <Typography className={classes.title}>{t('your.account.details')}</Typography>
            <List disablePadding className={classes.fieldsList}>
                {accountDetails.map((value, index) => (
                    <ListItem key={`${value}_${index}`} className={classes.listItem}>
                        <ListItemIcon>
                            <ListArrowIcon className={classes.listIcon} />
                        </ListItemIcon>
                        <ListItemText primary={value}></ListItemText>
                    </ListItem>
                ))}
            </List>
            <Typography className={classes.title}>{t('data.treatment.policy')}
                <a href="https://vpb.lrv.lt/asmens-duomenu-apsauga" target="_blank"> {t('here')}</a>
            </Typography>
            <Grid container spacing={0} className={classes.buttonGroup}>
                <Grid item></Grid>
                <Grid item>
                    <Button variant="outlined" type="button" className={classes.editButton} onClick={backToStepTwo}>
                        {t('back')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant={'outlined'} type="button" className={classes.submitButton}  onClick={submitStep} >
                        {t('next')}
                    </Button>
                </Grid>
            </Grid>{' '}
        </div>
    );
};

export default StepThree;
