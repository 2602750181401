import { createSlice } from '@reduxjs/toolkit';

import { SLICE_NAME } from './constants';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_SORT_COLUMN = 'RECEIVED_DATE';
const DEFAULT_SORT_TYPE = 'DESC';

export const slice = createSlice({
    name: SLICE_NAME,
    initialState: {
        list: null,
        error: null,
        loading: false,
        listParameters: {
            requestPage: DEFAULT_PAGE_NUMBER,
            size: DEFAULT_PAGE_SIZE,
            sort: DEFAULT_SORT_COLUMN,
            sortType: DEFAULT_SORT_TYPE,
            searchQuery: '',
            filters: {},
            filtersChips: {},
            selectedTab: '',
        },
        newMessages: null,
        expiringMessages: null,
    },
    reducers: {
        list: (state, action) => {
            state.list = action.payload;
        },
        loading: (state, action) => {
            state.loading = action.payload;
        },
        error: (state, action) => {
            state.error = action.payload;
        },
        listParameters: (state, action) => {
            state.listParameters = action.payload;
        },
        newMessages: (state, action) => {
            state.newMessages = action.payload;
        },
        expiringMessages: (state, action) => {
            state.expiringMessages = action.payload;
        },
    },
});

export const list = (state) => state[SLICE_NAME].list;
export const loading = (state) => state[SLICE_NAME].loading;
export const error = (state) => state[SLICE_NAME].error;
export const listParameters = (state) => state[SLICE_NAME].listParameters;
export const newMessages = (state) => state[SLICE_NAME].newMessages;
export const expiringMessages = (state) => state[SLICE_NAME].expiringMessages;
export default slice.reducer;
