import { all, call, put, takeLatest } from 'redux-saga/effects';

import { downloadFile } from '../../common/utils/service';
import { getDraftReceipt, deleteApp } from '../MyApplicationsPage/service';
import { SLICE_NAME } from './constants';
import {fetchShoppingCarts, payApp, submitApp} from './service';

function* requestShoppingCarts(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(fetchShoppingCarts, {
        ...action.payload,
    });
    if (!response.error) {
        yield put({
            type: `${SLICE_NAME}/shoppingCartList`,
            payload: response,
        });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* getShoppingCarts() {
    yield takeLatest(`${SLICE_NAME}/getShoppingCarts`, requestShoppingCarts);
}

function* requestDeleteApplication(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(deleteApp, {
        ...action.payload,
    });
    if (!response.error) {
        const params = { ...action.payload.params.sortProps };
        yield call(requestShoppingCarts, { payload: { params } });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.code === 403 ? response.error : response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* deleteApplicationPayments() {
    yield takeLatest(`${SLICE_NAME}/deleteApplicationPayments`, requestDeleteApplication);
}

function* requestSubmitWithoutPayment(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(submitApp, {
        ...action.payload,
    });
    if (!response.error) {
        const params = { ...action.payload.params.sortProps };
        yield call(requestShoppingCarts, { payload: { params } });
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.code === 403 ? response.error : response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* submitWithoutPayment() {
    yield takeLatest(`${SLICE_NAME}/submitWithoutPayment`, requestSubmitWithoutPayment);
}

function* requestDownloadDraftReceipt(action) {
    yield put({ type: `${SLICE_NAME}/error`, payload: false });
    const response = yield call(getDraftReceipt, {
        ...action.payload,
    });
    if (!response.error) {
        downloadFile(response);
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.description });
    }
}

function* downloadDraftReceiptPayments() {
    yield takeLatest(`${SLICE_NAME}/downloadDraftReceiptPayments`, requestDownloadDraftReceipt);
}

function* requestPayApplications(action) {
    yield all([put({ type: `${SLICE_NAME}/loading`, payload: true }), put({ type: `${SLICE_NAME}/error`, payload: false })]);
    const response = yield call(payApp, {
        ...action.payload,
    });
    if (!response.error) {
        if (!!response.redirectToPaymentPlatformUrl && !!response.paymentData) {
            yield put({ type: `${SLICE_NAME}/platformUrl`, payload: response.redirectToPaymentPlatformUrl });
            yield put({ type: `${SLICE_NAME}/platformPaymentData`, payload: response.paymentData });
        }
    } else {
        yield put({ type: `${SLICE_NAME}/error`, payload: response.error.code === 403 ? response.error : response.error.description });
    }
    yield put({ type: `${SLICE_NAME}/loading`, payload: false });
}

function* payApplications() {
    yield takeLatest(`${SLICE_NAME}/payApplications`, requestPayApplications);
}

export {
    getShoppingCarts,
    deleteApplicationPayments,
    downloadDraftReceiptPayments,
    payApplications,
    submitWithoutPayment,
};
