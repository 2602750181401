import React, { useEffect, useRef, useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useHistory } from 'react-router';

import { ReactComponent as DownloadIcon } from '../../../../assets/images/download.svg';
import Accordion from '../../../../components/Accordion';
import { bareDateTimeFormat, t } from '../../../../providers';
import Attachments from '../Attachments';
import Details from '../Details';
import Editor from './Editor';
import Upload from './Upload';

const useStyles = makeStyles((theme) => ({
    messageSection: {
        color: theme.palette.text.greyMain,
        marginBottom: '30px',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingBottom: '10px',
        '@media (max-width:960px)': { marginRight: '10px' },
        '& button': {
            marginLeft: '16px',
        },
    },
    actionIcon: {
        fill: '#6b6c7e',
        '@media (max-width:960px)': {
            padding: '12px',
            '&:hover': { cursor: 'pointer' },
        },
    },
    deleteIcon: {
        color: theme.palette.error.main,
    },
    headerActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        transition: '.2s all ease-in-out',
        '& #preview-btn-header:hover': { cursor: 'pointer' },
        '& svg': {
            paddingLeft: '10px',
        },
    },
    hidden: { opacity: 0 },
}));

const Draft = ({
    isTablet,
    content = {},
    parentInfo,
    open,
    setOpen,
    previewPdf,
    onSave,
    onSend,
    onDelete,
    autoSaveInterval,
    forceSave = false,
    downloadAttachment,
    deleteAttachment,
    readOnly,
}) => {
    const classes = useStyles();
    const history = useHistory();

    const [expanded, setExpanded] = useState(false);
    const [editorContentHtml, setEditorContentHtml] = useState('');
    const [contentChanged, setContentChanged] = useState(false);
    const { id, actionDate, userFullNameAndEmail, body: messageBody, messageSearchAttachment: attachments } = content;
    const { applicationNumber, dossierType, messageId } = parentInfo;
    const replyRef = useRef(null);
    console.log('Reply parentInfo', parentInfo);

    const handleFieldsView = (fieldvalue, textValue) => (fieldvalue ? textValue || fieldvalue : '');

    const contentEditor = useRef(editorContentHtml);
    useEffect(() => {
        contentEditor.current = editorContentHtml;
    }, [editorContentHtml]);

    const handleAutoSave = () => {
        if (!readOnly) {
            onSave(id, contentEditor.current);
            setContentChanged(false);
        }
    };

    useEffect(() => {
        const saveOnInterval = () => {
            if (expanded && contentChanged) {
                handleAutoSave();
            }
        };

        const interval = setInterval(() => saveOnInterval(), autoSaveInterval * 1000);
        return () => {
            clearInterval(interval);
        };
    }, [expanded, contentChanged]);

    useEffect(() => {
        setExpanded(open);
        if (open) replyRef.current.scrollIntoView();
    }, [open]);

    useEffect(() => {
        if (forceSave) {
            handleAutoSave();
            history.push({ pathname: '/correspondence' });
        }
    }, [forceSave]);

    const handleChange = () => {
        if (expanded) {
            setOpen('');
            if (contentChanged) handleAutoSave();
        }
        setExpanded(!expanded);
    };

    const details = isTablet ? (
        <Grid container spacing={2}>
            <Grid item xs={10}>
                <Details label={`${t('draft.date')}:`} value={handleFieldsView(actionDate, bareDateTimeFormat(actionDate))} />
                <Details label={`${t('sender')}:`} value={handleFieldsView(userFullNameAndEmail)} />
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <div className={classes.actions}>
                    {/* 
                    <DownloadIcon className={classes.actionIcon} id={'previewBtn'} onClick={() => previewPdf(id)} />
                    */}
                    {!readOnly && <DeleteOutlinedIcon className={classes.actionIcon} id={'deleteBtn'} onClick={() => onDelete(id)} />}
                </div>
            </Grid>
        </Grid>
    ) : (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <Details label={`${t('draft.date')}:`} value={handleFieldsView(actionDate, bareDateTimeFormat(actionDate))} />
            </Grid>
            <Grid item xs={12} md={4}>
                <Details label={`${t('sender')}:`} value={handleFieldsView(userFullNameAndEmail)} />
            </Grid>
            <Grid item xs={12} md={4} align={'right'}>
                <div className={[classes.headerActions, expanded ? classes.hidden : ''].join(' ')}>
                    {/*
                    <Tooltip arrow title={t('preview')} placement={'top'}>
                        <GetAppOutlinedIcon id={'preview-btn-header'} aria-label={'preview'} onClick={() => previewPdf(id)} color={'secondary'} />
                    </Tooltip>
                    */}
                </div>
            </Grid>
        </Grid>
    );

    return (
        <Accordion
            replyRef={replyRef}
            expanded={expanded}
            handleChange={handleChange}
            title={`${t('reply.noun')} - ${handleFieldsView(t('correspondence.category.' + dossierType))} - ${handleFieldsView(applicationNumber)}`}
            id={`reply${id}`}
            visibleDetails={details}>
            <div>
                {/* message */}
                <div className={classes.messageSection}>
                    <Editor
                        data={messageBody}
                        controls={['bold', 'italic', 'underline', 'numberList', 'bulletList']}
                        contentHTML={editorContentHtml}
                        setContentHTML={setEditorContentHtml}
                        setContentChanged={setContentChanged}
                        readOnly={readOnly}
                        uploadComponent={<Upload draftId={id} messageId={messageId} readOnly={readOnly} beforeUpload={handleAutoSave} />}
                    />
                </div>

                {/* attachments */}
                {attachments && !!attachments.length && (
                    <Attachments attachments={attachments} handleDownload={downloadAttachment} handleDelete={deleteAttachment} readOnly={readOnly} />
                )}
            </div>

            {/* actions */}
            <div className={classes.actions}>
                {!isTablet && (
                    <React.Fragment>
                        <Button
                            aria-label={'delete'}
                            id={'delete-btn'}
                            className={classes.closeButton}
                            startIcon={<DeleteOutlinedIcon />}
                            onClick={() => onDelete(id)}
                            disabled={readOnly}>
                            {t('delete.draft')}
                        </Button>
                        {/*
                        <Button
                            aria-label={'preview'}
                            id={'preview-btn'}
                            className={classes.closeButton}
                            endIcon={<GetAppOutlinedIcon />}
                            onClick={() => previewPdf(id)}>
                            {t('preview')}
                        </Button>
                        */}
                    </React.Fragment>
                )}
                <Button
                    aria-label={'send'}
                    id={'send-btn'}
                    className={classes.closeButton}
                    variant={'outlined'}
                    onClick={() => onSend(id, editorContentHtml)}
                    disabled={readOnly || !editorContentHtml}>
                    {t('send')}
                </Button>
            </div>
        </Accordion>
    );
};

export default Draft;
