import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';

import ApplicationFilters from '../../../../components/Filters/ApplicationFilters';
import FeedbackFormModal from '../../../../components/FeedbackFormModal';
import CustomPagination from '../../../../components/Pagination/CustomPagination';
import WarningModal from '../../../../components/WarningModal';
import {t} from '../../../../providers';
import {me as meSlice} from '../../../AuthenticatedPage/authenticationSlice';
import {SLICE_NAME} from '../../constants';
import filterItems from '../../filterItems';
import {error as errorSlice, loading as loadingSlice, shoppingCartList as list} from '../../paymentsSlice';
import {shoppingCartParserList, summaryShoppingCartParserList} from '../../utils';
import ShoppingCartListTable from './components/ShoppingCartListTable';
import ProceedToPaymentTable from './components/ProceedToPaymentTable';
import PaidInAdvanceTable from './components/PaidInAdvanceTable';
import {confirmPayment} from "../../service";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

const TRADEMARKS = 'trademark';
const DESIGNS = 'design';
const ASC = 'ASC';
const DESC = 'DESC';
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_SORT_COLUMN = 'ID';
const DEFAULT_SORT_TYPE = DESC;

const useStyles = makeStyles((theme) => ({
    infoAfterPayment: {
        marginTop: '50px',
        marginBottom: '50px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: theme.typography.fontWeightBold,
    }
}));

const ShoppingCart = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const me = useSelector(meSlice);
    const loading = useSelector(loadingSlice);
    const error = useSelector(errorSlice);
    const shoppingCartApplications = useSelector(list) || [];
    const {impersonatedUser: readOnly} = me || {};

    const [displayFeedbackForm, setDisplayFeedbackForm] = useState(false);
    const [availableAuthorities, setAvailableAuthorities] = useState([]);
    const [shoppingCartList, setShoppingCartList] = useState([]);
    const [summaryShoppingCartList, setSummaryShoppingCartList] = useState([]);
    const [filtersValues, setFiltersValues] = useState({filters: {}, filtersChips: {}});
    const [sortProps, setSortProps] = useState({sortColumn: DEFAULT_SORT_COLUMN, sortType: DEFAULT_SORT_TYPE});
    const [totalListElements, setTotalListElements] = useState(0);
    const [number, setNumber] = useState(0);
    const [size, setSize] = useState(DEFAULT_PAGE_SIZE);
    const [selectedApplications, setSelectedApplications] = useState([]);
    const [totalCost, setTotalCost] = useState(0);
    const [proceedToPay, setProceedToPay] = useState(false);
    const [proceedToConfirmPay, setProceedToConfirmPay] = useState(false);
    const [infoAfterBankPayment, setInfoAfterBankPayment] = useState(false);
    const [dialog, setDialog] = useState({
        visibility: false,
        message: '',
        onConfirmation: () => {},
        confirmButtonText: '',
        cancelButtonText: '',
        onCancel: () => {},
    });

    const [paymentCompleted, setPaymentCompleted] = useState(new URLSearchParams(window.location.search).get('paymentCompleted'));

    if (paymentCompleted == 1) {
        setInfoAfterBankPayment(true);
        setDisplayFeedbackForm(true);
        setPaymentCompleted(0);
    }

    const handleCloseDialog = () => {
        setDialog({
            visibility: false,
            message: '',
            onConfirmation: () => {},
            confirmButtonText: '',
            cancelButtonText: '',
            onCancel: () => {},
        });
        if (error) dispatch({type: `${SLICE_NAME}/error`, payload: false});
    };

    const handleSelectAllApplications = (event) => {
        if (event.target.checked) {
            let toPush = [];
            let total = 0;
            shoppingCartApplications.map((application) => {
                toPush.push(application.filingNumber);
                const cartFee = application.fees || 0;
                total = +(total + cartFee);
            });
            setSelectedApplications(toPush);
            setTotalCost(total);
        } else {
            setSelectedApplications([]);
            setTotalCost(0);
        }
    };

    const handleSelectApplication = (event, num, cost, summary = false) => {
        const selectedIndex = selectedApplications.indexOf(num);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedApplications, num);
            const updatedTotalCost = +(totalCost + cost);
            setTotalCost(updatedTotalCost);
        } else {
            if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedApplications.slice(1));
            } else if (selectedIndex === selectedApplications.length - 1) {
                newSelected = newSelected.concat(selectedApplications.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selectedApplications.slice(0, selectedIndex), selectedApplications.slice(selectedIndex + 1));
            }
            const updatedTotalCost = +(totalCost - cost);
            setTotalCost(updatedTotalCost);
        }
        if (!newSelected.length && summary) {
            setProceedToPay(false);
            setProceedToConfirmPay(false);
        }
        setSelectedApplications(newSelected);
    };

    const setSorting = (sortColumn) => {
        const isSameKey = sortColumn === sortProps.sortColumn;
        const oppositeSort = sortProps.sortType === ASC ? DESC : ASC;
        const sortType = !isSameKey ? ASC : oppositeSort;
        setSortProps({sortColumn, sortType});
    };

    const handleChangePage = (event, newPage) => {
        event.preventDefault();
        if (newPage !== number) {
            setNumber(newPage);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        const pageSize = event.target.value;
        if (size !== pageSize) {
            setSize(pageSize);
            setNumber(0);
        }
    };

    const clearFilters = () => {
        setFiltersValues({...filtersValues, filters: {}, filtersChips: {}});
    };

    const proceedToPayment = () => {
        if (isOverdueApplicationSelected()) {
            setDialog({
                visibility: true,
                message: t('pay.application.overdue.warning'),
                onConfirmation: handleCloseDialog,
                confirmButtonText: t('ok'),
                cancelButtonText: '',
                onCancel: () => {
                },
            });
        } else {
            setProceedToPay(!proceedToPay);
        }
    };

    const closeInAdvancePayment = (params) => {
        if (!!params.paymentCompleted) {
            setDisplayFeedbackForm(true);
        }
        setProceedToConfirmPay(!proceedToConfirmPay);
    }

    const proceedToConfirmPayment = () => {
        setProceedToConfirmPay(!proceedToConfirmPay);
    }

    const handlePay = (paymentReference, additionalEmail) => {
        const params = {
            filingNumbers: selectedApplications,
            paymentReference,
            additionalEmail,
        };
        dispatch({type: `${SLICE_NAME}/payApplications`, payload: {params}});
    };

    const handleConfirm = (params) => {
        params.filingNumbers = selectedApplications;
        return confirmPayment({params})
    }

    const downloadDraftReceipt = (appNumber) => {
        dispatch({type: `${SLICE_NAME}/downloadDraftReceiptPayments`, payload: {params: {appNumber}}});
    };

    const handleDeleteApplication = (filingNumber) => {
        const params = {filingNumber, sortProps};
        dispatch({type: `${SLICE_NAME}/deleteApplicationPayments`, payload: {params}});
        handleCloseDialog();
    };

    const deleteApplication = (filingNumber) => {
        setDialog({
            visibility: true,
            message: t('delete.application'),
            onConfirmation: () => handleDeleteApplication(filingNumber),
            confirmButtonText: t('delete'),
            cancelButtonText: t('cancel'),
            onCancel: handleCloseDialog,
        });
    };

    const handleSubmitWithoutPayment = (filingNumber) => {
        const params = {filingNumber, sortProps};
        dispatch({type: `${SLICE_NAME}/submitWithoutPayment`, payload: {params}});
        handleCloseDialog();
    }

    const submitWithoutPayment = (filingNumber) => {
        setDialog({
            visibility: true,
            message: t('submit.without.payment.warning'),
            onConfirmation: () => handleSubmitWithoutPayment(filingNumber),
            confirmButtonText: t('ok'),
            cancelButtonText: t('cancel'),
            onCancel: handleCloseDialog,
        });
    }

    const onFeedbackFormSubmit = (event) => {
        event.preventDefault();
        setDisplayFeedbackForm(false);
        dispatch({type: `${SLICE_NAME}/getShoppingCarts`, payload: {params: {...sortProps}}});
    };

    const onFeedbackFormCancel = (event) => {
        event.preventDefault();
        setDisplayFeedbackForm(false);
        dispatch({type: `${SLICE_NAME}/getShoppingCarts`, payload: {params: {...sortProps}}});
    };

    const isOverdueApplicationSelected = () => {
        let now = (new Date()).setHours(0, 0, 0, 0);
        let overdueApplication = shoppingCartApplications.find((app) => !!app.lastSubmitDate
            && selectedApplications.includes(app.filingNumber)
            && now > new Date(app.lastSubmitDate).setHours(0, 0, 0, 0));
        return !!overdueApplication;
    };

    useEffect(() => {
        const {authorities} = me;
        const allowedTypes = [];
        if (authorities && authorities.includes('ROLE_TRADEMARKS')) allowedTypes.push(TRADEMARKS);
        if (authorities && authorities.includes('ROLE_DESIGNS')) allowedTypes.push(DESIGNS);
        if (allowedTypes.length > 0) {
            setAvailableAuthorities(allowedTypes);
        } else {
            history.push('/');
        }
    }, [me, history]);

    useEffect(() => {
        const params = {...sortProps};
        dispatch({type: `${SLICE_NAME}/getShoppingCarts`, payload: {params}});
    }, [dispatch, sortProps]);

    useEffect(() => {
        if (shoppingCartApplications && shoppingCartApplications.length > 0) {
            let toPush = [];
            shoppingCartApplications.map((application) => {
                toPush.push(application.filingNumber);
            });
            setTotalListElements(toPush.length);
        }
    }, [shoppingCartApplications]);

    useEffect(() => {
        const applicationsList = shoppingCartParserList(
            shoppingCartApplications || [],
            handleSelectAllApplications,
            handleSelectApplication,
            downloadDraftReceipt,
            deleteApplication,
            readOnly,
            submitWithoutPayment,
            true,
            false,
            selectedApplications || [],
            filtersValues.filters
        );
        setShoppingCartList(applicationsList);
        setTotalListElements(applicationsList.data.length);

        let forSummary = shoppingCartApplications;
        if (forSummary && forSummary.length > 0) {
            forSummary = forSummary.filter(function (item) {
                return selectedApplications.includes(item.filingNumber);
            });
        }
        const sumShoppingCartList = summaryShoppingCartParserList(forSummary || [], handleSelectApplication, false);
        setSummaryShoppingCartList(sumShoppingCartList);
    }, [shoppingCartApplications, selectedApplications, filtersValues]);

    useEffect(() => {
        setNumber(0);
    }, [filtersValues]);

    useEffect(() => {
        if (!!error) {
            let messageModal;
            if (error.code === 403) {
                messageModal = t(error.description, {username: error.details.username});
            } else {
                if (error.externalError) messageModal = `${t(error.message)} ${error.externalError}`;
                else messageModal = t(error);
            }
            setDialog({
                visibility: true,
                message: messageModal,
                onConfirmation: handleCloseDialog,
                confirmButtonText: t('ok'),
                cancelButtonText: '',
                onCancel: handleCloseDialog,
            });
        }
    }, [error]);

    const shoppingCartFilters = (
        <ApplicationFilters
            filtersValues={filtersValues}
            setFiltersValues={setFiltersValues}
            clearFilters={clearFilters}
            filterItems={filterItems}
            authorities={availableAuthorities}
            filterType={'eservicesTypes'}
            searchEnabled={false}
        />
    );

    const customPagination = (
        <CustomPagination
            defaultPageSize={DEFAULT_PAGE_SIZE}
            totalElements={totalListElements}
            size={size || DEFAULT_PAGE_SIZE}
            page={number || DEFAULT_PAGE_NUMBER}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            paginationId={'shoppingCart'}
        />
    );

    return (
        <div>
            {infoAfterBankPayment && (
                <Grid className={classes.infoAfterPayment}>
                    {t('info.after.bank.payment')}
                </Grid>
            )}
            <div>
                {!proceedToPay && !proceedToConfirmPay && (
                    <ShoppingCartListTable
                        loading={loading}
                        setSorting={setSorting}
                        sortProps={sortProps}
                        shoppingCartList={shoppingCartList}
                        totalCost={totalCost}
                        size={size}
                        number={number}
                        customPagination={customPagination}
                        proceedToPayment={proceedToPayment}
                        proceedToConfirmPayment={proceedToConfirmPayment}
                        filters={shoppingCartFilters}
                        selectedApplications={selectedApplications}
                        readOnly={readOnly}
                    />
                )}
                {proceedToPay && (
                    <ProceedToPaymentTable
                        loading={loading}
                        summaryShoppingCartList={summaryShoppingCartList}
                        selectedApplications={selectedApplications}
                        totalCost={totalCost}
                        setSorting={setSorting}
                        sortProps={sortProps}
                        onPay={handlePay}
                        onCancelProceedToPay={proceedToPayment}
                        readOnly={readOnly}
                    />
                )}
                {proceedToConfirmPay && (
                    <PaidInAdvanceTable
                        loading={loading}
                        shoppingCartApplications={shoppingCartApplications}
                        summaryShoppingCartList={summaryShoppingCartList}
                        selectedApplications={selectedApplications}
                        totalCost={totalCost}
                        setSorting={setSorting}
                        sortProps={sortProps}
                        onConfirm={handleConfirm}
                        onCancelConfirm={closeInAdvancePayment}
                        readOnly={readOnly}
                    />
                )}

                {/* dialog */}
                <WarningModal
                    visibility={dialog.visibility}
                    message={dialog.message}
                    onConfirmation={dialog.onConfirmation}
                    confirmButtonText={dialog.confirmButtonText}
                    cancelButtonText={dialog.cancelButtonText}
                    onCancel={dialog.onCancel}
                />
                {/* feedback form window */}
                {displayFeedbackForm && (
                    <FeedbackFormModal onSubmit={onFeedbackFormSubmit} onCancel={onFeedbackFormCancel}/>)}
            </div>
        </div>
    );
};

export default ShoppingCart;
