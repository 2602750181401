import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { t } from '../../providers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { GLOBALS_SLICE_NAME } from '../../app/constants';
import TextArea from "../TextArea";
import { FormGroup } from '@material-ui/core';

const useStyles = makeStyles((_theme) => ({
    okButton: {
        minWidth: '66px',
    },
    formLabel: {
        paddingTop: '10px',
    },
    dialogOverride: {
        '& .MuiDialogContent-root': { maxHeight: 800 },
    },
}));

const FeedbackFormModal = ({
    onSubmit,
    onCancel,
}) => {
    const classes = useStyles();
    const [evaluationRate, setEvaluationRate] = useState(null);
    const [reasons, setReasons] = useState([false, false, false, false, false, false]);
    const [complaints, setComplaints] = useState('');
    const [comments, setComments] = useState('');
    const [suggestions, setSuggestions] = useState('');
    const [userContacts, setUserContacts] = useState('');
    const [evaluationRateError, setEvaluationRateError] = useState(false);
    const [reasonMissingError, setReasonMissingError] = useState(false);
    const dispatch = useDispatch();

    const handleEvaluationRateChange = (event) => {
        setEvaluationRate(event.target.value);
    };

    const handleReasonChange = (event) => {
        let _reasons = [... reasons];
        eval(event.target.name + ' = ' + event.target.checked);
        setReasons(_reasons);
    };

    const handleComplaintsChange = (event) => {
        setComplaints(event.target.value);
    };

    const handleCommentsChange = (event) => {
        setComments(event.target.value);
    };

    const handleSuggestionsChange = (event) => {
        setSuggestions(event.target.value);
    };

    const handleUserContactsChange = (event) => {
        setUserContacts(event.target.value);
    };

    const _onSubmit = (event) => {
        setEvaluationRateError(!evaluationRate);
        const reasonMissing = evaluationRate >= 1 && evaluationRate <= 4 && !reasons.includes(true);
        setReasonMissingError(reasonMissing);
        if(!!evaluationRate && !reasonMissing){
            dispatch({ type: `${GLOBALS_SLICE_NAME}/submitUserFeedback`, payload: {
                evaluationRate: evaluationRate,
                reasons: reasons,
                complaints: complaints,
                comments: comments,
                suggestions: suggestions,
                userContacts: userContacts
            } });
            if(!!onSubmit) onSubmit(event);
        }
    };

    return (
        <Dialog
            className={classes.dialogOverride}
            maxWidth='md'
            fullWidth={true}
            open={true}
            aria-labelledby="user-feedback-dialog"
            >
            <DialogTitle>{t('feedback.form.header1')}</DialogTitle>
            <DialogContent>

                <Typography>{t('feedback.form.header2')}</Typography>

                <div className={classes.formLabel}>
                    <FormLabel error={evaluationRateError}>{t('feedback.form.question1')}</FormLabel>
                </div>
                <RadioGroup row name='evaluationRate' value={evaluationRate} onChange={handleEvaluationRateChange}>
                    <FormControlLabel value="1" control={<Radio size="small"/>} label={'1 - ' +  t('feedback.form.question1.explanation1')} />
                    <FormControlLabel value="2" control={<Radio size="small"/>} label="2"/>
                    <FormControlLabel value="3" control={<Radio size="small"/>} label="3"/>
                    <FormControlLabel value="4" control={<Radio size="small"/>} label="4"/>
                    <FormControlLabel value="5" control={<Radio size="small"/>} label="5"/>
                    <FormControlLabel value="6" control={<Radio size="small"/>} label="6"/>
                    <FormControlLabel value="7" control={<Radio size="small"/>} label={'7 - ' +  t('feedback.form.question1.explanation2')} />
                </RadioGroup>

                { (evaluationRate >= 1 && evaluationRate <= 4) && (
                <>
                <FormLabel error={reasonMissingError}>{t('feedback.form.question2')}</FormLabel>
                <FormGroup row onChange={handleReasonChange}>
                    <FormControlLabel control={<Checkbox name={'_reasons[0]'} checked={reasons[0]} size='small'/>} label={<Typography className={classes.checkboxLabel}>{t('feedback.form.question2.0')}</Typography>} />
                    <FormControlLabel control={<Checkbox name={'_reasons[1]'} checked={reasons[1]} size='small'/>} label={<Typography className={classes.checkboxLabel}>{t('feedback.form.question2.1')}</Typography>} />
                    <FormControlLabel control={<Checkbox name={'_reasons[2]'} checked={reasons[2]} size='small'/>} label={<Typography className={classes.checkboxLabel}>{t('feedback.form.question2.2')}</Typography>} />
                    <FormControlLabel control={<Checkbox name={'_reasons[3]'} checked={reasons[3]} size='small'/>} label={<Typography className={classes.checkboxLabel}>{t('feedback.form.question2.3')}</Typography>} />
                    <FormControlLabel control={<Checkbox name={'_reasons[4]'} checked={reasons[4]} size='small'/>} label={<Typography className={classes.checkboxLabel}>{t('feedback.form.question2.4')}</Typography>} />
                    <FormControlLabel control={<Checkbox name={'_reasons[5]'} checked={reasons[5]} size='small'/>} label={<Typography className={classes.checkboxLabel}>{t('feedback.form.question2.5')}</Typography>} />
                    <FormControlLabel control={<Checkbox name={'_reasons[6]'} checked={reasons[6]} size='small'/>} label={<Typography className={classes.checkboxLabel}>{t('feedback.form.question2.6')}</Typography>} />
                </FormGroup>
                <Typography className={classes.formLabel}>{t('feedback.form.question3')}</Typography>
                <TextArea name={'complaints'} value={complaints} onChange={handleComplaintsChange} rows={1}/>
                </>
                )}

                <Typography className={classes.formLabel}>{t('feedback.form.question4')}</Typography>
                <TextArea name={'comments'} value={comments} rows={4} onChange={handleCommentsChange}/>

                <Typography className={classes.formLabel}>{t('feedback.form.question5')}</Typography>
                <TextArea name={'suggestions'} value={suggestions} rows={4} onChange={handleSuggestionsChange}/>

                <Typography className={classes.formLabel}>{t('feedback.form.question6')}</Typography>
                <TextField name={'userContacts'} value={userContacts} onChange={handleUserContactsChange}/>

            </DialogContent>

            <DialogActions>
                <Button variant={'outlined'} onClick={onCancel}>{t('cancel')}</Button>
                <Button className={classes.okButton} variant={'contained'} onClick={_onSubmit}>{t('submit')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default FeedbackFormModal;
