import { sendRequest } from '../../common/utils';

export const fetchCorrespondenceDetails = ({ params }) => {
    const { corrId, selectedTab, requestBody } = params;
    const searchType = (selectedTab + 's').toUpperCase();
    const url = `/correspondences/message/detail/${corrId}`;
    return sendRequest({
        url,
        method: 'POST',
        prefix: '/backend',
        body: {
            ...requestBody,
            searchType: searchType,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: 'cannot.open.correspondence', code: error.code } };
        });
};

export const initNewDraft = (params) => {
    const { messageId } = params;
    const url = `/correspondences/draft?messageId=${messageId}`;
    return sendRequest({
        url,
        method: 'POST',
        prefix: '/backend',
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code, details: error.details } };
        });
};

export const sendDraft = (params) => {
    const { draftId, messageId, message } = params;
    const url = '/correspondences/draft/send';
    return sendRequest({
        url,
        method: 'POST',
        prefix: '/backend',
        headers: { 'Content-Type': 'multipart/form-data' },
        returnJSON: false,
        body: {
            draftResource: JSON.stringify({ id: draftId, messageId: messageId, body: message }),
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: 'send.draft.failed', code: error.code } };
        });
};

export const deleteDraft = (params) => {
    const { draftId } = params;
    const url = `/correspondences/draft/${draftId}`;
    return sendRequest({
        url,
        method: 'DELETE',
        prefix: '/backend',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: 'something.went.wrong', code: error.code } };
        });
};

export const saveDraft = (params) => {
    const { draftId, messageId, message } = params;
    const url = '/correspondences/draft';
    return sendRequest({
        url,
        method: 'PUT',
        prefix: '/backend',
        // headers: { 'Content-Type': 'multipart/form-data' },
        returnJSON: false,
        body: {
            id: draftId,
            messageId: messageId,
            body: message,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: 'something.went.wrong', code: error.code } };
        });
};

export const downloadIncoming = (params) => {
    const { corrId } = params;
    const url = `/correspondences/download/incoming/${corrId}`;
    return sendRequest({
        url,
        method: 'GET',
        prefix: '/backend',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: 'something.went.wrong', code: error.code } };
        });
};

export const downloadOutgoing = (params) => {
    const { corrId } = params;
    const url = `/correspondences/download/draft/${corrId}`;
    return sendRequest({
        url,
        method: 'GET',
        prefix: '/backend',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: 'something.went.wrong', code: error.code } };
        });
};

export const uploadAttachment = (params) => {
    const { draftId, files } = params;
    const url = `/correspondences/attachments/${draftId}`;
    return sendRequest({
        url,
        method: 'POST',
        prefix: '/backend',
        headers: { 'Content-Type': 'multipart/form-data' },
        body: {
            files: files,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code, details: error.details } };
        });
};

export const deleteAttachment = (params) => {
    const { attachmentId } = params;
    const url = `/correspondences/attachments/${attachmentId}`;
    return sendRequest({
        url,
        method: 'DELETE',
        prefix: '/backend',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: 'something.went.wrong', code: error.code } };
        });
};

export const downloadAttachment = (params) => {
    const { attachmentId } = params;
    const url = `/correspondences/attachments/${attachmentId}`;
    return sendRequest({
        url,
        method: 'GET',
        prefix: '/backend',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: 'something.went.wrong', code: error.code } };
        });
};

export const downloadDraftAttachment = (params) => {
    const { attachmentId } = params;
    const url = `/correspondences/attachments/draft/${attachmentId}`;
    return sendRequest({
        url,
        method: 'GET',
        prefix: '/backend',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: 'something.went.wrong', code: error.code } };
        });
};
