import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ReactComponent as AttachmentIcon } from '../../../../assets/images/attachment_24px.svg';
import { round } from '../../../../common/utils/service';
import VerticalMenu from '../../../../components/VerticalMenu';
import { t } from '../../../../providers';

const useStyles = makeStyles((theme) => ({
    attachmentsSection: {
        '@media (max-width:960px)': {
            marginBottom: '20px',
        },
    },
    title: {
        marginBottom: '8px',
        color: theme.palette.text.grey700,
        fontWeight: '600',
    },
    attachments: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        '@media (max-width:960px)': {
            display: 'block',
            width: '100%',
        },
    },
    file: {
        marginBottom: '10px',
        width: '150px',
        border: '1px solid #BEC4D0',
        borderRadius: '3px',
        marginRight: '20px',
        padding: '8px',
        position: 'relative',
        '@media (max-width:960px)': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: 'auto',
            padding: '5px 20px',
            marginRight: 'unset',
        },
    },

    fileName: {
        fontWeight: '600',
        color: theme.palette.text.blackCommon,
        marginBottom: '8px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '120px',
        '@media (max-width:960px)': {
            marginBottom: 'unset',
            maxWidth: '70%',
        },
    },
    details: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: theme.typography.fontSize,
        color: theme.palette.text.greyMain,
        '@media (max-width:960px)': {
            justifyContent: 'space-between',
        },
    },
    fileSize: { marginRight: '5px' },
    attachmentIcon: {
        transform: 'rotate(-90deg)',
        width: '18px',
        fill: theme.palette.text.greyMain,
    },
    options: {
        position: 'absolute',
        right: '0',
        top: '0',
        '@media (max-width:960px)': {
            position: 'unset',
            right: 'unset',
            top: 'unset',
        },
    },
}));

const Attachments = ({ attachments, handleDownload, handleDelete = null, readOnly = false }) => {
    const classes = useStyles();
    const isTablet = useMediaQuery('(max-width:960px)');

    const convertBytes = (size) => (size / 1000 < 1000 ? `${round(size / 1000, 1)} KB` : `${round(size / (1000 * 1000), 1)} MB`);
    return (
        <div className={classes.attachmentsSection}>
            <div className={classes.title}>{`${t('attached.files')}:`}</div>
            <div className={classes.attachments}>
                {attachments.map(({ id, name, size }, idx) => {
                    const menuVertical = (
                        <div className={classes.options}>
                            {
                                <VerticalMenu
                                    actionButtonId={idx}
                                    menuItems={[
                                        { label: t('download'), action: () => handleDownload(id) },
                                        handleDelete !== null && !readOnly && { label: t('delete'), action: () => handleDelete(id) },
                                    ].filter((item) => typeof item === 'object')}
                                />
                            }
                        </div>
                    );
                    return (
                        <div key={idx} className={classes.file}>
                            <div className={classes.fileName}>{name}</div>
                            <div className={classes.details}>
                                <div className={classes.fileSize}>{convertBytes(size)}</div>
                                <AttachmentIcon className={classes.attachmentIcon} />
                                {isTablet && menuVertical}
                            </div>
                            {!isTablet && menuVertical}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Attachments;
