import React, { useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { GLOBALS_SLICE_NAME } from '../../../app/constants';
import { ReactComponent as InfoIcon } from '../../../assets/images/info_24px.svg';
import { t } from '../../../providers';
import { SLICE_NAME as SSO_SLICE_NAME } from '../../SSOPage/constants';
import helpers from '../../SSOPage/SSOHelpers';
import { response as responseSlice } from '../../SSOPage/ssoSlice';
import { credentials as creds, error as verifyError, loading as isLoading, registration } from '../authenticationSlice';
import AuthContainer from '../components/AuthContainer';
import RequestResult from '../components/RequestResult';
import { SLICE_NAME } from '../constants';
import VerificationForm from './components';
import { Typography } from '@material-ui/core';

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const UsernameChangeVerificationPage = () => {
    const { token } = useParams();
    const credentials = useSelector(creds);
    const history = useHistory();
    const error = useSelector(verifyError);
    const loading = useSelector(isLoading);
    const result = useSelector(registration);
    const response = useSelector(responseSlice);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!token && (!credentials || !credentials.username)) history.push('/login');
    }, [token, credentials, history]);

    useEffect(() => {
        if (!error && result && result.fromAction) {
            const { username, password, challenge } = credentials || {};
            if (result.fromAction === 'verifyUsernameChange' && username && password && result.message !== 'registration.token.not.valid') {
                dispatch({ type: `${SLICE_NAME}/registration`, payload: {} });
                dispatch({ type: `${SSO_SLICE_NAME}/login`, payload: { username, password, challenge } });
            }
        } else {
            console.log('UsernameChangeVerificationPage error', error);
        }

    }, [dispatch, result, credentials, error]);

    useEffect(() => {
        if (response && response.redirect) {
            dispatch({ type: `${SLICE_NAME}/response`, payload: null });
            dispatch({ type: `${GLOBALS_SLICE_NAME}/globalLoading`, payload: true });
            helpers.verifyLoginToSSO(response.redirect);
        }
    }, [response, dispatch]);

    useEffect(() => {
        return () => {
            dispatch({ type: `${SLICE_NAME}/credentials`, payload: {} });
            dispatch({ type: `${SLICE_NAME}/registration`, payload: {} });
            dispatch({ type: `${SLICE_NAME}/error`, payload: null });
        };
    }, [dispatch]);

    const requestNewCode = () => {
        const body = {};
        if (credentials && credentials.username) body.userName = credentials.username;
        if (token) body.token = token;
        if (!body.userName && !body.token) return;
        dispatch({ type: `${SLICE_NAME}/requestUsernameChangeNewVerificationCode`, payload: body });
    };

    const verifyUsernameChange = (payload) => {
        dispatch({ type: `${SLICE_NAME}/verifyUsernameChange`, payload });
    };

    const backToLogin = () => {
        dispatch({ type: `${SLICE_NAME}/credentials`, payload: null });
        dispatch({ type: `${SLICE_NAME}/registration`, payload: {} });
        history.push('/login');
    };

    async function sleepBeforeRedirect() {
        console.log('async function 2');
        for (let i = 0; i < 3; i++) {
            console.log(`Waiting ${i} seconds...`);
            await sleep(i * 1000);
        }
        backToLogin();
    }
    
    let renderBody;
    if (!error && result && result.fromAction === 'requestUsernameChangeNewVerificationCode') {
        renderBody = <Typography>{t('resend.username.change.email.success')}</Typography>;
    } else if (!error && result && result.fromAction === 'verifyUsernameChange') {
        sleepBeforeRedirect();
        renderBody = <RequestResult action={backToLogin} message={'username.change.successful'} button={'back.to.login'} />;
    } else {
        renderBody = (
            <VerificationForm
                token={token}
                error={error}
                requestNewCode={requestNewCode}
                verifyUsernameChange={verifyUsernameChange}
            />
        );
    }

    return (
        <AuthContainer preTitle={<InfoIcon />} title={t('verify.username.change.title')}>
            {loading ? <CircularProgress /> : renderBody}
        </AuthContainer>
    );
};

export default UsernameChangeVerificationPage;
