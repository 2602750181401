import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import { globals as globalsSlice } from '../../../app/globalsSlice';
import { SLICE_NAME } from '../../../pages/MyApplicationsPage/constants';
import { waitingForPaymentCount as waitingForPaymentSlice, waitingForSignatureCount as waitingForSignatureSlice } from '../../../pages/MyApplicationsPage/finalizedApplicationsSlice';

const useStyles = makeStyles((theme) => ({
    count: {
        fontSize: theme.typography.fontSizeSmall,
        fontWeight: theme.typography.fontWeightBold,
        marginLeft: '10px',
        padding: '2px',
        border: '1px solid',
        borderRadius: '50%',
        color: theme.palette.text.mainBlue,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '20px',
        minHeight: '20px',
        maxWidth: '30px',
        maxHeight: '30px',
        position: 'absolute',
        right: 0,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));


const WaitingForPaymentApplicationCountIcon = ({ id = 'count' }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { globals } = useSelector(globalsSlice) || {};
    const applicationCount = useSelector(waitingForPaymentSlice) || 0;

    const CHECK_NEW_APPLICATIONS_INTERVAL = globals?.application['applications.pending-action.counter'] || 60;

    useEffect(() => {
        const updateCountOnInterval = () => {
            dispatch({ type: `${SLICE_NAME}/getWaitingForPaymentApplicationCount` });
        };
        updateCountOnInterval();
        const interval = setInterval(() => updateCountOnInterval(), CHECK_NEW_APPLICATIONS_INTERVAL * 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <React.Fragment>
            {!!applicationCount && (
                <div className={classes.count} id={`${id}-counter`}>
                    <span>{applicationCount}</span>
                </div>
            )}
        </React.Fragment>
    );
};

const WaitingForSignatureApplicationCountIcon = ({ id = 'count' }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { globals } = useSelector(globalsSlice) || {};
    const applicationCount = useSelector(waitingForSignatureSlice) || 0;

    const CHECK_NEW_APPLICATIONS_INTERVAL = globals?.application['applications.pending-action.counter'] || 60;

    useEffect(() => {
        const updateCountOnInterval = () => {
            dispatch({ type: `${SLICE_NAME}/getWaitingForSignatureApplicationCount` });
        };
        updateCountOnInterval();
        const interval = setInterval(() => updateCountOnInterval(), CHECK_NEW_APPLICATIONS_INTERVAL * 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <React.Fragment>
            {!!applicationCount && (
                <div className={classes.count} id={`${id}-counter`}>
                    <span>{applicationCount}</span>
                </div>
            )}
        </React.Fragment>
    );
};


export { 
    WaitingForPaymentApplicationCountIcon,
    WaitingForSignatureApplicationCountIcon,
}
