import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

import PageTitle from '../../../../components/PageTitle';
import MinimalPagination from '../../../../components/Pagination/MinimalPagination';
import { bareDateTimeFormat, t } from '../../../../providers';
import { dueDateParser, formatColor, canReply } from '../../../CorrespondencePage/utils';
import Attachments from '../Attachments';
import Details from '../Details';

const useStyles = makeStyles((theme) => ({
    incoming: {
        fontSize: '14px',
        lineHeight: '20px',
        // marginBottom: '40px',
        marginBottom: '20px',
        paddingBottom: '20px',
    },
    hasReplies: { borderBottom: '1px solid #E2E4E9' },
    detailsSection: { marginTop: '25px' },
    messageSection: {
        margin: '0 0 24px 0',
        height: '150px',
        overflow: 'auto',
        color: theme.palette.text.greyMain,
        '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '3px',
            backgroundColor: theme.palette.scrollbar.main,
        },
    },

    attachments: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    boldText: { fontWeight: 600 },
    closeButton: {},
}));

const Incoming = ({
    content = {},
    downloadAttachment,
    downloadCorrespondence,
    redirectToCorrespondence,
    maxRepliesAllowed,
    onClose,
    onReply,
    initReply,
    readOnly,
}) => {
    const classes = useStyles();
    const { message, subRows } = content;
    const {
        id,
        applicationNumber,
        dossierType,
        subject,
        recipientFullNameAndEmail,
        senderFullNameAndEmail,
        messageStatus: status,
        receivedDate,
        dueDate,
        hasReply,
        requiresReply = true,
        body,
        messageSearchAttachment: attachments,
        previousId,
        index,
        nextId,
        direction
    } = message || {};
    const replies = subRows;

    const pagination = { previousId, index, nextId };

    const [initiateReply, setInitiateReply] = useState(initReply);

    useEffect(() => {
        setInitiateReply(initReply);
    }, [initReply]);

    useEffect(() => {
        if (message && !!Object.keys(message).length && initiateReply) {
            setInitiateReply(false);
            onReply();
        }
    }, [message]);

    const enableReply = (direction !== 'BO_INCOMING') && canReply(dueDate, requiresReply, replies, maxRepliesAllowed, readOnly);

    const handleFieldsView = (fieldvalue, textValue) => (fieldvalue ? textValue || fieldvalue : '');

    const messageBody = (direction === 'BO_INCOMING') 
        ? body 
        : t('correspondence.message.default.content');

    return (
        <div className={[classes.incoming, !!replies?.length ? classes.hasReplies : ''].join(' ')}>
            <PageTitle title={`@ ${handleFieldsView(t('correspondence.category.' + dossierType))} - ${handleFieldsView(applicationNumber)}`} />
            {/* details */}
            <Grid container spacing={2} className={classes.detailsSection}>
                <Grid item xs={4} className={classes.details}>
                    <Details label={`${t('subject')}:`} value={handleFieldsView(subject, <span className={classes.boldText}>{subject}</span>)} />
                    {direction !== 'BO_INCOMING' && (
                        <>
                        <Details label={`${t('recipient')}:`} value={handleFieldsView(recipientFullNameAndEmail)} /> 
                        <Details label={`${t('sender')}:`} value={t('correspondence.vpb')} />
                        </>
                    )}
                    {direction === 'BO_INCOMING' && (
                        <>
                        <Details label={`${t('sender')}:`} value={handleFieldsView(senderFullNameAndEmail)} />
                        <Details label={`${t('recipient')}:`} value={t('correspondence.vpb')} />
                        </>
                    )}
                    <Details label={`${t('status.correspondence')}:`} value={handleFieldsView(status, formatColor(status))} />
                </Grid>
                <Grid item xs={4} className={classes.details}>
                    {direction !== 'BO_INCOMING' && (
                        <Details label={`${t('received.date')}:`} value={handleFieldsView(receivedDate, bareDateTimeFormat(receivedDate))} />
                    )}
                    {direction === 'BO_INCOMING' && (
                        <Details label={`${t('sent.date')}:`} value={handleFieldsView(receivedDate, bareDateTimeFormat(receivedDate))} />
                    )}
               </Grid>
                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <MinimalPagination pagination={pagination} redirectTo={redirectToCorrespondence} />
                </Grid>
            </Grid>

            {/* message */}
            <div className={classes.messageSection} dangerouslySetInnerHTML={{ __html: messageBody }} />

            {/* attachments */}
            {attachments && !!attachments.length && <Attachments attachments={attachments} handleDownload={downloadAttachment} />}
            {/* actions */}
            <div className={classes.actions}>
                {/*
                <Button
                    className={classes.closeButton}
                    id={`btn-download-messageId-${id}`}
                    endIcon={<GetAppOutlinedIcon />}
                    onClick={() => downloadCorrespondence(id)}>
                    {t('download')}
                </Button>
                */}
                <Button
                    className={classes.closeButton}
                    id={`btn-reply-messageId-${id}`}
                    variant={'outlined'}
                    onClick={() => onReply()}
                    disabled={!enableReply}>
                    {t('reply')}
                </Button>

                {!replies?.length && (
                    <Button className={classes.closeButton} id={`btn-close-messageId-${id}`} variant={'contained'} onClick={onClose}>
                        {t('close.and.back')}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Incoming;
