import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { t } from '../../providers';
import { me as meSlice } from '../AuthenticatedPage/authenticationSlice';
import PaymentHistory from '../PaymentHistory';
import ShoppingCart from './components/ShoppingCart';

const useStyles = makeStyles((_theme) => ({
    container: {
        width: '100%',
        height: '100%',
        maxWidth: 'calc(100% - 236px)',
        '@media (max-width:1100px)': {
            minWidth: '77%',
            maxWidth: 'unset',
        },
    },
    tabs: {
        marginBottom: '20px',
    },
    tabContainer: {
        width: '100%',
    },
}));

const SHOPPING_CART = 'shopping-cart';
const PAYMENT_HISTORY = 'history';

const PaymentsPage = (props) => {
    const { tab } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const me = useSelector(meSlice);

    const tabs = [SHOPPING_CART, PAYMENT_HISTORY];

    useEffect(() => {
        const { authorities } = me;
        const checkTabs = !tab || tabs.indexOf(tab) === -1;
        const checkAuthorities = authorities && !authorities.includes('ROLE_PAYMENTS');
        if (checkTabs || checkAuthorities) {
            history.push('/');
        }
    }, [me, history, tab, tabs]);

    const handleTabChange = (event, value) => {
        event.preventDefault();
        history.push(`/payments/${tabs[value]}`);
    };

    const tabIndex = !!~tabs.indexOf(tab) ? tabs.indexOf(tab) : 0;

    return (
        <div className={classes.container}>
            <Tabs className={classes.tabs} value={tabIndex} onChange={handleTabChange} textColor="primary">
                <Tab label={t('shopping.cart')} />
                <Tab label={t('payment.history')} />
            </Tabs>
            <div className={classes.tabContainer}>
                {tab === SHOPPING_CART && <ShoppingCart {...props} />}
                {tab === PAYMENT_HISTORY && <PaymentHistory />}
            </div>
        </div>
    );
};

export default PaymentsPage;
