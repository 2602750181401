import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import ApplicationsTableClientSide from '../../../components/ApplicationsTableClientSide';
import Card from '../../../components/Card';
import PageTitle from '../../../components/PageTitle';
import { t } from '../../../providers';

const useStyles = makeStyles((theme) => ({
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width:1100px)': {
            display: 'block',
        },
    },
    cardTable: {
        marginRight: '20px',
        '@media (max-width:960px)': {
            marginRight: 'unset',
        },
    },
    tableActions: {
        marginTop: '15px',
        color: theme.palette.text.detailsGrey,
        fontSize: theme.typography.fontSize,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '@media (max-width:960px)': {
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
    },
    pagination: {
        marginTop: '80px',
        '@media (max-width:960px)': {
            marginTop: 0,
        },
    },
    tableActionsSummary: {
        marginTop: '30px',
        color: theme.palette.text.detailsGrey,
        fontSize: theme.typography.fontSize,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '@media (max-width:960px)': {
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
    },
}));

const SignaturesListTable = ({
    loading,
    setSorting,
    sortProps,
    signaturesList,
    size,
    number,
    customPagination,
}) => {
    const classes = useStyles();

    const signaturesTable = !loading ? (
        <ApplicationsTableClientSide
            setSorting={setSorting}
            sortBy={sortProps.sortColumn}
            sortType={sortProps.sortType}
            applications={signaturesList}
            tableId={'signaturesTable'}
            size={size}
            page={number}
            noAvailableDataLabel={t('no.signatures')}
        />
    ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
        </Box>
    );

    return (
        <React.Fragment>
            {/* title */}
            <div className={classes.title}>
                <div>
                    <PageTitle title={t('signatures')} details={t('signatures.description')} />
                </div>
            </div>
            <div className={classes.cardTable}>
                <Card canEdit={false}>
                    {signaturesTable}
                    {customPagination}
                </Card>
            </div>
        </React.Fragment>
    );
};

export default SignaturesListTable;
