import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import { ReactComponent as AddIcon } from '../../../../assets/images/plus.svg';
import WarningModal from '../../../../components/WarningModal';
import { t } from '../../../../providers';
import { SLICE_NAME } from '../../constants';

const ACCEPTED_FORMATS = '.PDF, .DOC, .DOCX, .XLS, .XLSX, .PPT, .PPTX, .JPEG, .JPG, .PJPEG, .PNG, .MP3, .MPEG';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

const Upload = ({ draftId, messageId, readOnly, beforeUpload }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({
        visibility: false,
        message: '',
        onConfirmation: () => {},
        confirmButtonText: '',
        cancelButtonText: '',
        onCancel: () => {},
    });

    const handleCancelModalAction = () => {
        setConfirmDialog({
            visibility: false,
            message: '',
            onConfirmation: () => {},
            confirmButtonText: '',
            cancelButtonText: '',
            onCancel: () => {},
        });
    };

    useEffect(() => {
        let validTypes = true;
        if (!!selectedFiles.length) {
            selectedFiles.forEach((file) => {
                if (!isValidType(file)) {
                    validTypes = false;
                }
            });
            if (validTypes) {
                doUpload(draftId, selectedFiles);
            } else {
                setConfirmDialog({
                    visibility: true,
                    message: t('draft.invalide.file.types'),
                    onConfirmation: () => handleCancelModalAction(),
                    confirmButtonText: t('ok'),
                    cancelButtonText: '',
                    onCancel: () => {},
                });
            }
        }
    }, [selectedFiles]);

    const isValidType = (file) => {
        let valid = true;
        let fileType = file.name.split('.').pop();
        if (ACCEPTED_FORMATS.indexOf(fileType.toUpperCase()) < 0) {
            valid = false;
        }
        return valid;
    };

    const doUpload = (draftId, files) => {
        beforeUpload();
        dispatch({
            type: `${SLICE_NAME}/uploadAttachmentCorrespondence`,
            payload: {
                draftId,
                messageId,
                files,
            },
        });
    };

    const handleUpload = (event) => {
        const { files } = event.target;
        setSelectedFiles(files);
    };

    return (
        <div className={classes.root}>
            <input
                accept={ACCEPTED_FORMATS}
                className={classes.input}
                id={'contained-button-file'}
                multiple
                type={'file'}
                onChange={handleUpload}
                disabled={readOnly}
            />
            <label htmlFor={'contained-button-file'}>
                <Button variant={'outlined'} startIcon={<AddIcon />} component={'span'} className={classes.addBtn} disabled={readOnly}>
                    {t('draft.attachment')}
                </Button>
            </label>
            {confirmDialog.visibility && (
                <WarningModal
                    visibility={confirmDialog.visibility}
                    message={confirmDialog.message}
                    onConfirmation={confirmDialog.onConfirmation}
                    confirmButtonText={confirmDialog.confirmButtonText}
                    cancelButtonText={confirmDialog.cancelButtonText}
                    onCancel={confirmDialog.onCancel}
                />
            )}
        </div>
    );
};

export default Upload;
