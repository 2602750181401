import { sendRequest } from '../../common/utils';

export const fetchApplicationsPaginated = ({ userId, params }) => {
    const { requestBody, selectedTab } = params;
    const url = (!!selectedTab) ? `/applications/${selectedTab}` : '/applications/homepage';
    return sendRequest({
        url,
        method: 'POST',
        body: {
            ...requestBody,
            userId: userId,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const fetchApplicationReceipt = (params) => {
    const { appNumber } = params;
    return sendRequest({
        url: `/applications/${appNumber}/receipt`,
        method: 'GET',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { code: error.code || 500, description: 'application.pdf.failed.fetching' } };
        });
};

export const fetchApplicationsExport = ({ params }) => {
    const { requestBody, applicationType, type } = params;
    return sendRequest({
        url: `/applications/${applicationType}/${type}`,
        method: 'POST',
        body: { ...requestBody },
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { code: error.code || 500, description: 'something.went.wrong' } };
        });
};

export const updateApplicationNote = ({ params }) => {
    const { appNumber, ipRightType, applicationType, note } = params;
    const appRightType = (ipRightType || applicationType).toLowerCase();
    const url = `/applications/${applicationType}/${appRightType}/note`;
    return sendRequest({
        url,
        method: 'POST',
        body: {
            applicationIdentifier: appNumber,
            note: note,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code, details: error.details } };
        });
};

export const lockApplicationNote = ({ params }) => {
    const { appNumber, ipRightType, applicationType } = params;
    const appRightType = (ipRightType || applicationType).toLowerCase();
    const url = `/applications/${applicationType}/${appRightType}/note/lock`;
    return sendRequest({
        url,
        method: 'POST',
        body: {
            applicationIdentifier: appNumber,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code, details: error.details } };
        });
};

export const duplicateApplication = (params) => {
    const { appId, applicationType } = params;
    return sendRequest({
        url: `/applications/${applicationType}/${appId}/duplicate`,
        method: 'GET',
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};

export const validateEserviceInitiation = (params) => {
    const { eserviceName, applicationIds, applicationType } = params;
    const url = `/applications/${applicationType}/eservices/validate`;
    return sendRequest({
        url,
        method: 'POST',
        body: {
            eserviceType: eserviceName,
            applicationIds: applicationIds,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code, details: error.details, extraInfo: error.extraInfo } };
        });
};

export const getDraftReceipt = ({ params }) => {
    const { appNumber } = params;
    return sendRequest({
        url: `/applications/${appNumber}/receipt`,
        method: 'GET',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: 'application.receipt.fetch.failed', code: error.code } };
        });
};

export const deleteApp = ({ params }) => {
    const { filingNumber } = params;
    const url = `/applications/delete/${filingNumber}`;
    return sendRequest({
        url,
        method: 'DELETE',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code, details: error.details } };
        });
};

export const fetchApplicationPaymentInvoice = ({ params }) => {
    const { filingNumber } = params;
    const url = `/applications/invoice/${filingNumber}`;
    return sendRequest({
        url,
        method: 'GET',
        returnJSON: false,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return {
                error: {
                    description: error.code === 404 ? 'payment.invoice.historical.applications.failure' : 'payment.invoice.failed',
                    code: error.code || 500,
                },
            };
        });
};

export const fetchApplicationsFilters = () => {
    const url = '/content/filters';
    return sendRequest({
        url,
        method: 'GET',
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { code: error.code || 500, description: 'something.went.wrong' } };
        });
};

export const fetchHomepageApplicationCount = () => {
    return sendRequest({
            url: '/applications/homepage/count',
            method: 'POST'
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};


export const fetchWaitingForPaymentApplicationCount = () => {
    return sendRequest({
            url: '/applications/WAITING_FOR_PAYMENT/count',
            method: 'POST'
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};


export const fetchWaitingForSignatureApplicationCount = () => {
    return sendRequest({
            url: '/applications/WAITING_FOR_SIGNATURE/count',
            method: 'POST'
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { error: { description: error.message, code: error.code } };
        });
};